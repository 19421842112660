/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Input, Alert, Icon, Checkbox } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  userHasRole,
  sortByLabel,
  sortByKey,
  truncate,
  isPB,
} from '../../utils';
import { chain, get, set } from 'lodash';
import { getOrgs } from '../../_shared/services/manage-orgs.service';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { getModels } from '../../_shared/services/manage-models.service';
import { Address, Org } from '../../_shared/interfaces/org';
import colors from '../../_shared/colors';
import { Button } from '../../_shared/button';
import { SelectValue } from 'antd/lib/select';
import { User } from '../../_shared/interfaces/user';
import { Select } from '../../_shared/select';
import Link from '../../_shared/link';

export const renderAddress = (record: Org) => {
  const addr = get(record, 'address') as string | Address;

  let address;
  if (typeof addr === 'string') {
    address = addr.split(',').map(s => (
      <p
        css={css(
          `line-height: 16px; margin-block-start: 0px; margin-block-end: 0px; padding: 0px`
        )}
        key={s}
      >
        {s}
      </p>
    ));
  } else if (addr && addr.address) {
    const order = ['address', 'address2', ['city', 'state', 'zip']];
    address = order
      .map((o, i) => {
        if (Array.isArray(o)) {
          const vals: string[] = [];
          o.map(oo => {
            const val = get(addr, oo);
            if (val) {
              vals.push(val);
            }
          });
          return (
            <p
              key={i}
              css={css(
                `line-height: 16px; margin-block-start: 0px; margin-block-end: 0px; padding: 0px;`
              )}
            >
              {vals.join(', ')}
            </p>
          );
        } else {
          const val = get(addr, o);
          if (val) {
            return (
              <p
                css={css(
                  // tslint:disable-next-line: max-line-length
                  `line-height: 16px; margin-block-start: 0px; margin-block-end: 0px; padding: 0px;`
                )}
                key={o}
              >
                {val}
              </p>
            );
          }
        }
      })
      .filter(a => a);
  }
  return address;
};

const Columns = (_this: _ManageOrgs) => {
  const { orgType, sState } = _this.props;

  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      defaultSortOrder: 'ascend',
      sorter: (a: Org, b: Org) => sortByKey(a, b, 'name'),
    },
    {
      title: 'Account ID',
      dataIndex: 'accountNumber',
      width: '10%',
      sorter: (a: Org, b: Org) => sortByKey(a, b, 'accountNumber'),
    },
    orgType === 1 &&
      userHasRole([0, 1], sState) && {
        title: 'Service Org',
        dataIndex: 'serviceOrg',
        width: '15%',
      },
    {
      title: 'Equipment',
      dataIndex: 'equipCount',
      width: '10%',
    },
    orgType === 0 && {
      title: 'Email',
      dataIndex: 'email',
      width: '10%',
      // tslint:disable-next-line: no-any
      sorter: (a: any, b: any) => sortByKey(a, b, 'email'),
      render: (text: string) => {
        return <span>{text ? truncate(text, 20) : ''}</span>;
      },
    },
    orgType === 0 && {
      title: 'Phone',
      dataIndex: 'phone',
      width: '10%',
    },
    orgType === 1 && {
      title: 'Address',
      dataIndex: 'address',
      width: '10%',
      render: (_text: string, record: Org) => {
        return renderAddress(record);
      },
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '8%',
      render: (_text: string, record: { _org: Org }) => {
        const isActive = get(record, '_org.isActive', 0) > 0;
        return (
          <Icon
            type={isActive ? 'check-circle' : 'close-circle'}
            css={css(`color: ${isActive ? colors.success : colors.error};`)}
          />
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '8%',
      render: (_text: string, record: { _org: Org }) => {
        return (
          <Icon
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              _this.addOrg(record._org);
            }}
            type={'edit'}
            css={css(`color: ${colors.highlight};`)}
          />
        );
      },
    },
  ].filter(c => c);
};

interface IProps extends RouteComponentProps {
  sState: AppState;
  orgType: number;
}

class _ManageOrgs extends Component<IProps> {
  state = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    data: [],
    orgs: [],
    equipment: [],
    models: [],
    showInactive: false,
    searchValue: null,
    showFilters: true,
    serviceOrgs: {
      canDisplay: () =>
        this.props.orgType === 1 && userHasRole([0, 1], this.props.sState),
      opts: () => {
        const opts = this.props.sState.dash.orgs
          .filter(o => o.orgType === 0)
          .map(org => ({
            label: org.name,
            value: org.id,
          }))
          .sort(sortByLabel);

        return [{ label: 'No Service Org', value: undefined }, ...opts];
      },
      selected: [] as string[],
    },
  };

  componentDidMount = () => {
    const { state } = this.props.history.location;
    const { sState } = this.props;
    const ispb = isPB(sState);

    if (ispb) {
      this.setState({ showFilters: false });
    }

    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    this.retrieveOrgs();
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (
      get(prevProps, 'sState.dash.view.isPhoneBreak') !==
      get(this.props, 'sState.dash.view.isPhoneBreak')
    ) {
      const { sState } = this.props;
      const ispb = isPB(sState);
      if (ispb) {
        this.setState({ showFilters: false });
      } else {
        this.setState({ showFilters: true });
      }
    }
  };

  addOrg = (org?: Org) => {
    const { orgType } = this.props;
    const loc = `/dashboard/edit${orgType === 1 ? 'Dental' : 'Service'}Org`;

    this.props.history.push(loc, {
      orgType,
      org, //if org is provided we will edit, otherwise it is "new"
    });
  };
  manageOrg = (org: Org) => {
    const { orgType } = this.props;
    const loc = `/dashboard/manage${orgType === 1 ? 'Dental' : 'Service'}Org`;
    this.props.history.push(loc, {
      org,
      orgType,
    });
  };
  selectOrg = (record: { _org: Org }, index: number) => {
    this.manageOrg(record._org);
  };
  selectedRowsChange = (keys: string[] | number[]) => {
    this.setState({
      selectedRows: keys,
    });
  };
  fireSearch = (value: string) => {
    this.setState({ searchValue: value }, () => this.retrieveOrgs(false));
  };
  retrieveOrgs = async (shouldFetch = true) => {
    const state = { ...this.state };
    const { searchValue, serviceOrgs, showInactive } = state;
    const {
      sState,
      sState: { auth },
      orgType,
    } = this.props;

    const me = get(auth, 'user', {}) as User;
    const iAmOrgAdmin = userHasRole(2, sState);

    this.setState({ loading: true });

    try {
      const [allOrgs, equipment, models] = await Promise.all([
        shouldFetch ? await getOrgs(undefined, !state.showInactive) : state.orgs,
        shouldFetch ? await getEquipments() : state.equipment,
        shouldFetch ? await getModels() : state.models,
      ]);

      const orgs = allOrgs.filter(o => {
        delete o.equipCount;

        const oType = o.orgType === orgType;

        return iAmOrgAdmin
          ? (o.id === me.orgId || o.serviceOrgId === me.orgId) && oType
          : oType;
      });

      const orgMap = chain(allOrgs)
        .keyBy('id')
        .value();

      const modelMap = chain(models)
        .keyBy('id')
        .value();

      equipment.map(e => {
        const orgId = orgType === 0 ? e.serviceOrgId : e.dentalOrgId;
        const model = get(modelMap, `${e.modelId}`);
        if (!!orgId && !!model) {
          const count = get(orgMap, `${orgId}.equipCount`, 0) as number;

          set(orgMap, `${orgId}.equipCount`, count + 1);
        }
      });

      const sterm = (searchValue || '').toLowerCase();
      const data = [...orgs]
        .map((o, i) => {
          const org = get(orgMap, o.id);
          return {
            ...o,
            _org: { ...o },
            key: o.id,
            equipCount: get(org, 'equipCount', 0),
            serviceOrg: get(orgMap, `${o.serviceOrgId}.name`, ''),
          };
        })
        .filter(o => {
          let passes = true;

          if (searchValue) {
            const oname = (get(o, 'name', '') || '').toLowerCase();
            const ocode = (get(o, 'accountNumber', '') || '').toLowerCase();

            if (
              (oname && oname.indexOf(sterm) > -1) ||
              (ocode && ocode.indexOf(sterm) > -1)
            ) {
              passes = true;
            } else {
              passes = false;
            }
          }

          if (passes && serviceOrgs.selected.length > 0) {
            if (
              !o.serviceOrgId ||
              serviceOrgs.selected.indexOf(o.serviceOrgId) === -1
            ) {
              passes = false;
            }

            const hasUndefined = serviceOrgs.selected.filter(
              so => so === undefined
            );
            if (hasUndefined.length > 0) {
              passes = !o.serviceOrgId;
            }
          }

          if (passes && !showInactive) {
            const isActive = get(o, 'isActive', 0) > 0;
            passes = isActive;
          }

          return passes;
        });

      const columns = Columns(this);

      this.setState({
        data: data as never[],
        orgs: allOrgs as never[],
        equipment: equipment as never[],
        models: models as never[],
        columns: columns as never[],
        loading: false,
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        loading: false,
        error: err.message,
      });
    }
  };
  changeSelect = (item: 'serviceOrgs', value: SelectValue) => {
    this.setState(
      { [item]: { ...this.state[item], selected: [...(value as string[])] } },
      () => this.retrieveOrgs(false)
    );
  };

  render() {
    const { orgType } = this.props;
    const {
      columns,
      data,
      loading,
      error,
      success,
      showInactive,
      serviceOrgs,
      showFilters,
    } = this.state;

    const { sState } = this.props;
    const ispb = isPB(sState);
    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <TableListHeader style={ispb && SharedStyles.pbFilterContainer}>
          <div
            css={css(SharedStyles.row, ispb ? `width: 100%` : `width: 60%;`)}
          >
            <Input.Search
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 70%;
                      margin-right: 10px;
                    `
              }
              placeholder="Search by Name or Account ID..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />

            <Button
              css={ispb && css(`display: none;`)}
              loading={loading}
              title="Refresh"
              type="link"
              onClick={() => this.retrieveOrgs(true)}
            />
          </div>
          <div css={ispb && css(SharedStyles.pbButtonContainer)}>
            <Button
              title={`Add ${orgType === 0 ? 'Service' : 'Dental'} Org`}
              onClick={() => this.addOrg()}
            />
            <div
              css={
                (!serviceOrgs.canDisplay() || !ispb) && css(SharedStyles.hidden)
              }
            >
              <Link
                css={css(SharedStyles.row)}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
                <Icon
                  css={css('margin-left: 5px;')}
                  type={showFilters ? 'up' : 'down'}
                />
              </Link>
            </div>
          </div>
        </TableListHeader>

        <TableListHeader
          style={
            ispb
              ? SharedStyles.pbFilterContainer
              : `justify-content: flex-start;`
          }
        >
          {serviceOrgs.canDisplay() && showFilters && (
            <Select
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 30%;
                      margin-right: 10px;
                    `
              }
              placeholder={'Select Service Orgs'}
              onChange={value => this.changeSelect('serviceOrgs', value)}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
            >
              {serviceOrgs.opts().map((item, i) => (
                <Select.Option key={i} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          )}
          <Checkbox
            checked={showInactive}
            onChange={() =>
              this.setState({ showInactive: !showInactive }, () =>
                this.retrieveOrgs(true)
              )
            }
          >
            Show Inactive
          </Checkbox>
        </TableListHeader>

        <TableList
          canSelectRows={false}
          loading={loading}
          columns={columns as never[]}
          data={data}
          rowClick={this.selectOrg}
          pageSize={100}
          includeCount={`${
            orgType === 0 ? 'Service' : 'Dental'
          } Organizations Count`}
        />
      </div>
    );
  }
}

export const ManageOrgsComponent = withRouter(_ManageOrgs);
