/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import SharedStyles from '../styles';
import FormTitle from '../form-title';
import { Table } from 'antd';
import { size } from 'lodash';
import { ColumnProps } from 'antd/lib/table';

export interface TableColumnFilter {
  text: string,
  value: string,
  children?: Array<TableColumnFilter>,
}

interface IProps<T> {
  columns: ColumnProps<T>[];
  data: T[];
  canSelectRows?: boolean;
  loading?: boolean;
  implementScroll?: boolean;
  showPagination?: boolean;
  scrollHeight?: number;
  pageSize?: number;
  title?: string;
  hidePageSelect?: boolean;
  // tslint:disable-next-line:no-any
  rowClick?: (record: any, rowIndex: number) => void;
  onSelectedRowsChange?: (keys: string[] | number[], rows: any[]) => void;
  // tslint:disable-next-line:no-any
  rowKey?: (record: any) => string;
  includeCount?: boolean | string | ((amt: number) => string);
  countExtra?: string;
  subtractHeight?: number;
  getCheckboxProps?: (record: any) => any;
}

export class TableList<T> extends Component<IProps<T>> {
  state = {
    selectedRowKeys: [],
    selectedRows: [],
    loading: false,
    height: 500,
    pageSize: this.props.pageSize || 100,
  };
  mounted = true;
  calcTableSize = () => {
    const container = document.getElementById('ContentContainer');

    if (container) {
      const cHeight = container.offsetHeight;
      const subtract = this.props.subtractHeight || 200;
      const height = cHeight - subtract;
      this.setState({
        height,
      });
    }
  };
  componentWillUnmount = () => {
    this.mounted = false;
    window.removeEventListener('resize', this.calcTableSize);
  };
  componentDidMount = () => {
    setTimeout(() => {
      if (this.mounted) {
        this.calcTableSize();
      }
    }, 500);
    window.addEventListener('resize', this.calcTableSize);
  };
  selectedRowsChange = () => {
    const { onSelectedRowsChange } = this.props;
    if (onSelectedRowsChange) {
      onSelectedRowsChange(this.state.selectedRowKeys, this.state.selectedRows);
    }
  };
  rowSelection = () => {
    const { selectedRowKeys } = this.state;

    return {
      selectedRowKeys,
      onChange: (keys: string[] | number[], selectedRows: T[]) => {
        this.setState({ selectedRowKeys: keys, selectedRows }, this.selectedRowsChange);
      },
      getCheckboxProps: (row: T) => {
        return this.props.getCheckboxProps ? this.props.getCheckboxProps(row) : undefined;
      }
    };
  };
  // tslint:disable-next-line:no-any
  rowClick = (record: any, rowIndex: number) => {
    const { rowClick } = this.props;
    if (rowClick) {
      rowClick(record, rowIndex);
    }
  };

  static defaultProps = {
    canSelectRows: true,
    implementScroll: true,
    showPagination: true,
    pageSize: 100,
    includeCount: true,
    rowKey: (r: { sk: string | undefined, id: string | undefined }) => r.sk || r.id,
  };

  renderCount = () => {
    const { includeCount, data, showPagination, countExtra } = this.props;

    if (includeCount) {
      const amt = size(data);
      const str =
        typeof includeCount === 'string'
          ? `${includeCount}: ${amt}`
          : typeof includeCount === 'function'
            ? includeCount(amt)
            : `Count: ${amt}`;
      const extra = countExtra || null;

      return (
        <div
          css={css(
            `margin: 0; margin-top: ${showPagination && amt > this.state.pageSize ? -40 : 10
            }px; font-weight: 500; width: 100%; text-align: left;`
          )}
        >
          <span dangerouslySetInnerHTML={{ __html: str }} />
          {!!extra && <div>{extra}</div>}
        </div>
      );
    }

    return null;
  };
  render() {
    const { height } = this.state;
    const {
      columns,
      data,
      canSelectRows,
      loading,
      implementScroll,
      showPagination,
      scrollHeight,
      rowClick,
      title,
      rowKey,
    } = this.props;

    return (
      <div>
        {title && (
          <div css={css(SharedStyles.row, `height: 30px;`)}>
            <FormTitle
              css={css`
                margin-bottom: 0;
              `}
              title={title}
            />
          </div>
        )}
        <Table
          rowKey={rowKey}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => this.rowClick(record, rowIndex),
            };
          }}
          pagination={
            showPagination
              ? { hideOnSinglePage: true, pageSize: this.state.pageSize }
              : false
          }
          size={'small'}
          scroll={implementScroll ? { y: scrollHeight || height } : undefined}
          rowSelection={canSelectRows ? this.rowSelection() : undefined}
          columns={columns}
          dataSource={data}
          loading={loading}
          rowClassName={(_item, index) =>
            `tbl-list-row-${index % 2} ${rowClick ? 'canClick' : ''}`
          }
        />
        {this.renderCount()}
      </div>
    );
  }
}