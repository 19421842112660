import { get } from 'lodash';

const access_token = '5c23b2cf-6b02-411c-b3b2-6c223422fc6d';
const particle_key = 'b7b11fe3882122246598a414a1257ec993e9a373';

const modelPhotos = (env = 'dev'): string => {
  return `https://models-photos-${env}.s3.amazonaws.com/`;
};

export const getEnv = () => {
  const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
  if (env === 'development') {
    return 'local';
  } else if (env === 'production') {
    return 'prod';
  } else {
    return 'qa';
  }
};

export const isProd = () => {
  return getEnv() === 'prod';
}

export const getUrl = () => {
  const env = getEnv();
  const base = {
    local: 'http://localhost:3001',
    qa: 'https://qa.aeras-api.link',
    prod: 'https://prod.aeras-api.link',
  };
  const baseURL = get(base, env, base.qa);
  return `${baseURL}/api`;
};

export interface IAppConfig {
  aws_identity_pool: string;
  aws_user_pool: string;
  aws_region: string;
  aws_webclient_id: string;

  app_url: string;
  access_token: string;
  model_photos: string;

  particle_key: string;

  ToAddresses: string[];
  FromAddresses: string[];

  manage_users_url: string;
  user_history_url: string;
  user_history_v2_url: string;
  manage_roles_url: string;
  manage_orgs_url: string;
  manage_models_url: string;
  manage_sensors_url: string;
  manage_serials_url: string;
  manage_services_url: string;
  manage_groups_url: string;
  manage_presets_url: string;
  manage_locations_url: string;
  manage_org_types_url: string;
  manage_dental_org_types_url: string;
  manage_equipment_url: string;
  manage_schedule_url: string;
  manage_procedure_types_url: string;
  manage_profiles_url: string;
  email_service_url: string;
  publish_url: string;
  status_url: string;
  history_url: string;
  history_v2_url: string;
  device_location_history_url: string;
  equipment_history_url: string;
  equipment_history_v2_url: string;
  manage_tandem_groups_url: string;
  conversion_url: string;
  manage_trial_duration_url: string;
  handpiece_profiles_url: string;

  pusherChannelName: string;
  pusher: {
    appId: string;
    key: string;
    cluster: string;
  };
}

const config = {
  local: {
    aws_identity_pool: 'us-east-1:67cb5064-203b-4123-a9f9-48e8419c4e8e',
    aws_user_pool: 'us-east-1_fuFHi7gL7',
    aws_region: 'us-east-1',
    aws_webclient_id: '309dheuss9idber715d9kmhj',

    app_url: 'https://qa--vibrant-chandrasekhar-ddde91.netlify.app/',
    access_token,
    model_photos: modelPhotos('dev'),

    particle_key: `${particle_key}`,

    ToAddresses: ['smartutility@dentalez.com'],
    FromAddresses: ['noreply@mg.aerasintel.com'],

    manage_users_url: getUrl(),
    user_history_url: getUrl(),
    user_history_v2_url: getUrl(),
    manage_roles_url: getUrl(),
    manage_orgs_url: getUrl(),
    manage_models_url: getUrl(),
    manage_sensors_url: getUrl(),
    manage_serials_url: getUrl(),
    manage_services_url: getUrl(),
    manage_groups_url: getUrl(),
    manage_locations_url: getUrl(),
    manage_org_types_url: getUrl(),
    manage_equipment_url: getUrl(),
    manage_schedule_url: getUrl(),
    email_service_url: getUrl(),
    publish_url: getUrl(),
    status_url: getUrl(),
    history_url: getUrl(),
    history_v2_url: getUrl(),
    device_location_history_url: getUrl(),
    equipment_history_url: getUrl(),
    equipment_history_v2_url: getUrl(),
    manage_tandem_groups_url: getUrl(),
    conversion_url: getUrl(),
    manage_trial_duration_url: getUrl(),
    manage_presets_url: getUrl(),
    handpiece_profiles_url: getUrl(),

    pusherChannelName: 'dentalEZ',
    pusher: {
      appId: '894219',
      key: '74e2290804ec7f6f3b90',
      cluster: 'us2',
    },
  },
  qa: {
    aws_identity_pool: 'us-east-1:67cb5064-203b-4123-a9f9-48e8419c4e8e',
    aws_user_pool: 'us-east-1_fuFHi7gL7',
    aws_region: 'us-east-1',
    aws_webclient_id: '309dheuss9idber715d9kmhj',

    app_url: 'https://qa--vibrant-chandrasekhar-ddde91.netlify.app/',
    access_token,
    model_photos: modelPhotos('dev'),

    particle_key: `${particle_key}`,

    ToAddresses: ['smartutility@dentalez.com'],
    FromAddresses: ['noreply@mg.aerasintel.com'],

    manage_users_url: getUrl(),
    user_history_url: getUrl(),
    user_history_v2_url: getUrl(),
    manage_roles_url: getUrl(),
    manage_orgs_url: getUrl(),
    manage_models_url: getUrl(),
    manage_sensors_url: getUrl(),
    manage_serials_url: getUrl(),
    manage_services_url: getUrl(),
    manage_groups_url: getUrl(),
    manage_locations_url: getUrl(),
    manage_org_types_url: getUrl(),
    manage_equipment_url: getUrl(),
    manage_schedule_url: getUrl(),
    email_service_url: getUrl(),
    publish_url: getUrl(),
    status_url: getUrl(),
    history_url: getUrl(),
    history_v2_url: getUrl(),
    device_location_history_url: getUrl(),
    equipment_history_url: getUrl(),
    equipment_history_v2_url: getUrl(),
    manage_tandem_groups_url: getUrl(),
    conversion_url: getUrl(),
    manage_trial_duration_url: getUrl(),
    manage_presets_url: getUrl(),
    handpiece_profiles_url: getUrl(),

    pusherChannelName: 'dentalEZ',
    pusher: {
      appId: '894219',
      key: '74e2290804ec7f6f3b90',
      cluster: 'us2',
    },
  },
  prod: {
    aws_identity_pool: 'us-east-1:67cb5064-203b-4123-a9f9-48e8419c4e8e',
    aws_user_pool: 'us-east-1_fuFHi7gL7',
    aws_region: 'us-east-1',
    aws_webclient_id: '309dheuss9idber715d9kmhj',

    app_url: 'https://aeras.dentalez.com/',
    access_token,
    model_photos: modelPhotos('dev'),

    particle_key: `${particle_key}`,

    ToAddresses: ['smartutility@dentalez.com'],
    FromAddresses: ['noreply@mg.aerasintel.com'],

    manage_users_url: getUrl(),
    user_history_url: getUrl(),
    user_history_v2_url: getUrl(),
    manage_roles_url: getUrl(),
    manage_orgs_url: getUrl(),
    manage_models_url: getUrl(),
    manage_sensors_url: getUrl(),
    manage_serials_url: getUrl(),
    manage_services_url: getUrl(),
    manage_groups_url: getUrl(),
    manage_locations_url: getUrl(),
    manage_org_types_url: getUrl(),
    manage_equipment_url: getUrl(),
    manage_schedule_url: getUrl(),
    email_service_url: getUrl(),
    publish_url: getUrl(),
    status_url: getUrl(),
    history_url: getUrl(),
    history_v2_url: getUrl(),
    device_location_history_url: getUrl(),
    equipment_history_url: getUrl(),
    equipment_history_v2_url: getUrl(),
    manage_tandem_groups_url: getUrl(),
    conversion_url: getUrl(),
    manage_trial_duration_url: getUrl(),
    manage_presets_url: getUrl(),
    handpiece_profiles_url: getUrl(),

    pusherChannelName: 'dentalEZ',
    pusher: {
      appId: "894220",
      key: "611725ebddd425ca0a19",
      cluster: 'us2',
    },
  },
};

export type ENVS = 'local' | 'qa' | 'prod';

export default (use?: ENVS): IAppConfig => {
  use = use || getEnv();
  return config[use as ENVS] as IAppConfig;
};
