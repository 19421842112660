/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import styles, { sbstyles, modalStyles } from './equipment-details.styles';
import {
  Icon,
  Switch,
  Modal,
  InputNumber,
  Checkbox,
  message,
  Alert,
} from 'antd';
import { Equipment } from '../../_shared/interfaces/equipment';
import { Org, Address } from '../../_shared/interfaces/org';
import { Model } from '../../_shared/interfaces/model';
import SharedStyles from '../../_shared/styles';
import _Icon from '../../_shared/icon';
import EditableP from '../../_shared/editable-p';
import {
  validPhoneNumber,
  removeAllAlpha,
  formatPhoneNumber,
  userHasRole,
  getDebugStatus,
  getAutoWashStatus,
  downloadFile,
  getSubscriptionHistory,
  renderHWVersion,
  isHandpiece,
  getDebugStatusHandpiece,
  isChair,
  cleanPhoneNumber,
  getEquipMaint,
  getEqType,
  chairMountTrue,
  isAerasOne,
  wait,
} from '../../utils';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import { getEquipments, updateEquipment } from '../../_shared/services/manage-equipment.service';
import ModelImage from '../../_shared/model-image';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { get, set, first, size } from 'lodash';
import { Button } from '../../_shared/button';
import moment from 'moment';
import { TandemGroup } from '../../_shared/interfaces/tandemGroup';
import { getHardwareVersion } from '../../_shared/services/hardware.service';
import { _Schedule } from '../../_shared/interfaces/schedule';
import {
  Publish,
  HandpieceConfigPData,
} from '../../_shared/interfaces/publishData';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';
import { ReplaceController, ReplaceProps } from './replacecontroller.component';
import { SSUpgradeBtn } from './ssupgrade.component';
import { getAppConfig } from '../../_shared/hooks/useAppConfig';
import { withRouter } from 'react-router-dom';

interface IProps {
  sState: AppState;
  equipment: Equipment;
  serviceOrg?: Org;
  dentalOrg?: Org;
  model?: Model;
  config?: Publish;
  maint?: Publish;
  onEditClick?: () => void;
  updateEquipment?: () => Promise<void>;
  waitForNewConfig?: (eq: Equipment) => Promise<unknown>;
  isPhoneBreak?: boolean;
  showHeads?: boolean;
  showReplace?: boolean;
  tandemGroup?: TandemGroup;
  tandemGroups?: TandemGroup[];
  showTandemGroup?: boolean;
  goToViewTGroup?: () => void;
  goToAddTGroup?: () => void;
  toggleModal?: () => void;
  goToAddUser?: (orgId: string) => void;
  goToBillingHistory: (equipmentId: string) => void;
  showBadAccess?: () => void;
  history: any;
}

interface IState {
  lockPresets: boolean;
  debugModal?: DebugModal;
  loading: boolean;
  loadingMotor: boolean;
  replaceModal?: ReplaceProps;
  hw?: string;
  searchingLinkedEq: boolean;
  foundLinkedEq?: Equipment[],
}

interface DebugModal {
  title: string;
  content: string;
  frequency: number;
  duration: number;
  indefinite: boolean;
}

export class _Sidebar extends Component<IProps, IState> {
  state: IState = {
    debugModal: undefined,
    loading: false,
    loadingMotor: false,
    lockPresets: !!(
      this.props.config && !!get(this, 'props.config.data.dental_school')
    ),
    replaceModal: {
      visible: false,
      equipment: this.props.equipment,
      onClose: () => {
        this.setState({
          replaceModal: {
            ...this.state.replaceModal,
            visible: false,
          } as ReplaceProps,
        });
        get(this, 'props.updateEquipment', () => 0)();
      },
    } as ReplaceProps,
    hw: undefined,
    searchingLinkedEq: false,
    foundLinkedEq: undefined,
  };

  componentDidUpdate(prevProps: IProps) {
    if (!this.props.config || !this.props.config.data) {
      return;
    }
    const key = 'config.data.dental_school';
    const prevschool = get(prevProps, key);
    const curschool = get(this.props, key);
    if (prevschool !== curschool) {
      this.setState({ lockPresets: !!curschool });
    }
  }

  componentDidMount() {
    this.retrieveHardwareVersion();
  }
  trackEvent = (event = '', data = {}) => {
    trackEvent(event, { ...data, equipment: this.props.equipment });
  };
  onEditPhone = async (phone: string) => {
    const { sState } = this.props;
    const me = get(sState, 'auth.user');
    const isValid = validPhoneNumber(phone);
    const { equipment } = this.props;
    const _phone = cleanPhoneNumber(phone);
    if (!isValid) {
      throw new Error('Not a valid phone number.');
    }
    if (!equipment.deviceId) {
      throw new Error('No valid DeviceID.');
    }

    await sendDeviceCommand(
      equipment.deviceId,
      { arg: `phone,${_phone}` },
      { showErrorToast: true },
      isHandpiece(equipment)
    );

    set(equipment, 'updatedAt', moment().toISOString());
    set(equipment, 'updatedBy', me.userId);
    set(equipment, 'serviceOrgNumber', _phone);
    await updateEquipment(equipment);

    if (this.props.updateEquipment) {
      await this.props.updateEquipment();
    }
  };
  toggleMotor = async (num: number) => {
    const { sState, showBadAccess } = this.props;

    if (!!showBadAccess && userHasRole([6, 7], sState)) {
      showBadAccess();
      return;
    }

    if (this.state.loadingMotor) {
      return message.error('Please wait for previous command to complete.');
    }

    const { equipment, config } = this.props;

    if (!equipment.deviceId) {
      return message.error('No deviceID!');
    }

    this.setState({ loadingMotor: true });

    const isCurrentlyEnabled = get(config, `data.heads_enabled.${num}`, false);
    const motorNum = num + 1;

    try {
      const arg = `set_motor,${motorNum},${isCurrentlyEnabled ? 0 : 1}`;
      await sendDeviceCommand(
        equipment.deviceId,
        { arg },
        { showErrorToast: true },
        isHandpiece(equipment)
      );
      message.success(
        `Motor head ${motorNum} is now ${
          isCurrentlyEnabled ? 'disabled' : 'enabled'
        }`
      );
      this.setState({ loadingMotor: false });
      this.trackEvent(eventTypes.eq_motorhead_toggle, {
        state: isCurrentlyEnabled ? 'disabled' : 'enabled',
      });
    } catch (err) {
      console.error(err);
      message.error(
        `Unable to ${isCurrentlyEnabled ? 'disable' : 'enable'} motor head`
      );
      this.setState({ loadingMotor: false });
    }
  };
  renderMotorHeadSerials = () => {
    const { equipment, config } = this.props;
    const { loadingMotor } = this.state;
    const maint =
      this.props.maint || first(getEquipMaint(equipment, this.props.sState));
    const isCompressor =
      get(this.props.model, 'type') === 'compressor' ? true : false;

    const headsEnabled = get(config, 'data.heads_enabled', []);
    const headsEnabledLength = isHandpiece(equipment) ? 1 : headsEnabled.length;
    const markup = [];

    const eType = getEqType(equipment);
    const isA1 = isAerasOne(eType);

    if (!get(this, 'props.model.showMotor', true) || isA1) { return null; }

    for (let i = 0; i < headsEnabledLength; i++) {
      const ii = i + 1;
      const hasSerial = isHandpiece(equipment)
        ? equipment.mSerial_motor
        : get(maint, `data.serial_numbers.motor${ii}`);
      const isEnabled = headsEnabled[i];

      markup.push(
        <div key={i} css={css(sbstyles.motorItem)}>
          <div key={ii} css={css(SharedStyles.row, sbstyles.button)}>
            {isCompressor && `Head ${ii}`}
            {isCompressor && (
              <Switch
                css={css(sbstyles.motorSwitch)}
                size="small"
                loading={loadingMotor}
                onChange={() => this.toggleMotor(i)}
                checked={isEnabled}
              />
            )}
          </div>
          <div css={css(sbstyles.serialItem)}>{`Serial #: ${hasSerial ||
            'Not entered'}`}</div>
        </div>
      );
    }

    return (
      <div css={css(sbstyles.infoDivide)}>
        <div css={css(styles.title)}>
          {isCompressor ? 'Motor Heads' : 'Motor'}
        </div>
        {markup}
      </div>
    );
  };

  renderTestResults = () => {
    const { equipment } = this.props;
    const url: string = equipment.testDataUrl ? equipment.testDataUrl : '';
    return (
      <div css={css(sbstyles.infoDivide)}>
        <div css={css(styles.title)}>Manufacturing Test</div>
        <button
          css={css(styles.link)}
          onClick={async () => await downloadFile(url)}
        >
          Test Results
        </button>
      </div>
    );
  };

  onLockPresetsClick = async () => {
    const { equipment } = this.props;
    if (equipment.deviceId) {
      const lock: boolean = this.state.lockPresets;
      try {
        this.setState({ lockPresets: !lock });
        await sendDeviceCommand(
          equipment.deviceId,
          {
            arg: `{"cmd":"set_cfg", "cfg": {"config":{"dental_school": ${!lock}}}}`,
          },
          { showErrorToast: false },
          true
        );
        message.success(
          `Presets have been ${lock ? 'unlocked' : 'locked'} on the device LCD`
        );
      } catch (error) {
        message.error('Unable to update device.');
        this.setState({ lockPresets: lock });
      }
    }
  };

  onDebugClick = async () => {
    const { sState, equipment, updateEquipment, config } = this.props;
    const debugOn = isHandpiece(equipment)
      ? getDebugStatusHandpiece(sState, equipment.deviceId)
      : getDebugStatus(config);

    if (!equipment.deviceId) {
      return message.error('No deviceID!');
    }

    if (!debugOn) {
      const debugModal = {
        title: 'Confirmation:  Debug Mode ON',
        content: 'Specify publish frequency and duration.',
        frequency: 0,
        duration: 0,
        indefinite: false,
      };
      this.setState({ debugModal });
    } else {
      try {
        this.setState({ loading: true });

        var data = {};

        if (isHandpiece(equipment)) {
          data = {
            arg: `{"cmd":"set_cfg","cfg": {"sensor_statistics":{"interval": 0,"duration": 0}}}`,
          };
        } else {
          data = {
            arg: `statistics_interval,0`,
          };
        }

        await sendDeviceCommand(
          equipment.deviceId,
          data,
          { showErrorToast: true },
          isHandpiece(equipment)
        );
        updateEquipment && (await updateEquipment());
        message.success('Debug Mode turned off');
      } catch (err) {
        console.error(err);
        message.error('Failed to turn off Debug Mode.');
      }
      this.setState({ loading: false });
    }
  };
  onHideClick = async () => {
    const { equipment } = this.props;
    const { showOnDash } = equipment;

    Modal.confirm({
      title: showOnDash ? 'Hide Equipment' : 'Show Equipment',
      content: showOnDash
        ? 'Are you sure you want to hide this equipment?  Users will no longer be able to see this equipment in any area of the web application.'
        : 'Are you sure you want to show this equipment?  It was previously hidden from web application users via the ShowOnDash flag.',
      okText: showOnDash ? 'Hide' : 'Show',
      onOk: async () => {
        if (equipment) {
          this.trackEvent(eventTypes.eq_vis_toggle, {
            showOnDash: !showOnDash,
          });
          await updateEquipment({ ...equipment, showOnDash: !showOnDash });
          this.props.updateEquipment && (await this.props.updateEquipment());
        }
      },
    });
  };
  toggleChairMount = async () => {
    const { equipment, config } = this.props;
    const chairMount = chairMountTrue(config)

    Modal.confirm({
      title: 'Update Chair Mount Config',
      content: 'Are you sure you want to update the chair mount configuration for this chair?  Alerts may be hidden as a result.',
      okText: 'Update',
      onOk: async () => {
        if (equipment) {
          await sendDeviceCommand(
            equipment.deviceId,
            { arg: chairMount ? 'chair_mount,0' : 'chair_mount,1' },
            { showErrorToast: true },
            isHandpiece(equipment)
          );
        }
      },
    });
  };
  onAutoWashClick = async () => {
    const { equipment } = this.props;

    const finish = async () => {
      if (!equipment.deviceId) {
        return message.error('No deviceID!');
      }

      try {
        this.setState({ loading: true });
        await sendDeviceCommand(
          equipment.deviceId,
          { arg: 'autowash' },
          { showErrorToast: true },
          isHandpiece(equipment)
        );
        message.success(`Cycle Autowash successful`);
        this.setState({ loading: false });
        this.trackEvent(eventTypes.eq_cycle_autowash);
      } catch (err) {
        console.error(err);
        message.error(`Unable to cycle Autowash`);
        this.setState({ loading: false });
      }
    };

    Modal.confirm({
      title: 'Test Autowash',
      content:
        'This will cycle the autowash accessory on for 10\
      seconds and should not be done when the vacuum is running.',
      onOk: finish,
      onCancel: () => 0,
      okText: 'Start',
      cancelText: 'Cancel',
    });
  };
  onCycleDryer = async () => {
    const { equipment, updateEquipment } = this.props;
    if (!equipment.deviceId) {
      return message.error('No deviceID!');
    }

    try {
      this.setState({ loading: true });
      const arg = `cycle_dryers`;
      await sendDeviceCommand(
        equipment.deviceId,
        { arg },
        { showErrorToast: true },
        isHandpiece(equipment)
      );
      updateEquipment && (await updateEquipment());
      message.success(`Cycle Dryer Towers successful`);
      this.trackEvent(eventTypes.eq_cycle_towers);
    } catch (err) {
      console.error(err);
      message.error(`Unable to cycle Dryer Towers`);
    }
    this.setState({ loading: false });
  };
  debugClose = () => {
    this.setState({ debugModal: undefined, loading: false });
  };
  checkValueChange = (e: number | undefined, limit: number, key: string) => {
    const { debugModal } = this.state;

    let val = parseInt((e || '').toString());

    if (isNaN(val)) {
      val = 0;
    }

    this.setState({
      debugModal: {
        ...(debugModal as DebugModal),
        [key]: val > limit ? limit : val,
      },
    });
  };
  parseDebug = async () => {
    const { debugModal } = this.state;
    const { equipment, updateEquipment } = this.props;

    if (!equipment.deviceId) {
      return message.error('No deviceID for device!');
    }

    try {
      const dbm = debugModal as DebugModal;

      if (dbm.frequency <= 0) {
        return message.error('Please provide a value greater than 0');
      }

      this.setState({ loading: true });

      var data = {};

      if (isHandpiece(equipment)) {
        data = {
          arg: `{"cmd":"set_cfg","cfg": {"sensor_statistics":{"interval":${
            dbm.frequency
          },"duration":${dbm.indefinite ? -1 : dbm.duration * 60}}}}`,
        };
      } else {
        data = {
          arg: `statistics_interval,${dbm.frequency}${
            dbm.indefinite ? '' : `,${dbm.duration * 60}`
          }`,
        };
      }

      await sendDeviceCommand(
        equipment.deviceId,
        data,
        { showErrorToast: true },
        isHandpiece(equipment)
      );

      updateEquipment && (await updateEquipment());

      this.debugClose();
      this.trackEvent(eventTypes.eq_debug);
      return message.success('Debug mode turned on');
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
      message.error(`Debug Mode failed to turn on.`);
    }
  };
  renderAddress = () => {
    const { serviceOrg } = this.props;

    const addr = get(serviceOrg, 'address') as string | Address;

    let address;
    if (typeof addr === 'string') {
      address = addr.split(',').map(s => <p key={s}>{s}</p>);
    } else if (addr && addr.address) {
      const order = ['address', 'address2', ['city', 'state', 'zip']];
      address = order
        .map((o, i) => {
          if (Array.isArray(o)) {
            const vals: string[] = [];
            o.map(oo => {
              const val = get(addr, oo);
              if (val) {
                vals.push(val);
              }
            });
            return <p key={i}>{vals.join(', ')}</p>;
          } else {
            const val = get(addr, o);
            if (val) {
              return <p key={o}>{val}</p>;
            }
          }
        })
        .filter(a => a);
    }

    return address;
  };
  replaceController = () => {
    this.setState({
      replaceModal: {
        ...this.state.replaceModal,
        ...this.props,
        equipment: this.props.equipment,
        visible: true,
      } as ReplaceProps,
    });
  };

  showAddToTandemGroup = () => {
    const { equipment } = this.props;
    const { tandemGroups } = this.props;
    return (
      !!equipment.locationId &&
      !equipment.tandemGroupId &&
      tandemGroups &&
      tandemGroups.length
    );
  };

  retrieveHardwareVersion = () => {
    const { equipment, config } = this.props;
    const hw = equipment.hw_version;
    if (hw) {
      return this.setState({ hw });
    }

    if (isHandpiece(equipment) || getEqType(equipment).includes('aerasone')) {
      return;
    }

    getHardwareVersion(get(equipment, 'deviceId', ''))
      .then(response => {
        this.setState({ hw: response ? response : '' });
      })
      .catch(error => console.warn(error));
  };

  renderTandemGroup = () => {
    const {
      equipment,
      tandemGroup,
      goToViewTGroup,
      goToAddTGroup,
    } = this.props;

    if (!get(this, 'props.model.showTandem', true)) { return null }

    if (!equipment.locationId) {
      return (
        <div css={css(sbstyles.infoDivide)}>
          <div css={css(styles.title)}>Tandem Group</div>
          <p>No Location</p>
        </div>
      );
    }
    return (
      <div css={css(sbstyles.infoDivide)}>
        <div css={css(styles.title)}>Tandem Group</div>
        {!!equipment.locationId && !equipment.tandemGroupId && (
          <div>
            <Button
              css={css(`margin: 4px 0px 4px 0px`)}
              title="Create Tandem Group"
              size={'small'}
              onClick={() => !!goToAddTGroup && goToAddTGroup()}
            />
          </div>
        )}
        {!!equipment.tandemGroupId && !!tandemGroup && (
          <button css={css(styles.link)} onClick={goToViewTGroup}>
            View Tandem Group
          </button>
        )}
        {!!this.showAddToTandemGroup() && (
          <div>
            <Button
              css={css(`margin: 4px 0px 4px 0px`)}
              title="Add To Tandem Group"
              size={'small'}
              onClick={() => this.props.toggleModal && this.props.toggleModal()}
            />
          </div>
        )}
      </div>
    );
  };

  renderInfinitank = (val: boolean) => {
    return (
      <div css={css(sbstyles.infoDivide)}>
        <div css={css(styles.title)}>Infinitank</div>
        <p>{val ? 'Enabled' : 'Disabled'}</p>
      </div>
    );
  };

  findLinkedEq = () => {
    this.setState({ searchingLinkedEq: true }, async () => {
      const eqs = await getEquipments();
      const [did] = get(this, 'props.equipment.deviceId', '').split('_')
      const foundLinkedEq = eqs.filter(e => {
        const eid = e.deviceId || '';
        return eid.includes(did) && eid !== get(this, 'props.equipment.deviceId');
      });
      this.setState({ foundLinkedEq, searchingLinkedEq: false })
    })
  }

  renderLinkedEq = () => {
    const { equipment } = this.props;
    const eType = getEqType(equipment);
    const isA1 = isAerasOne(eType);

    if (!isA1) { return null }

    if (!this.state.searchingLinkedEq && !this.state.foundLinkedEq) { this.findLinkedEq() }

    if (!size(this.state.foundLinkedEq)) { return null }

    const clickEq = async (eq: Equipment) => {
      this.props.history.goBack();
      await wait(50);
      this.props.history.push('/dashboard/equipmentDetails', {
        equipment: eq,
      });
      this.setState({ searchingLinkedEq: false, foundLinkedEq: undefined })
    }

    return (
      <div css={css(sbstyles.infoDivide)}>
        <div css={css(styles.title)}>Associated Equipment</div>
        {!this.state.searchingLinkedEq && <div>
          {(this.state.foundLinkedEq || []).map(eq => {
            return (
              <button key={eq.deviceId} css={css(styles.link)} onClick={() => clickEq(eq)}>{`${eq.name || eq.equipmentSN}`}</button>
            )
          })}
        </div>}
      </div>
    )
  }

  renderDebug = () => {
    const { loading } = this.state;
    const {
      model,
      equipment,
      sState,
      config,
    } = this.props;

    const isHandpiece = get(model, 'type') === 'handpiece';
    const eIsChair = isChair(equipment);
    const showDebug = get(this, 'props.model.showDebug', true);
    const userIsDezReadOnly = userHasRole([7], sState);
    const eType = getEqType(this.props.equipment);
    const isA1 = isAerasOne(eType);

    if (!config || !userHasRole([0], sState) || eIsChair || !showDebug || isA1) { 
      return null 
    }
    
    return (
      <div css={css(SharedStyles.row, sbstyles.button)}>
        {loading ? (
          <Icon type="loading" css={css(sbstyles.iconMarg)} />
        ) : (
          <_Icon css={css(sbstyles.iconMarg)} name={'bug'} />
        )}
        <div css={css('margin-right: 5px;')}>{'Debug Mode'}</div>
        <Switch
          size="small"
          onChange={this.onDebugClick}
          checked={
            isHandpiece
              ? !!getDebugStatusHandpiece(sState, equipment.deviceId)
              : !!getDebugStatus(config)
          }
        />
      </div>
    )
  }

  render() {
    const { debugModal, loading, replaceModal, hw } = this.state;
    const {
      onEditClick,
      serviceOrg,
      model,
      equipment,
      sState,
      config,
      isPhoneBreak,
      showTandemGroup,
      maint,
    } = this.props;

    const isVacuum = get(model, 'type') === 'vacuum';
    const isCompressor = get(model, 'type') === 'compressor';
    const isHandpiece = get(model, 'type') === 'handpiece';
    const eIsChair = isChair(equipment);
    const showDebug = get(this, 'props.model.showDebug', true);
    const fw = get(config, 'fw_version') || get(equipment, 'fw_version');
    const hpRevsPublishes =
      isHandpiece && get(this.props, `equipment._status.dz_hp_revs`);
    const hpRevs = isHandpiece && hpRevsPublishes;
    const autoWash = getAutoWashStatus(config);
    const showHeads = get(this, 'props.showHeads', true);
    const showReplace = get(this, 'props.showReplace', false);
    const subHistory = getSubscriptionHistory(equipment);
    const userIsOrgReadOnly = userHasRole([6], sState);
    const userIsDezReadOnly = userHasRole([7], sState);
    const chairMount = eIsChair && chairMountTrue(config);
    const eType = getEqType(this.props.equipment);
    const isA1 = isAerasOne(eType);

    return (
      <div css={css(styles.sideMenu)}>
        {!isPhoneBreak && (
          <ModelImage
            css={css('min-height: 142px; margin-bottom: 20px;')}
            model={model}
            config={config}
          />
        )}

        {onEditClick && !userIsOrgReadOnly && !userIsDezReadOnly && (
          <div
            onClick={onEditClick}
            css={css(SharedStyles.row, sbstyles.button)}
          >
            <Icon css={css(sbstyles.iconMarg)} type={'edit'} />
            <div>{'Edit Details'}</div>
          </div>
        )}

        {this.renderDebug()}

        {config &&
          config.data &&
          (config.data as HandpieceConfigPData).dental_school !== undefined && (
            <div css={css(SharedStyles.row, sbstyles.button)}>
              {loading ? (
                <Icon type="loading" css={css(sbstyles.iconMarg)} />
              ) : (
                <_Icon css={css(sbstyles.iconMarg)} name={'lock'} />
              )}
              <div css={css('margin-right: 5px;')}>{'Lock Presets'}</div>
              <Switch
                size="small"
                onChange={this.onLockPresetsClick}
                checked={this.state.lockPresets}
              />
            </div>
          )}

        {userHasRole([0], sState) && eIsChair && (
          <div css={css(SharedStyles.row, sbstyles.button)}>
            {loading ? (
              <Icon type="loading" css={css(sbstyles.iconMarg)} />
            ) : null}
            <div css={css('margin-right: 5px;')}>{'Chair Mount'}</div>
            <Switch
              size="small"
              onChange={this.toggleChairMount}
              checked={chairMount}
            />
          </div>
        )}

        {userHasRole([0], sState) && (
          <div css={css(SharedStyles.row, sbstyles.button)}>
            {loading ? (
              <Icon type="loading" css={css(sbstyles.iconMarg)} />
            ) : null}
            <div css={css('margin-right: 5px;')}>{'Show in Web App'}</div>
            <Switch
              size="small"
              onChange={this.onHideClick}
              checked={get(equipment, 'showOnDash', true)}
            />
          </div>
        )}

        {config && userHasRole([0, 1], sState) && isVacuum && (
          <div css={css(SharedStyles.row, sbstyles.button)}>
            {loading ? (
              <Icon type="loading" css={css(sbstyles.iconMarg)} />
            ) : (
              <Icon type={'login'} css={css(sbstyles.iconMarg)} />
            )}
            <Button
              css={css('margin-right: 5px;')}
              title={'Cycle Autowash'}
              size={'small'}
              disabled={loading || autoWash}
              onClick={
                loading || autoWash ? () => 0 : () => this.onAutoWashClick()
              }
            />
          </div>
        )}

        {config && userHasRole([0, 1], sState) && isCompressor && (
          <div css={css(SharedStyles.row, sbstyles.button)}>
            {loading ? (
              <Icon type="loading" css={css(sbstyles.iconMarg)} />
            ) : (
              <Icon type={'sync'} css={css(sbstyles.iconMarg)} />
            )}
            <Button
              css={css('margin-right: 5px;')}
              title={'Cycle Dryer Towers'}
              size={'small'}
              disabled={loading}
              onClick={this.onCycleDryer}
            />
          </div>
        )}

        <div css={css(styles.hr)} />

        {serviceOrg && (
          <div css={css(SharedStyles.row)}>
            <_Icon name="tools" css={css(sbstyles.iconMarg)} />
            <div css={css(styles.title, `margin-bottom: 4px;`)}>
              Service Provider
            </div>
          </div>
        )}
        {serviceOrg && (
          <div>
            {serviceOrg.name && <p>{serviceOrg.name.toUpperCase()}</p>}
            {this.renderAddress()}
            <EditableP
              italic={!equipment.serviceOrgNumber}
              text={
                equipment.serviceOrgNumber
                  ? `${formatPhoneNumber(equipment.serviceOrgNumber)}`
                  : 'No Phone Provided'
              }
              onSave={this.onEditPhone}
            />
          </div>
        )}

        {serviceOrg && <div css={css(styles.hr)} />}

        {this.renderLinkedEq()}

        {model && model.id && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Model Number</div>
            <p>{model.id}</p>
          </div>
        )}

        {equipment.equipmentSN && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Serial Number</div>
            <p>{equipment.equipmentSN}</p>
          </div>
        )}

        {!!equipment && !!equipment.mSerial_motorboard && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Motor Control Board SN</div>
            <p>{equipment.mSerial_motorboard}</p>
          </div>
        )}

        {!!equipment &&
        !!equipment.installDate &&
        equipment.installDate !== 'null' ? (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Install Date</div>
            <p>{moment(equipment.installDate as string).format('L')}</p>
          </div>
        ) : equipment.DOSaleDate ? (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Transfer Date</div>
            <p>{moment(equipment.DOSaleDate).format('L')}</p>
          </div>
        ) : null}

        {!!equipment.serviceRenewalDate && subHistory.active ? (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Service Renewal Date</div>
            <p>{moment(equipment.serviceRenewalDate).format('L')}</p>
          </div>
        ) : null}

        {!!userHasRole([0, 1, 7], sState) && equipment && equipment.deviceId && !isA1 && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Aeras Controller SN</div>
            <p>{equipment.controllerSerial}</p>
            {showReplace && (
              <Button
                title={eIsChair ? "Replace Daughter Board" : "Replace Aeras Controller"}
                size={'small'}
                onClick={this.replaceController}
              />
            )}
          </div>
        )}

        {!!equipment && !!equipment.deviceId && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Device ID</div>
            <p>{equipment.deviceId}</p>
          </div>
        )}

        {!!equipment && !isHandpiece && !!fw && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Firmware</div>
            <p>{`v${fw}`}</p>
          </div>
        )}

        {!!equipment && isHandpiece && hpRevs && hpRevs.data && (
          <div>
            <div css={css(sbstyles.infoDivide)}>
              <div css={css(styles.title)}>MCB Hardware</div>
              <p>{`v${get(hpRevs, 'data.mcb_hw_rev')}`}</p>
            </div>
            <div css={css(sbstyles.infoDivide)}>
              <div css={css(styles.title)}>MCB Firmware</div>
              <p>{`v${get(hpRevs, 'data.mcb_fw_rev')}`}</p>
            </div>

            <div css={css(sbstyles.infoDivide)}>
              <div css={css(styles.title)}>Display Hardware</div>
              <p>{`v${get(hpRevs, 'data.display_hw_rev')}`}</p>
            </div>

            <div css={css(sbstyles.infoDivide)}>
              <div css={css(styles.title)}>Display Firmware</div>
              <p>{`v${get(hpRevs, 'data.display_fw_rev')}`}</p>
            </div>
          </div>
        )}

        {!!equipment && !isHandpiece && !!hw && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Hardware</div>
            <p>{renderHWVersion(hw)}</p>
          </div>
        )}

        {[
          { val: get(config, 'data.iot_board_fw'), title: 'IOT Firmware' },
          { val: get(config, 'data.iot_board_hw'), title: 'IOT Hardware' },
          { val: get(config, 'data.control_board_fw'), title: 'CB Firmware' },
          { val: get(config, 'data.control_board_hw'), title: 'CB Hardware' },
        ].map(m => {
          return m.val ? (
            <div css={css(sbstyles.infoDivide)}>
              <div css={css(styles.title)}>{m.title}</div>
              <p>{m.val}</p>
            </div>
          ) : null;
        })}

        {(isCompressor || isVacuum) &&
          showTandemGroup &&
          this.renderTandemGroup()}

        {!!model &&
          !!model.heads &&
          !!showHeads &&
          this.renderMotorHeadSerials()}

        {!!userHasRole([0, 1, 7], sState) &&
          equipment &&
          equipment.deviceId &&
          !!model &&
          !!model.heads &&
          !!showHeads && getAppConfig('ss_values', []).includes(model.id) && (
            <div css={css(sbstyles.infoDivide)}>
              {showReplace && <SSUpgradeBtn {...this.props} />}
            </div>
          )}

        {isCompressor && maint && get(maint, 'data.serial_numbers.desdryers') && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Desiccant</div>
            <p>Serial #: {get(maint, 'data.serial_numbers.desdryers')}</p>
          </div>
        )}
        {isVacuum && maint && get(maint, 'data.serial_numbers.pump') && (
          <div css={css(sbstyles.infoDivide)}>
            <div css={css(styles.title)}>Pump</div>
            <p>Serial #: {get(maint, 'data.serial_numbers.pump')}</p>
          </div>
        )}

        {isVacuum &&
          !!config &&
          !!config.data &&
          this.renderInfinitank(!!get(config.data, 'infinitank'))}

        {userHasRole([0, 1, 7], this.props.sState) &&
          equipment.testDataUrl &&
          this.renderTestResults()}

        <ReplaceController {...(replaceModal as ReplaceProps)} equipment={equipment} />

        {debugModal && (
          <Modal
            visible={true}
            title={debugModal.title}
            centered
            closable
            confirmLoading={loading}
            okText="Enable Debug Mode"
            onCancel={this.debugClose}
            onOk={this.parseDebug}
          >
            <div css={css(modalStyles.content)}>{debugModal.content}</div>
            <div css={css(modalStyles.dividers)}>
              <div css={css(modalStyles.label)}>{'Frequency: '}</div>
              <InputNumber
                value={debugModal.frequency}
                min={0}
                max={999}
                type={'number'}
                onChange={e => this.checkValueChange(e, 999, 'frequency')}
              />
              <div css={css(modalStyles.label, 'margin-left: 10px;')}>
                {'seconds'}
              </div>
            </div>
            <div css={css(modalStyles.dividers)}>
              <div css={css(modalStyles.label)}>{'Duration: '}</div>
              <InputNumber
                value={debugModal.duration}
                disabled={debugModal.indefinite}
                min={0}
                max={99999}
                type={'number'}
                onChange={e => this.checkValueChange(e, 99999, 'duration')}
              />
              <div css={css(modalStyles.label, 'margin-left: 10px;')}>
                {'minutes'}
              </div>
            </div>
            <div css={css(modalStyles.dividers)}>
              <Checkbox
                css={css(modalStyles.label)}
                onChange={e =>
                  this.setState({
                    debugModal: {
                      ...debugModal,
                      indefinite: e.target.checked,
                    },
                  })
                }
                checked={debugModal.indefinite}
              >
                Indefinite
              </Checkbox>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

//@ts-ignore
export const Sidebar = withRouter(connect((sState: AppState) => ({ sState }))(_Sidebar));
