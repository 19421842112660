/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList } from '../../_shared/table-list';
import { Alert } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import { chain, get, set } from 'lodash';
import _ from 'lodash';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { getModels } from '../../_shared/services/manage-models.service';
import { Model } from '../../_shared/interfaces/model';
import FormTitle from '../../_shared/form-title';
import { sortByValue } from '../../utils';
import { Button } from '../../_shared/button';
import { ColumnFilterItem, ColumnProps } from 'antd/lib/table';

const Columns = (_this: _ManageModelsComponent): ColumnProps<Model>[] => [
  {
    title: 'Model ID',
    dataIndex: 'id',
    width: '25%',
    filters: [],
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '25%',
    sorter: (a: Model, b: Model) => a.name.localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
    filters: [],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '25%',
    sorter: (a: Model, b: Model) => a.type.localeCompare(b.type),
    sortDirections: ['ascend', 'descend'],
    filters: [],
  },
  {
    title: '# Installed',
    dataIndex: 'installed',
    width: '25%',
    filters: [],
  },
];

interface IProps extends RouteComponentProps {
  sState: AppState;
}

class _ManageModelsComponent extends Component<IProps> {
  state = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    data: [],
    models: [],
    equipment: [],
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    this.retrieveModels();
  };
  manageModel = (model: Model) => {
    this.props.history.push('/dashboard/manageModel', {
      model,
    });
  };
  selectModel = (record: { _model: Model }, index: number) => {
    this.manageModel(record._model);
  };
  retrieveModels = async (shouldFetch = true) => {
    const state = { ...this.state };

    state.loading = true;

    this.setState(state);

    try {
      const [models, equipment] = await Promise.all([
        shouldFetch ? await getModels() : state.models,
        shouldFetch ? await getEquipments() : state.equipment,
      ]);

      const modelMap = chain(models)
        .keyBy('id')

      equipment.map(e => {
        if (e.dentalOrgId && e.locationId && e.modelId) {
          const count = get(
            modelMap,
            `${e.modelId}._installCount`,
            0
          ) as number;
          set(modelMap, `${e.modelId}._installCount`, count + 1);
        }
      });

      const data = [...models]
        .map((model, i) => {
          return {
            ...model,
            _model: { ...model },
            installed: get(modelMap, `${model.id}._installCount`, 0),
            value: model.id,
          };
        })
        .sort(sortByValue);

      state.data = data as never[];
      state.models = models as never[];
      state.equipment = equipment as never[];
      state.columns = Columns(this);
      const types = _.uniq(models.map(m => m.type));
      state.columns[2].filters = _.map(types, t => ({ text: t, value: t })).sort();
      state.columns[2].onFilter = (value: string, record: Model) => record.type == value;
      state.loading = false;

      this.setState(state);
    } catch (err) {
      console.warn(err);
    }
  };

  addOrEdit = (model?: Model) => {
    this.props.history.push(
      '/dashboard/editModel',
      {
        model,
        backText: '< Back to Models',
        editingModel: false,
      }
    )
  };


  render() {
    const { columns, data, loading, error, success } = this.state;

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <FormTitle size={'18px'}>
          {'Models'}
          <Button
            size="small"
            css={css('margin-left: 10px;')}
            loading={loading}
            title="Refresh"
            onClick={() => this.retrieveModels(true)}
          />
        </FormTitle>

        <div css={css(SharedStyles.pbButtonContainer)}>
          {(
            <Button
              title={`Add Model`}
              onClick={() => this.addOrEdit()}
            />
          )}
        </div>

        <TableList
          showPagination={false}
          canSelectRows={false}
          loading={loading}
          columns={columns}
          data={data}
          rowClick={this.selectModel}
          includeCount="Models Count"
        />
      </div>
    );
  }
}

export const ManageModelsComponent = withRouter(_ManageModelsComponent);
