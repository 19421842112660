import colors from '../../_shared/colors';

export default {
  select: `
    width: 20%;
    margin-right: 10px;
  `,
  modalContainer: `
  `,
  modalHeader: `
    font-weight: bold;
  `,
  modalBody: `
    font-size: 12px;
    background-color: rgba(0,0,0,0.1);
    margin: 10px 0;
  `,
  noteUserModalTitle: `
    font-weight: bold;
  `,
  recipRow: `
    cursor: pointer;

    i {
      color: ${colors.highlight};
    }
  `,
  generateText: `
    max-width: 400px; 
    text-align: center;
  `
};
