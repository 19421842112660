import { AppState } from '../app.state';
import {
  get,
  set,
  isEqual,
  uniqBy,
  flatten,
  sortBy,
  size,
  take,
  isObject,
  last,
  orderBy,
  uniq,
} from 'lodash';
import v4 from 'uuid/v4';
import { User, _User } from '../_shared/interfaces/user';
import { Sched, Schedule } from '../_shared/interfaces/schedule';
import { Publish } from '../_shared/interfaces/publish';
import moment, { Moment } from 'moment';
import colors from '../_shared/colors';
import {
  Equipment,
  EquipmentRecord,
  PSerials,
  Subscription,
  SUBSCRIPTION_TYPE,
  SUB_TYPE_DISPLAY,
  _Equipment,
} from '../_shared/interfaces/equipment';
import { Model } from '../_shared/interfaces/model';
import getConfig from '../config';
import { serviceType, IServiceType } from '../_shared/lib/services';
import { Address, Org } from '../_shared/interfaces/org';
import { Sensor } from '../_shared/interfaces/sensor';
import { AlertStatus, EQStatus, EquipmentStatus } from '../_shared/interfaces/equipmentStatus';
import { EQMap } from '../dashboard/dashboard.reducer';
import Crypto, { AES } from 'crypto-js';
import { Service } from '../_shared/interfaces/service';
import { getTandemGroups } from '../_shared/services/manage-tandem-groups.service';
import { TandemGroup } from '../_shared/interfaces/tandemGroup';
import { getPreSignedUrl } from '../_shared/services/publish.service';
import { IPrefixMatches } from '../_shared/interfaces/prefixMatches';
import { getProfiles } from '../_shared/services/manage-profiles.service';
import { Profile } from '../_shared/interfaces/profile';
import { getUsers } from '../_shared/services/manage-users.service';
import { EQ_TYPE } from '../_shared/interfaces/equipment';
import { Location } from '../_shared/interfaces/location';

const CRYPT_KEY = 'DentalEZ2020';

export const defaultDateFormat = 'MMM Do YYYY h:mm a';

//@ts-ignore
// tslint:disable-next-line
export const getStore = (store?: any) => {
  return store || get(window, 'STORE', { getState: () => { } });
};

export const getActiveModels = () => {
  return get(getStore().getState(), 'dash.models', []).filter((m: Model) =>
    get(m, 'isActive', 1)
  );
};

export const isHandpiece = (equipment: Equipment): boolean => {
  const equipType = getEquipmentType(
    equipment.equipmentSN as string,
    equipment.modelId as string
  );
  return equipType == EQ_TYPE.Handpiece;
};

export const isChair = (equipment: Equipment): boolean => {
  const equipType = getEquipmentType(
    equipment.equipmentSN as string,
    equipment.modelId as string
  );
  return equipType == EQ_TYPE.Chair;
};

export const getEquipConfig = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  return get(sState, `dash.equipMap.${e && e.deviceId}.configs.0`, get(e, '_status.dz_config', get(e, '_status.ai_config', get(e, '_status.chair_config', def))));
};

export const equipIsMetric = (e: Equipment | undefined, sState: AppState) => {
  const config = getEquipConfig(e, sState);
  return get(config, 'data.metric', false);
};

export const generatePin = (amt = 6) => {
  return Math.random()
    .toString()
    .substr(2, amt);
};

export const cleanPhoneNumber = (num: string) => {
  if (!num) {
    return '';
  }
  if ((num || '').trim() === '+') {
    return '';
  }
  const number = num.replace(/[^\d]/g, '');
  return number.length === 10 ? `+1${number}` : `+${number}`;
};

export const displayPhoneNumber = (num: string) => {
  return cleanPhoneNumber(num).replace('+', '');
};

export const getEquipSchedule = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  return get(sState, `dash.equipMap.${e && e.deviceId}.schedules.0`, get(e, '_status.dz_schedule', def));
};

export const getEquipAlertMap = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  return get(sState, `dash.equipMap.${e && e.deviceId}.alertMap`, def);
};

export const getEquipAlerts = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  return get(sState, `dash.equipMap.${e && e.deviceId}.alerts`, def);
};

export const getEquipThresholds = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  return get(sState, `dash.equipMap.${e && e.deviceId}.thresholds`, def);
};

export const getEquipSensors = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  return get(sState, `dash.equipMap.${e && e.deviceId}.sensorStatistics`, def);
};

export const getEquipMaint = (
  e: Equipment | undefined,
  sState: AppState,
  def: undefined | {} = undefined
) => {
  const ids = getAllDeviceIds(e);
  const onEq = get(e, '_status.dz_maint') as unknown as Publish;
  const maint = [onEq ? [onEq] : []] as Array<Publish[]>;
  ids.map(_id => {
    const id = get(_id, 'id', _id);
    maint.push(get(sState, `dash.equipMap.${id}.maint`, def));
  });
  return flatten(maint).filter(f => !!f);
};

export const getMyOrg = (sState: AppState) => {
  const me = get(sState, 'auth.user');
  const orgs = get(sState, 'dash.orgs', []) as Org[];
  return me ? orgs.find(o => o.id === me.orgId) : null;
};

export const getAllMyOrgs = (appState: AppState): Org[] => {
  const me = get(appState, 'auth.user');
  const orgs = get(appState, 'dash.orgs', []) as Org[];
  return me ? orgs.filter(o => o.id === me.orgId) : [];
};

export const userHasCompOrVac = (sState: AppState) => {
  const { equipment } = get(sState, 'dash');

  return !!(equipment || []).find(e => {
    const model = getEquipmentModel(e, sState);
    const type = get(model, 'type');
    return !type ? undefined : ['compressor', 'vacuum'].includes(type);
  })
};

export const getMyOrgStrings = (sState: AppState) => {
  const orgStrings: string[] = [];
  const orgs = get(sState, 'dash.orgs', []) as Org[];
  orgs.map((o: Org) => {
    if (o.serviceOrgId) {
      orgStrings.push(o.serviceOrgId);
    }
    orgStrings.push(o.id);
  });
  return orgStrings;
};

export const checkHasFilter = (sState: AppState) => {
  const filters = get(sState, 'dash.equipmentFilters', {});
  let has = false;
  for (let k in filters) {
    const opt = filters[k];
    if (opt) {
      has = true;
      break;
    }
  }
  return has;
};

export const getEquipmentModel = (eq: Equipment, _sState?: AppState) => {
  const sState = _sState || getStore().getState();
  const models = get(sState, 'dash.models', []) as Model[];
  return models.find(m => eq.modelId === m.id);
};

export const getEquipTestResults = (
  e: Equipment,
  sState: AppState,
  def?: undefined | {}
) => {
  return get(
    sState,
    `dash.equipMap.${e && e.deviceId}.testresults`,
    get(e, 'status.test', def)
  ) as Publish[];
};

export const getSensor = (id: string, sState: AppState) => {
  const sensors = get(sState, 'dash.sensors', []) as Sensor[];
  return sensors.find(s => s.id === id);
};

export const getSensorsForEquipmentType = (equipmentType: string | undefined, sState: AppState): Sensor[] => {
  if (equipmentType === undefined) return [];
  const sensors = get(sState, 'dash.sensors', []) as Sensor[];
  let filter =
    equipmentType.includes("compressor") ? (s: Sensor) => s.isCompressor == 1 :
      equipmentType.includes("vacuum") ? (s: Sensor) => s.isVacuum == 1 :
        equipmentType.includes("sterilizer") ? (s: Sensor) => s.isSterilizer == 1 :
          equipmentType.includes("handpiece") ? (s: Sensor) => s.isHandpiece == 1 :
            equipmentType.includes("chair") ? (s: Sensor) => s.isChair == 1 :
              (s: Sensor) => false;
  if (equipmentType.includes("aerasone")) {
    return sensors.filter(filter).filter(s => s.isAerasOne == 1);
  }
  return sensors.filter(filter);
};

// tslint:disable-next-line:no-any
export const getObjectDiff = (obj1: any, obj2: any) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const singleStringAddress = (addr?: Address | string) => {
  if (!addr) {
    return '';
  } else if (typeof addr === 'string') {
    return addr;
  } else {
    let str = '';
    const order = ['address', 'address2', 'city', 'state', 'zip'];
    order.map(o => {
      const val = get(addr, o);
      if (val) {
        str += `${str ? ', ' : ''}${val}`;
      }
    });
    return str;
  }
};

export const buildErrorMsgFromForm = (objErrors: {
  [key: string]: { errors: { message: string }[] };
}) => {
  let message = '';
  for (let key in objErrors) {
    let all = get(objErrors, `${key}.errors`, []) as { message: string }[];
    all.map(e => {
      message = message + (message.includes(e.message) ? '' : e.message + '\n');
    });
  }
  return message;
};

export const singleOrAverage = (
  val: [] | number | string | undefined | null,
  transform?: (v: number) => number
) => {
  let v = val;

  if (Array.isArray(val)) {
    let sum = 0;
    val.map(va => (sum += va));
    v = (sum / val.length).toFixed(1);
  }

  if (transform) {
    v = transform(v as number);
  }

  return v as number;
};

export const sortByFirstThenLastName = (a: User, b: User) => {
  const aName = getUsersName(a).toLowerCase();
  const bName = getUsersName(b).toLowerCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const sortByName = (a: { name?: string }, b: { name?: string }) => {
  const aName = get(a, 'name', '').toLowerCase();
  const bName = get(b, 'name', '').toLowerCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const sortByLabel2 = (a: { label: string }, b: { label: string }) => {
  const aName = `${get(a, 'label', '')}`.toLowerCase();
  const bName = `${get(b, 'label', '')}`.toLowerCase();

  if (aName < bName) return -1;
  if (aName > bName) return 1;
  return 0;
};

export const sortByLabel = (a: { label: string }, b: { label: string }) => {
  const aName = `${get(a, 'label', '')}`.toLowerCase();
  const bName = `${get(b, 'label', '')}`.toLowerCase();

  return aName.localeCompare(bName, 'en', { numeric: true });
};

export const sortById = (
  a: { id: number | string },
  b: { id: number | string }
) => {
  const aId = get(a, 'id', '');
  const bId = get(b, 'id', '');
  if (!aId) return 1;
  if (!bId) return -1;
  return aId < bId ? -1 : 1;
};

// tslint:disable-next-line: no-any
export const sortByKey = (a: any, b: any, key: string | number) => {
  if (!a || !a[key]) return 1;
  if (!b || !b[key]) return -1;
  if (a[key].toString().toLowerCase() === b[key].toString().toLowerCase())
    return 0;
  return a[key].toString().toLowerCase() < b[key].toString().toLowerCase()
    ? -1
    : 1;
};

// tslint:disable-next-line: no-any
export const sortByLastThenFirstName = (a: any, b: any): number => {
  if (
    get(a, 'lastName', '').toLowerCase() < get(b, 'lastName', '').toLowerCase()
  )
    return -1;
  if (
    get(a, 'lastName', '').toLowerCase() > get(b, 'lastName', '').toLowerCase()
  )
    return 1;
  if (
    get(a, 'firstName', '').toLowerCase() <
    get(b, 'firstName', '').toLowerCase()
  )
    return -1;
  if (
    get(a, 'firstName', '').toLowerCase() >
    get(b, 'firstName', '').toLowerCase()
  )
    return 1;
  return 0;
};

export const sortByValue = (a: { value: string }, b: { value: string }) => {
  const aName = get(a, 'value', '').toLowerCase();
  const bName = get(b, 'value', '').toLowerCase();

  return aName.localeCompare(bName, 'en', { numeric: true });
};

export const findServiceInTypes = (name: string) => {
  let key = get(name, 'value', get(name, 'key', name));
  let found = get(serviceType, key);
  if (!found) {
    for (let k in serviceType) {
      const stype = get(serviceType, k);
      const oname = get(stype, 'name');
      if (oname == key) {
        found = stype;
        key = k;
      }
    }
  }
  return found ? { ...found, key } : undefined;
};

export const getEmailFromText = (str: string) => {
  // tslint:disable-next-line:max-line-length
  var reg = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  const matches = str.match(reg);
  return matches && matches.length > 0 ? matches[0] : matches;
};

export const getValueBetweenParen = (str: string) => {
  const reg = /\(([^)]+)\)/;
  return get(str.match(reg), '1');
};

interface WHERE {
  FilterExpression: string;
  ExpressionAttributeValues: {
    [key: string]: string;
  };
  KeyConditionExpression?: string;
  Limit?: number;
  IndexName?: string;
}
// tslint:disable-next-line:no-any
export const convertWhere = (__where: any, opts = {}) => {
  const where: WHERE = {
    FilterExpression: '',
    ExpressionAttributeValues: {},
    Limit: undefined,
    ...opts,
  };

  const parse: { [key: string]: string | number } = {};

  // tslint:disable-next-line:no-any
  const parseObj = (_where: any, isOr = false) => {
    for (let k in _where) {
      if (k.startsWith('_') && k !== '_data_') {
        const _k = k.substring(1, k.length);
        parse[_k] = _where[k];
      } else if (k === 'pk') {
        where.KeyConditionExpression = `pk = :pk`;
        where.ExpressionAttributeValues[':pk'] = _where[k];
      } else {
        const obj: { eq?: string; contains?: string; or?: string[] } =
          _where[k];
        const isAnd =
          where.FilterExpression.length > 0 ? (isOr ? ' or ' : ' and ') : '';

        if (obj.eq) {
          where.FilterExpression += `${isAnd}${k} = :${k}`;
          where.ExpressionAttributeValues[`:${k}`] = obj.eq;
        } else if (obj.contains) {
          where.FilterExpression += `${isAnd}contains(${k}, :${k})`;
          where.ExpressionAttributeValues[`:${k}`] = obj.contains;
        } else if (obj.or) {
          let ors = `${isAnd ? ' and ' : ''}`;
          obj.or.map((a, i) => {
            const isFirst = i === 0;
            const isLast = obj.or && i === obj.or.length - 1;
            const varName = `:${k}${i}`;
            const or = `${isFirst ? '(' : ''}${k} = ${varName}${isLast ? ')' : ' or '
              }`;
            ors += or;
            where.ExpressionAttributeValues[varName] = a;
          });
          where.FilterExpression += ors;
        }
      }
    }
  };

  if (Array.isArray(__where)) {
    __where.map(o => parseObj(o, true));
    where.FilterExpression = '(' + where.FilterExpression + ')';
  } else {
    parseObj(__where);
  }

  // if (parse.limit) {
  //   where.Limit = parse.limit as number;
  // }

  return __where
    ? {
      where,
      parse,
    }
    : {};
};

export const convertStatusToPublish = (stat: EquipmentStatus, name: string) => {
  const data = get(stat, name);
  if (get(data, 'coreid')) {
    return data as Publish;
  }
  return {
    id: guid(),
    event: name,
    coreid: stat.coreid,
    data,
    published_at: get(data, 'published_at'),
  } as Publish;
};

export const getUsersForUser = async (sState: AppState) => {
  const currentUser = get(sState, 'auth.user');
  const userIsSuperOrTech = userHasRole([0, 1, 7], sState);
  if (userHasRole([5], sState) || !currentUser) {
    return [];
  }
  let users: User[] = [];
  if (userIsSuperOrTech) {
    users = await getUsers();
  } else {
    users = await getUsers({ orgId: { eq: currentUser.orgId } });
  }
  return users;
};

export const getTandemGroupsForUser = async (
  sState: AppState,
  _equipment: Equipment[]
) => {
  const user = get(sState, 'auth.user');
  if (!user) {
    return [];
  }
  const userIsSuperOrTech = userHasRole([0, 1, 7], sState);
  const tandemGroups = await getTandemGroups();

  if (userIsSuperOrTech) {
    const tgsWithEquip = tandemGroups.map(tg => {
      const equipment = _equipment.filter(
        e => e.tandemGroupId == tg.id
      ) as Equipment[];
      equipment.map((equip: Equipment) => {
        if (!tg.equipment) {
          tg.equipment = [];
        }
        tg.equipment.push(get(equip, 'id') as string);
      });
      return tg;
    });

    const tandemGroupsToDisplay = tgsWithEquip.filter(tg => {
      return !!size(get(tg, 'equipment', []));
    });
    return tandemGroupsToDisplay;
  }

  const equipWTg = uniqBy(
    _equipment.filter(equip => !!equip.tandemGroupId),
    'tandemGroupId'
  );
  const tgIds: string[] = equipWTg.map(equip => equip.tandemGroupId as string);

  return tandemGroups.filter(t => tgIds.indexOf(t.id) > -1) as TandemGroup[];
};

export const getUserOrg = (sState: AppState) => {
  const { dash, auth } = sState;
  const user = get(auth, 'user');
  const orgs = get(dash, 'orgs');
  return orgs.find(org => org.id === get(user, 'orgId'));
};

export const getProfilesForUser = async (sState: AppState) => {
  const user = get(sState, 'auth.user');
  if (!user) {
    return [];
  }
  const orgId = user.orgId;
  let profiles: Profile[] = [];
  if (orgId) profiles = await getProfiles({ dentalOrgId: { eq: user.orgId } });
  return profiles;
};

export const getTimeForPubAlert = (publish: Publish) => {
  const errorTime = get(publish, 'data.time');
  const pubTime = get(publish, 'published_at', new Date());

  return errorTime ? convertDZNowToJSNow(errorTime) : pubTime;
};

export const convertDZNowToJSNow = (time: number) => {
  return time * 1000;
};

export const getDebugStatusHandpiece = (
  sState: AppState,
  deviceId?: string
): boolean => {
  if (!deviceId) return false;

  const hpDebugPublishes = get(
    sState,
    `dash.equipMap.${deviceId}.hp_debug_mode`
  );

  // it might be -1 marking an indefinite duration so need to check explicitly for 0 instead of anything larger
  return (
    hpDebugPublishes &&
    hpDebugPublishes[0] &&
    hpDebugPublishes[0].data.duration !== 0
  );
};

export const getDebugStatus = (config?: Publish) => {
  return get(
    config,
    'data.statistics_interval.0',
    get(config, 'data.statistics_interval')
  );
};

export const chairMountTrue = (config?: Publish) => {
  return !!get(
    config,
    'data.chair_mount',
    1,
  );
}

export const getAutoWashStatus = (config?: Publish) => {
  return get(config, 'data.autowash', false);
};

export const findLatestPublishInMap = (map: EQMap) => {
  const loops = [
    'alerts',
    'configs',
    'maint',
    'schedules',
    'sensorStatistics',
    'thresholds',
  ];
  const marker = {
    latest: '',
    publish: undefined,
  };
  loops.map(l => {
    const lat = findLatestPublish(get(map, l, []));
    if (
      lat &&
      lat.published_at &&
      (!marker.latest || moment(lat.published_at) > moment(marker.latest))
    ) {
      set(marker, 'latest', lat.published_at);
      set(marker, 'publish', lat);
    }
  });
  return (marker.publish as unknown) as Publish;
};

export const getDeviceStatusFromEquip = (equipment?: Equipment) => {
  const eqType = getEqType(equipment as Equipment);

  const isAlwaysOn = false;
  const isSpecial = ['handpiece', 'sterilizer'].includes(eqType);

  const deviceStatus = get(equipment, 'deviceStatus', 'off');
  const on = isAlwaysOn ? true : deviceStatus === 'on';
  const off = isAlwaysOn ? false : deviceStatus === 'off';
  const standby = isAlwaysOn
    ? false
    : get(equipment, 'deviceStatus', 'off') === 'standby';
  const debug = get(equipment, 'modes.debug', false);
  const maint = get(equipment, 'modes.maintenance', false);
  const highPressure = get(equipment, 'modes.high_pressure', false);
  const highVoltage = get(equipment, 'modes.high_voltage', false);
  const headsEnabled = get(equipment, 'heads_enabled', [true, true, true]);
  const scheduleEnabled = isSpecial ? true : get(equipment, 'schedule_enabled', true);

  // tslint:disable-next-line:no-any
  const ret: { [key: string]: any } = {
    debug,
    maint,
    on,
    off,
    standby,
    headsEnabled,
    highPressure,
    scheduleEnabled,
    highVoltage,
    deviceStatus,
    isAlwaysOn,
  };

  return ret;
};

export const sortPublishByDate = (a: Publish, b: Publish) => {
  return (
    new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
  );
};

export const sortPublishByTimeOrDate = (a: Publish, b: Publish) => {
  return (
    new Date(moment(getTimeForPubAlert(b)).toISOString()).getTime() -
    new Date(moment(getTimeForPubAlert(a)).toISOString()).getTime()
  );
};

const modelImagePath = (model?: Model, eqConfig?: Publish, name?: string) => {
  // explicit empty string overrides default handling, for backwards compat
  if (name == '') {
    return 'image';
  }
  if (name) {
    return 'images.' + name;
  }
  if (get(model, 'type') === 'chair' && chairMountTrue(eqConfig)) {
    return 'images.chairMount0';
  }
  return 'image';
};

export const modelImageSource = (model?: Model, eqConfig?: Publish, name?: string) => {
  if (!model) return null;
  const imagePath = modelImagePath(model, eqConfig, name);
  const image = get(model, imagePath, '');
  if (!image) return null;
  const config = getConfig();
  return `${config.model_photos}${image}`;
};

export const findModel = (equipment: Equipment, sState: AppState) => {
  const models = get(sState, 'dash.models', []);
  return models.find((m: Model) => m.id === equipment.modelId);
};

export const findSensor = (pub: Publish, sState: AppState) => {
  const sensors = get(sState, 'dash.sensors', []);
  const es = get(pub, 'data.source');
  return sensors.find((m: Model) => m.id === es);
};

export const pullDataOutOfString = (str: string) => {
  try {
    const iof = str.indexOf('{');
    const dstring = str.substring(iof, str.length);
    const parsed = parseJson(dstring);
    return parsed && !parsed._invalid_ ? parsed : dstring;
  } catch (err) {
    return undefined;
  }
};

export const truncate = (str = '', maxLength: number) => {
  str = `${str || ''}`;
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength - 3)}...`;
  }
  return str;
};

export const generatePublish = (sState?: AppState) => {
  return {
    data: {},
    coreid: '',
    event: '',
    fw_version: 1,
    public: false,
    fromApp: true,
    published_at: new Date().toISOString(),
    userid: get(sState, 'auth.user.userId', '__app__'),
  };
};

export const generateDefaultPublish = (
  type = 'dz_config',
  deviceId: string,
  sState?: AppState
) => {
  let config = generatePublish(sState);
  config.event = type;
  config.coreid = deviceId;

  if (type === 'dz_config') {
    config.data = {
      debugmode_on: [false],
      device_on: [false, 0],
      heads_enabled: [false, false, false],
      highpressuremode_on: [false],
      maintmode_on: [false, 0],
      model: 0,
      schedule_enabled: [true],
      seq: 1,
      time_offset: 0,
    };
  } else if (type === 'dz_schedule') {
    config.data = {
      thu: [[], []],
      tue: [[], []],
      wed: [[], []],
      sat: [[], []],
      fri: [[], []],
      mon: [[], []],
      sun: [[], []],
      seq: Date.now(),
    };
  }

  return config;
};

const ERRORS = {
  '0': {
    definition: 'Cleared (back to normal)',
    message: 'Cleared (back to normal)',
    color: colors.success,
    isMinor: false,
    isMajor: false,
    sort: 0,
    extDef: 'Clear'
  },
  '1': {
    definition: 'Major Alert Upper Bound',
    message: 'The equipment has been put in Standby. Please check equipment.',
    color: colors.error,
    isMinor: false,
    isMajor: true,
    sort: 10,
    extDef: 'High, major',
    clearCmd: 'major,upper'
  },
  '2': {
    definition: 'Minor Alert Upper Bound',
    message: 'Please monitor equipment.',
    color: colors.warning,
    isMinor: true,
    isMajor: false,
    sort: 5,
    extDef: 'High, minor',
    clearCmd: 'minor,upper'
  },
  '3': {
    definition: 'Major Alert Lower Bound',
    message: 'The equipment has been put in Standby. Please check equipment.',
    color: colors.error,
    isMinor: false,
    isMajor: true,
    sort: 9,
    extDef: 'Low, major',
    clearCmd: 'major,lower'
  },
  '4': {
    definition: 'Minor Alert Lower Bound',
    message: 'Please monitor equipment.',
    color: colors.warning,
    isMinor: true,
    isMajor: false,
    sort: 4,
    extDef: 'Low, minor',
    clearCmd: 'minor,lower'
  },
  '5': {
    definition: 'Invalid Sensor Readings',
    message: '',
    color: colors.warning,
    isMinor: false,
    isMajor: false,
    sort: 3,
    extDef: 'Sensor disconnected'
  },
  '6': {
    definition: 'Secondary MCU Issue',
    message: '',
    color: colors.warning,
    isMinor: false,
    isMajor: false,
    sort: 2,
    extDef: 'Secondary MCU Issue',
  },
  '7': {
    definition: 'Regen Time Warning',
    message: '',
    color: colors.warning,
    isMinor: false,
    isMajor: false,
    sort: 1,
    extDef: 'Regen Time Warning',
  },
};

export const errorDef = (error: number) => {
  return get(ERRORS, error);
};

export const getExtErrorDefinitions = () => {
  const obj = {};
  Object.keys(ERRORS).map(k => {
    const { extDef } = get(ERRORS, k);
    if (extDef) {
      set(obj, k, extDef)
    }
  })
  return obj;
}

export const findWorstError = (alerts: EQStatus["alerts"] = [], eq?: Equipment) => {
  let worst: any;
  const rank = [1, 3, 2, 4, 6, 7, 5];

  const ignore = (eq
    ? get(getEquipmentModel(eq), 'ignoreAlerts', [])
    : []) as unknown as string[];

  const installDate = moment(get(eq, 'installDate'));

  (alerts || []).map(a => {
    const e = get(a, 'error', 0);
    const isAfter = !a.time ? false : moment(a.time * 1000).isAfter(installDate);
    const currentWorst = get(worst, 'error', 5);

    const eRank = rank.indexOf(e);
    const cRank = rank.indexOf(currentWorst);

    const isIgnored = ignore.indexOf(get(a, 'data.source')) > -1;

    if (eRank !== -1 && isAfter && eRank < cRank && !isIgnored) {
      worst = a;
    }
  })

  return worst;
};

export const generateAlertMap = (alerts: Publish[]) => {
  alerts.sort(sortPublishByTimeOrDate);

  const alertMap = {} as { [key: string]: Publish[] };

  alerts.map(a => {
    const source = get(a, 'data.source');
    let m = alertMap[source];
    if (!m) {
      m = alertMap[source] = [];
    }
    m.push(a);
  });

  const aMap = {} as { [key: string]: Publish };

  for (let k in alertMap) {
    let opts = alertMap[k];
    aMap[k] = opts[0];
  }

  return aMap;
};

export const getRelevantAlertMap = (alertMap: { [key: string]: Publish }) => {
  const aMap = {} as { [key: string]: Publish };

  for (let k in alertMap) {
    let opts = alertMap[k];
    if (opts && get(opts, 'data.error', 0) > 0) {
      set(aMap, k, opts);
    }
  }

  return aMap;
};

export const findLatestPublish = (publishes: Publish[] = []) => {
  let pub = {} as Publish;
  publishes.map(p => {
    if (!p) return;
    const pd = pub.published_at ? new Date(pub.published_at) : null;
    const date = new Date(p.published_at);
    if (pd === null || (pd && date > pd)) {
      pub = p;
    }
  });

  if (typeof pub.data === 'string') {
    pub.data = parseJson(pub.data);
  }

  return pub;
};

// tslint:disable-next-line:no-any
export const getContent = (content: any) => {
  return content();
};
// tslint:disable-next-line
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isLoggedIn = (sState: AppState) => {
  return get(sState, 'auth.user.sk');
};

export const inRange = (x: number, min: number, max: number) => {
  return (x - min) * (x - max) < 0;
};

export const convertHex = (hex: string, opacity: number) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
};

export const createHours = () => {
  const quarterHours = ['00', '15', '30', '45'];

  const hours = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      hours.push(('0' + i).slice(-2) + ':' + quarterHours[j]);
    }
  }

  return hours;
};

export const militaryToHours = (_time = '00:00') => {
  let time = _time.split(':');

  if (time.length < 2) {
    time = `${_time.slice(0, 2)}:${_time.slice(2)}`.split(':');
  }

  const hours = Number(time[0]);
  const minutes = Number(time[1]);
  const mins = minutes / 60;
  let timeValue = hours + mins;

  return timeValue;
};

export const militaryToNumber = (str: string) => {
  const split = str.split(':');
  const hours = parseInt(split[0]);
  const mins = parseInt(split[1]);
  return hours * 60 + parseInt((mins || 0).toString());
};

export const numberToMilitary = (num: number) => {
  const n = num / 60;
  const spl = `${n}`.split('.');
  const hours = parseInt(spl[0]);
  const decimal = spl[1] ? (parseInt(spl[1]) / 100) * 60 : 0;
  let dec = decimal ? decimal.toString() + '00' : '00';
  dec = dec.substr(0, 2);

  let val = `${hours}${dec}`;
  if (val.length === 1) {
    val = `00:0${val}`;
  } else if (val.length === 2) {
    val = `00:${val}`;
  } else if (val.length === 3) {
    val = `0${val.substring(0, 1)}:${val.substring(1, 3)}`;
  } else if (val.length === 4) {
    val = `${val.substring(0, 2)}:${val.substring(2, 4)}`;
  }
  return val;
};

export const buildScheduleCommands = (sched: Sched) => {
  const commands = [];
  for (let day in sched) {
    let e = sched[day];
    if (Array.isArray(e) && e.length > 0) {
      let flat = makeSureArrayIsOfLength(
        [].concat.apply([], e as never),
        4,
        -1
      );
      let hasVal = flat.filter(f => f);
      if (hasVal.length > 0) {
        let cmd = `sched,${day},${flat.join(',')}`;
        if (cmd.charAt(cmd.length - 1) === ',') {
          cmd = cmd.substr(0, cmd.length - 1);
        }
        commands.push(cmd);
      }
    }
  }
  // tslint:disable-next-line:no-console
  return commands;
};

export interface SchedEvent {
  title: string;
  schedule: Schedule;
  start: Date;
  end: Date;
  sched: {
    day: string;
    index: number;
  };
}

export const dayMap: { [key: string]: number | string } = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

export const findDayFromNumber = (num: number) => {
  let day = 'sun';
  for (let k in dayMap) {
    let d = dayMap[k];
    if (d === num) {
      day = k;
      break;
    }
  }
  return day;
};

export const makeSureArrayIsOfLength = (
  // tslint:disable-next-line:no-any
  array: any[],
  amt = 2,
  fillWith: null | number | number[] = null
) => {
  if (array.length === amt) {
    return array;
  } else {
    const arr = [];
    for (let i = 0; i < amt; i++) {
      let a = array[i];
      arr.push(a || fillWith);
    }
    return arr;
  }
};

export const dezTimeToHours = (num?: number) => {
  return !num ? 0 : num / 60;
};

export const scheduleToEvents = (schedule: Schedule) => {
  const events: SchedEvent[] = [];
  if (schedule && schedule.data) {
    set(schedule, 'sched', schedule.data);
  }
  if (schedule && schedule.sched) {
    for (let k in schedule.sched) {
      const current = moment();
      const currentDay = current.weekday();
      const startDay = moment().startOf('day');

      let dd = schedule.sched[k];

      const schedDay = dayMap[k] as number;
      const currentGreater = currentDay > schedDay;
      const dayDif = currentGreater
        ? currentDay - (schedDay as number)
        : (schedDay as number) - currentDay;

      const sDay = startDay[currentGreater ? 'subtract' : 'add'](
        dayDif,
        'days'
      );

      dd.map &&
        dd.map((d, i) => {
          const _d = d as number[];
          if (_d && _d[0] && _d[1]) {
            const addStart = dezTimeToHours(_d[0]);
            const addEnd = dezTimeToHours(_d[1]);

            const start = moment(sDay)
              .add(addStart, 'hours')
              .toDate();

            const end = moment(sDay)
              .add(addEnd, 'hours')
              .toDate();

            const event = {
              title: 'On',
              schedule: { ...schedule },
              sched: {
                day: k,
                index: i,
              },
              start,
              end,
            };

            events.push(event);
          }
        });
    }
  }

  return events;
};

export const testPassword = (password: string) => {
  var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/;
  return re.test(password);
};

export const uppercaseFirst = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.substring(1, str.length)}`;
};

export const uppercaseWords = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const PWReqsMessage =
  // tslint:disable-next-line:max-line-length
  'Please fix the password you provided. It must be at least eight characters, it must include at least one number, one lowercase and one uppercase letter, and at least one special character.';

export const userHasRole = (
  id: number | number[],
  sState?: AppState
): boolean => {
  let has = null;
  if (!sState) {
    sState = getStore().getState() as AppState;
  }
  if (Array.isArray(id)) {
    for (let i = 0; i < id.length; i++) {
      let _id = id[i];
      if (sState.auth.user && sState.auth.user.role === _id) {
        has = true;
        break;
      }
    }
  } else {
    has = sState.auth.user && sState.auth.user.role === id;
  }
  return has ? true : false;
};

export const isServiceOrgUser = (sState: AppState) => {
  const hasRole = userHasRole([2, 3, 4], sState);
  const myOrg = getMyOrg(sState);
  if (hasRole && myOrg && myOrg.orgType === 0) {
    return true;
  }
  return false;
};

export const isDentalOrgUser = (sState: AppState) => {
  const hasRole = userHasRole([2, 3, 4, 6], sState);
  const myOrg = getMyOrg(sState);
  if (hasRole && myOrg && myOrg.orgType === 1) {
    return true;
  }
  return false;
};

export const isServiceOrgAdmin = (sState: AppState) => {
  const hasRole = userHasRole([2], sState);
  const myOrg = getMyOrg(sState);

  if (hasRole && myOrg && myOrg.orgType === 0) {
    return true;
  } else {
    return false;
  }
};

export const isDentalOrgAdmin = (sState: AppState) => {
  const hasRole = userHasRole([2], sState);
  const myOrg = getMyOrg(sState);

  if (hasRole && myOrg && myOrg.orgType === 1) {
    return true;
  } else {
    return false;
  }
};

export const serviceOrgThenDentalOrgs = (sState: AppState) => {
  const myOrg = getMyOrg(sState);
  if (!myOrg) {
    return [];
  } else {
    const orgs = (get(sState, 'dash.orgs', []) as Org[])
      .filter(o => o.id !== myOrg.id)
      .sort(sortByName);
    return [myOrg, ...orgs].map(o => ({
      label: o.name,
      title: o.name,
      value: o.id,
    }));
  }
};

export const getRoleName = (id: number, sState: AppState): string => {
  const {
    auth: { allRoles },
  } = sState;
  const role = allRoles.find(role => role.id === id);
  return role ? role.title : 'none';
};

export const filterBasedOnRoles = (
  routes: // tslint:disable-next-line:no-any
    any[] | undefined,
  sState: AppState
) => {
  return (routes || []).filter(route => {
    if (route && route.allowedRoles) {
      let has = false;
      if (userHasRole(route.allowedRoles, sState)) {
        has = true;
      }
      if (has && route.test) {
        has = route.test(sState);
      }
      return has;
    } else {
      return true;
    }
  });
};

export const filterHeaderRoutes = (
  routes: // tslint:disable-next-line:no-any
    any[] | undefined,
  sState: AppState
) => {
  return (routes || []).filter(route => {
    if (route && route.allowedRoles) {
      let has = false;
      if (userHasRole(route.allowedRoles, sState)) {
        has = true;
      }
      if (has && route.test) {
        has = route.test(sState);
      }
      return has;
    } else {
      return true;
    }
  });
};

// tslint:disable-next-line:no-any
export const removeEmpty = (obj: any) => {
  for (let key in obj) {
    let o = obj[key];
    if (o === null) {
      obj[key] = 'null';
    } else if (o === undefined || o.length === 0) {
      delete obj[key];
    } else if (Array.isArray(o)) {
      obj[key] = o.map(oo => {
        if (oo === null) {
          return 'null';
        } else {
          return oo;
        }
      });
    } else if (typeof o === 'object') {
      o = removeEmpty(o);
    }
  }
  return obj;
};

// tslint:disable-next-line:no-any
export const fixEmpty = (obj: any) => {
  if (!isObject(obj)) {
    return obj;
  }

  for (let key in obj) {
    let o = get(obj, key);
    if (o === 'null' || o === 'NULL') {
      set(obj, key, null);
    } else if (Array.isArray(o)) {
      set(
        obj,
        key,
        o.map(oo => {
          if (oo === 'null' || oo === 'NULL') {
            return null;
          } else {
            return oo;
          }
        })
      );
    } else if (isObject(o)) {
      o = fixEmpty(o);
    }
  }
  return obj;
};

export const getNameFromState = (sState: AppState) => {
  const {
    auth: { user },
  } = sState;
  if (user) {
    const uu = new _User(user);

    let displayName = uu.name;

    if (!displayName.trim()) {
      displayName = `${uu.firstName} ${uu.lastName}`;
    }

    if (!displayName.trim()) {
      displayName = user.email;
    }

    return displayName;
  } else {
    return '';
  }
};

export const getUsersName = (user: User) => {
  user = new _User(user);
  return user.name;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const guid = () => {
  return v4();
};

export const getUrlParams = (url: string) => {
  let match;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s: string) {
    return decodeURIComponent(s);
  };
  const searchFor = '?';
  const index = url.indexOf(searchFor) + searchFor.length;
  const str = url.substring(index, url.length);
  const query = str;

  let urlParams: {
    [key: string]: string;
  } = {};

  while ((match = search.exec(query)))
    urlParams[decode(match[1])] = decode(match[2]);

  return urlParams;
};

export const validPhoneNumber = (str: string) => {
  return phoneNumberReg().test(cleanPhoneNumber(str));
};

export const removeAllAlpha = (str: string) => {
  return str.replace(/\D/g, '');
};

export const phoneNumberReg = () => {
  return new RegExp(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  );
};

export const cleanCopy = (obj = {}) => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (err) {
    console.warn(err);
    return obj;
  }
};

export const parseJson = (str: string) => {
  let obj = null;
  try {
    let _str = str.split('nan').join('"nan"');
    obj = JSON.parse(_str);
  } catch (err) {
    if (isObject(str) || Array.isArray(str)) {
      obj = str;
    } else {
      obj = {
        _invalid_: str,
      };
    }
  }
  return obj;
};

export const isPB = (sState: AppState) => {
  return get(sState, 'dash.view.isPhoneBreak');
};

export const getNumbersInString = (str: string) => {
  return str.replace(/^\D+/g, '');
};

export const checkPhoneBreak = () => {
  const widthBreak = 900;
  const width = window.innerWidth;

  if (width <= widthBreak) {
    return true;
  } else {
    return false;
  }
};

export const getTriggeredMaint = (maint: Publish) => {
  const triggeredItems: Array<{
    text: string;
    timeRemaining: number;
    serviceType: IServiceType;
    timeOfReset: number;
  }> = [];

  const data = get(maint, 'data', {});
  for (let key in data) {
    const d = data[key];
    if (Array.isArray(d)) {
      const oldFilters = [
        'comp_airfilter',
        'comp_coafilter',
        'comp_partfilter',
      ];
      if (oldFilters.includes(key)) key = 'comp_filterpack';
      const isOldComp = key.indexOf('comp_') > -1;
      const triggered = get(d, '0', false);
      if (triggered) {
        const timeRemaining = isOldComp
          ? get(d, '1')
          : get(d, '2', 0) - get(d, '1', 0);
        const timeOfReset = isOldComp ? get(d, '2', 0) : get(d, '3', 0);

        const stype = get(
          serviceType,
          isOldComp ? key : `comp_${key}`,
          get(serviceType, isOldComp ? key : `vac_${key}`)
        );
        triggeredItems.push({
          timeRemaining,
          text: get(stype, 'name', ''),
          serviceType: stype,
          timeOfReset,
        });
      }
    }
  }

  return triggeredItems;
};

export const findAssocDentalOrgs = (servOrgId: string, sState: AppState) => {
  const allOrgs: Org[] = get(sState, 'dash.allOrgs');
  if (!allOrgs) return [];
  return allOrgs.filter((org: Org) => org.serviceOrgId === servOrgId);
};

export const encryptString = (str: string) => {
  return AES.encrypt(str.toString(), CRYPT_KEY).toString();
};

export const decryptString = (str: string) => {
  return AES.decrypt(str.toString(), CRYPT_KEY).toString(Crypto.enc.Utf8);
};

export const wait = (time = 1000) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('');
    }, time);
  });
};

export const getAllDeviceIds = (e: Equipment | undefined) => {
  if (!e) {
    return [];
  }
  const hasPrevious = (e.previousSerials || []).map((ps: PSerials) => ({
    id: ps.deviceId,
    replaceDate: get(ps, 'replaceDate'),
  }));
  return uniqBy(
    [{ id: e.deviceId, replaceDate: undefined }, ...hasPrevious],
    'id'
  ) as Array<{
    id: string;
    replaceDate?: string;
  }>;
};

export const parseServiceByDate = (services: Array<Service>, date?: string) => {
  let servs = services;
  if (date) {
    const checkDate = new Date(date);
    servs = services.filter(s => {
      const d = new Date(get(s, 'published_at') as string);
      return d <= checkDate;
    });
  }
  return servs;
};

export const getHandpieceModels = (store = getStore()) => {
  const sState = store.getState();
  const models = get(sState, 'dash.models', []);
  return (models || []).filter((m: Model) => m.type === 'handpiece');
};

export const getEquipmentType = (
  eSN: string = '',
  mId: string = ''
): EQ_TYPE => {
  const hpmodels = getHandpieceModels().map((m: Model) => m.id);
  if (mId && mId == '6400') {
    return EQ_TYPE.Chair;
  } else if (hpmodels.indexOf(mId) > -1) {
    return EQ_TYPE.Handpiece;
  } else if ((eSN && eSN.includes('ACR')) || (mId && mId.includes('C'))) {
    return EQ_TYPE.Compressor;
  } else if (
    (eSN && eSN.includes('AQT')) ||
    (eSN && eSN.includes('ABN')) ||
    (mId && mId.includes('V'))
  ) {
    return EQ_TYPE.Vacuum;
  }
  return EQ_TYPE.Unknown;
};

export const getEqType = (e?: Equipment) => {
  const unknown = 'unknown';
  if (!e) {
    return unknown;
  }
  const { dash } = getStore().getState();
  const models = get(dash, 'models', []);
  const model = models.find((m: Model) => m.id == e.modelId);
  return get(model, 'type', unknown);
};

export const isAerasOne = (eqType: string) => {
  return eqType.includes('aerasone_');
}

export const getFullEqType = (e?: Equipment) => {
  const eqType = getEqType(e);

  return {
    eqType,
    isAerasOne: eqType.includes('aerasone'),
  }
};

export const _checkEquipmentSN = (
  eSN: string,
  mId?: string,
  eqType?: string
): Error[] => {
  const errors: Error[] = [];

  const ESN = eSN.toUpperCase();

  //new format for future checks
  //@ts-ignore
  let tests = [];
  if (eqType === 'handpiece') {
    tests = [
      {
        test: () => ESN.length == 10,
        errorText: () =>
          `Handpiece Serial Number must be exactly 10 alphanumeric characters`,
      },
      {
        test: () => /^[a-zA-Z()]+$/.test(ESN.slice(0, 2)),
        errorText: () =>
          `Validation error: serial number entered does not match any expected format`,
      },
      {
        test: () => !isNaN(parseInt(ESN.slice(2, 7))),
        errorText: () =>
          `Validation error: serial number entered does not match any expected format`,
      },
      {
        test: () => ['AH8'].includes(ESN.slice(ESN.length - 3, ESN.length)),
        errorText: () =>
          `Validation error: serial number entered does not match any expected format`,
      },
    ];
  } else if (eqType === 'chair') {
    tests = [
      {
        test: () => ESN.length == 10,
        errorText: () =>
          `Validation error: Chair Serial Number must be exactly 10 alphanumeric characters`,
      },
      {
        test: () => ESN.startsWith('ABU'),
        errorText: () =>
          `Validation error: Chair Serial Number should always start with “ABU”`,
      },
      {
        test: () => !isNaN(parseInt(ESN.slice(3, 10))),
        errorText: () =>
          `Validation error: Chair Serial Number entered does not match any expected format`,
      },
    ];
  }

  //@ts-ignore
  if (!!size(tests)) {
    //@ts-ignore
    tests.map(t => {
      if (!t.test()) {
        errors.push(new Error(t.errorText()));
      }
    });

    return errors;
  }

  //traditional checks
  let passFormat = true;
  let passYearPosition = true;
  let passMonthPosition = true;

  //first 3 chars (model id)
  const eSNPrefix = eSN.slice(0, 3).toUpperCase();
  const mIdPrefix = mId && mId.split('-')[0];
  const prefixMatches: IPrefixMatches = {
    ACR: ['C3', 'C4', 'C6', 'C7', 'C11'],
    AQT: ['RV4'],
    ABN: ['RV5', 'RV7', 'RV10'],
  };
  const match = prefixMatches[eSNPrefix];
  if (match) {
    passFormat = !mIdPrefix || match.includes(mIdPrefix);
  }
  //next 2 (build year - must be current or last year)
  const strarr = [...eSN.slice(3, eSN.length)];
  const allSame = strarr.length === 7 && strarr.every((x, _, a) => x === a[0]);
  const snYearString = eSN.slice(3, 5);
  passYearPosition = allSame
    ? true
    : snYearString === `${moment().year()}`.slice(2) ||
    snYearString ===
    `${moment()
      .subtract(1, 'year')
      .year()}`.slice(2);

  const snMonthString = eSN.slice(5, 7);
  const snMonthNumber = parseInt(snMonthString);

  passMonthPosition = allSame
    ? true
    : !!snMonthNumber && snMonthNumber > 0 && snMonthNumber <= 12;

  if (!passFormat) {
    errors.push(
      new Error(
        'Validation error:  serial number entered does not match any expected format'
      )
    );
  }

  if (!passYearPosition) {
    errors.push(
      new Error(
        `Validation error: serial number entered does not match expecations for 2-digit 'year' value in 4th & 5th positions`
      )
    );
  }

  if (!passMonthPosition) {
    errors.push(
      new Error(
        `Validation error: serial number entered does not match expectations for 2-digit 'month' value in 6th and 7th positions`
      )
    );
  }

  return errors;
};

export const motorControlBoardTests = (val = '') => {
  const errors: Error[] = [];

  val = (val || '').toUpperCase();

  const tests = [
    {
      test: () => val.length == 10,
      errorText: () =>
        `MCB Serial Number must be exactly 10 alphanumeric characters`,
    },
    {
      test: () => /^[a-zA-Z()]+$/.test(val.slice(0, 2)),
      errorText: () =>
        `Validation error: serial number entered does not match any expected format`,
    },
    {
      test: () => !isNaN(parseInt(val.slice(2, 7))),
      errorText: () =>
        `Validation error: serial number entered does not match any expected format`,
    },
    {
      test: () => val.slice(val.length - 3, val.length) === 'AJ5',
      errorText: () =>
        `Validation error: serial number entered does not match any expected format`,
    },
  ];

  if (!!size(tests)) {
    //@ts-ignore
    tests.map(t => {
      if (!t.test()) {
        errors.push(new Error(t.errorText()));
      }
    });

    return errors;
  }
};

export const checkEquipmentSN = (
  eSN: string,
  mId?: string
): [boolean, boolean, boolean] => {
  var allPasses: [boolean, boolean, boolean] = [false, false, false];

  //first 3 chars (model id)
  const eSNPrefix = eSN.slice(0, 3).toUpperCase();
  const mIdPrefix = mId && mId.split('-')[0];
  const prefixMatches: IPrefixMatches = {
    ACR: ['C3', 'C4', 'C6', 'C7', 'C11'],
    AQT: ['RV4'],
    ABN: ['RV5', 'RV7', 'RV10'],
  };
  const match = prefixMatches[eSNPrefix];
  if (match) {
    allPasses[0] = !mIdPrefix || match.includes(mIdPrefix);
  }
  //next 2 (build year - must be current or last year)
  const strarr = [...eSN.slice(3, eSN.length)];
  const allSame = strarr.length === 7 && strarr.every((x, _, a) => x === a[0]);
  const snYearString = eSN.slice(3, 5);
  allPasses[1] = allSame
    ? true
    : snYearString === `${moment().year()}`.slice(2) ||
    snYearString ===
    `${moment()
      .subtract(1, 'year')
      .year()}`.slice(2);

  const snMonthString = eSN.slice(5, 7);
  const snMonthNumber = parseInt(snMonthString);
  allPasses[2] = allSame
    ? true
    : !!snMonthNumber && snMonthNumber > 0 && snMonthNumber <= 12;

  return allPasses;
};

export const checkEqSNUniq = (
  allEquipment: Equipment[],
  value: string,
  equip: Equipment
) => {
  const passes: boolean =
    !allEquipment.find(equipment => equipment.equipmentSN === value) ||
    value === equip.equipmentSN;
  return passes;
};

const createPresignedUrl = async (url: string) => {
  const str = url;
  const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
  const matches = str.match(pathNameRegex);
  const key = get(matches, '[1]').substring(1);
  const response = await getPreSignedUrl(key);
  return response.data.url;
};

export const downloadFile = async (url: string) => {
  const preSignedUrl = await createPresignedUrl(url);
  const link = document.createElement('a');
  link.href = preSignedUrl;
  link.click();
  link && link.parentNode && link.parentNode.removeChild(link);
};

export const generateTGDeviceCMD = (groupNumber: number, role: string) => {
  const arg = `tandem_config,${role},${groupNumber}`;
  return arg;
};

export const TG_ROLES = {
  PEER: 'peer',
  CENTRAL: 'central',
  NONE: 'none',
};

export const buildMaintenanceCommands = (
  maint:
    | { [key: string]: number[] }
    | { [key: string]: { [key: string]: string } }
) => {
  const compRTCMDs: string[] = [];
  const compSNCMDs: string[] = [];
  maintCMDKeys.forEach((key: string) => {
    if (maint && maint[`${key}`]) {
      if (key === 'serial_numbers') {
        const serial_numbers = maint[`${key}`] as { [key: string]: string };
        const keys = Object.keys(serial_numbers);
        keys.forEach(key => {
          const data = serial_numbers[key];
          const cmd = `set_sn,${key},${data}`;
          compSNCMDs.push(cmd as never);
        });
      } else {
        const data = maint[`${key}`] as number[];
        const rtHours = data[1];
        const rtSeconds = rtHours * 3600;
        const cmd = `set_runtime,${key},${rtSeconds}${!!data[3] &&
          ',' + data[3]}`;
        compRTCMDs.push(cmd as never);
      }
    }
  });
  return {
    compRTCMDs: compRTCMDs,
    compSNCMDs: compSNCMDs,
  };
};

export const findActiveSubscription = (
  equip: Equipment
): Subscription | undefined => {
  const subscriptionHistory = get(equip, 'subscriptionHistory', []);
  const activeSubscription = subscriptionHistory.find((sub: Subscription) => {
    const now = moment().valueOf();
    const start =
      sub &&
      moment(get(sub, 'subscriptionStart'))
        .startOf('day')
        .valueOf();
    const end = get(sub, 'subscriptionEnd')
      ? moment(get(sub, 'subscriptionEnd'))
        .endOf('day')
        .valueOf()
      : undefined;
    return (end && now > start && now < end) || (now > start && !end);
  });
  return activeSubscription;
};
export const findActiveOrPendingSubscription = (
  equip: Equipment
): Subscription | undefined => {
  const subscriptionHistory = get(equip, 'subscriptionHistory', []);
  const activeSubscription = subscriptionHistory.find((sub: Subscription) => {
    const now = moment().valueOf();
    const start =
      sub &&
      moment(get(sub, 'subscriptionStart'))
        .startOf('day')
        .valueOf();
    const end = get(sub, 'subscriptionEnd')
      ? moment(get(sub, 'subscriptionEnd'))
        .endOf('day')
        .valueOf()
      : undefined;
    return (end && now > start && now < end) || (now > start && !end);
  });
  const pendingSubscription = sortBy(
    findPendingSubs(equip),
    'subscriptionStart'
  )[0];
  return !!activeSubscription
    ? activeSubscription
    : pendingSubscription
      ? pendingSubscription
      : undefined;
};

export const findTrial = (equip: Equipment): Subscription | undefined => {
  const subscriptionHistory = get(
    equip,
    'subscriptionHistory',
    []
  ) as Subscription[];
  const trial = subscriptionHistory.find((sub: Subscription) => {
    return get(sub, 'billingPreference') === SUBSCRIPTION_TYPE.trial;
  });
  return trial;
};

export const findMostRecentExpiredSub = (equip: Equipment) => {
  const subscriptionHistory = get(equip, 'subscriptionHistory', []);
  let recentExpiredSub: Subscription | undefined = undefined;
  subscriptionHistory.forEach(sub => {
    const subEnd = moment(get(sub, 'subscriptionEnd'))
      .endOf('day')
      .valueOf();
    const now = moment().valueOf();
    if (
      !recentExpiredSub ||
      (subEnd &&
        subEnd <= now &&
        moment(recentExpiredSub.subscriptionEnd)
          .endOf('day')
          .valueOf() <= subEnd)
    ) {
      recentExpiredSub = sub;
    }
  });
  return (recentExpiredSub as unknown) as Subscription;
};

export const findPendingSubs = (equip: Equipment) => {
  const subHistory = get(equip, 'subscriptionHistory', []);
  const pendingSubs = subHistory.filter((sub: Subscription) => {
    const now = moment().valueOf();
    const start = sub && moment(get(sub, 'subscriptionStart')).valueOf();
    const end = get(sub, 'subscriptionEnd')
      ? moment(get(sub, 'subscriptionEnd'))
        .endOf('day')
        .valueOf()
      : undefined;

    if (sub.billingPreference === SUBSCRIPTION_TYPE.trial) {
      return start > now;
    } else {
      return start > now && !end;
    }
  });

  return pendingSubs;
};

export const getSubscriptionHistory = (equip: Equipment) => {
  const subscriptionHistory = get(equip, 'subscriptionHistory');
  const activeSub = findActiveSubscription(equip);
  const expiredSub = findMostRecentExpiredSub(equip);
  const pendingSubs = findPendingSubs(equip);
  const alwaysSmart = get(equip, 'alwaysSmart', false);
  const showExpired =
    !alwaysSmart && !activeSub && !pendingSubs.length && expiredSub;

  let history: {
    alwaysSmart?: string;
    active?: string;
    pending?: string[];
    expired?: string;
    notActive?: string;
  } = {};
  if (!alwaysSmart && (!subscriptionHistory || !subscriptionHistory.length)) {
    Object.assign(history, { notActive: 'Not Active' });
  }
  if (alwaysSmart) {
    Object.assign(history, { alwaysSmart: 'Always Smart' });
  }
  if (
    !alwaysSmart &&
    activeSub &&
    activeSub.billingPreference &&
    activeSub.subscriptionEnd
  ) {
    Object.assign(history, {
      active: `${SUB_TYPE_DISPLAY[activeSub.billingPreference]
        } (expires: ${moment(activeSub.subscriptionEnd).format('L')})`,
    });
  }
  if (
    !alwaysSmart &&
    activeSub &&
    activeSub.billingPreference !== SUBSCRIPTION_TYPE.trial &&
    !activeSub.subscriptionEnd
  ) {
    Object.assign(history, {
      active: `${SUB_TYPE_DISPLAY[activeSub.billingPreference]
        } (start: ${moment(activeSub.subscriptionStart).format('L')})`,
    });
  }
  if (!alwaysSmart && pendingSubs.length) {
    history = Object.assign(history, { pending: [] });
    pendingSubs.forEach(sub => {
      history.pending &&
        history.pending.push(
          `${SUB_TYPE_DISPLAY[sub.billingPreference]} (start: ${moment(
            sub.subscriptionStart
          ).format('L')})`
        );
    });
  }
  if (showExpired && expiredSub.billingPreference === SUBSCRIPTION_TYPE.trial) {
    Object.assign(history, {
      expired: `Not Active: (trial end: ${moment(
        expiredSub.subscriptionEnd
      ).format('L')})`,
    });
  }

  if (showExpired && expiredSub.billingPreference !== SUBSCRIPTION_TYPE.trial) {
    Object.assign(history, {
      expired: `Not Active (canceled: ${moment(
        expiredSub.subscriptionEnd
      ).format('L')})`,
    });
  }
  return history;
};

export const getSubscriptionString = (subHistory: {
  [key: string]: string | string[] | undefined;
}) => {
  return subHistory.alwaysSmart
    ? subHistory.alwaysSmart
    : subHistory.active && subHistory.pending && subHistory.pending.length
      ? subHistory.active + ' ' + subHistory.pending[0]
      : subHistory.active
        ? subHistory.active
        : get(subHistory, 'pending', []).length === 1
          ? get(subHistory, 'pending', [])[0]
          : subHistory.pending && subHistory.pending.length === 2
            ? `${subHistory.pending[0]}  ${subHistory.pending[1]}`
            : subHistory.expired
              ? subHistory.expired
              : null;
};

export const areAllSubsExired = (equip: Equipment) => {
  const subHistory: Subscription[] = get(equip, 'subscriptionHistory', []);
  if (!hasSubHistory(equip)) return false;
  return subHistory.every(
    sub =>
      !!get(sub, 'subscriptionEnd') &&
      moment(get(sub, 'subscriptionEnd'))
        .endOf('day')
        .valueOf() < moment().valueOf()
  );
};

export const hasSubHistory = (equip: Equipment) => {
  return equip.subscriptionHistory && equip.subscriptionHistory.length;
};

export const isEligibleForSub = (equip: Equipment) => {
  const allSubsExpired = areAllSubsExired(equip);
  const subHistory: Subscription[] = get(equip, 'subscriptionHistory', []);
  const onlySubIsTrial =
    subHistory.length === 1 &&
    subHistory.every(
      sub => get(sub, 'billingPreference') === SUBSCRIPTION_TYPE.trial
    );
  return allSubsExpired || onlySubIsTrial;
};

export const setTrialSub = (
  e: Equipment,
  trialStart: Moment,
  trialDuration: number
) => {
  set(e, 'subscriptionHistory', [
    ...get(e, 'subscriptionHistory', []),
    {
      subscriptionStart: moment(trialStart)
        .startOf('day')
        .toISOString(),
      subscriptionEnd: moment(trialStart)
        .endOf('day')
        .add(trialDuration, 'months')
        .toISOString(),
      billingPreference: SUBSCRIPTION_TYPE.trial,
    },
  ]);
  return e;
};

export const extendTrial = (
  e: Equipment,
  newEnd: Moment
): [boolean, Equipment] => {
  //we are going to mutate this array in place,
  //re-assign `e`s sub history array, and return
  //(also go ahead and sort chrono to make life easier)
  const subscriptionHistory = sortBy(
    e.subscriptionHistory,
    'subscriptionStart'
  );
  let successful = false;

  if (subscriptionHistory) {
    //find the index of the trial subscription in the array
    //and use that to write the new end date
    const trialSubIndex = subscriptionHistory.findIndex(
      s => s.billingPreference === SUBSCRIPTION_TYPE.trial
    );

    if (trialSubIndex > -1 && subscriptionHistory.length > trialSubIndex) {
      subscriptionHistory[trialSubIndex].subscriptionEnd = newEnd.toISOString();

      //at this point we mark a success --
      //there might not be a subsequent trial we have to bump
      successful = true;

      //since we sorted this array earlier, the next
      //subscription is just the trial + 1
      const subsequentSubscriptionIndex = trialSubIndex + 1;

      //find the index of the subscription after the trial
      //and use that to update the start date of that sub
      //(the trial has "pushed" the sub after it to a later date)
      if (subscriptionHistory.length > subsequentSubscriptionIndex) {
        subscriptionHistory[
          subsequentSubscriptionIndex
        ].subscriptionStart = newEnd.add(1, 'days').toISOString();
      }
    }
  }

  e.subscriptionHistory = subscriptionHistory;
  return [successful, e];
};

export const setPayedSub = (
  e: Equipment,
  subscriptionStart: Moment,
  subscriptionType: string,
  doPurchaseOrder?: string
) => {
  const subscriptionHistory = get(e, 'subscriptionHistory', []);
  set(e, 'subscriptionHistory', [
    ...subscriptionHistory,
    {
      subscriptionStart: moment(subscriptionStart)
        .startOf('day')
        .toISOString(),
      billingPreference: subscriptionType,
      doPurchaseOrder,
    },
  ]);
  return e;
};
export const getIneligibleEquipment = (equipments: Equipment[]) => {
  let ineligibleEquip: Equipment[] = [];
  equipments.forEach((equip: Equipment) => {
    const equipHasSubHistory = hasSubHistory(equip);
    if (equipHasSubHistory || equip.alwaysSmart) {
      ineligibleEquip.push(equip);
    }
  });
  return ineligibleEquip;
};

export const getCancelableSub = (
  equip: Equipment
): Subscription | undefined => {
  const subHistory: Subscription[] = get(equip, 'subscriptionHistory', []);
  const cancelableSub = subHistory.find(sub => {
    return (
      get(sub, 'billingPreference') !== SUBSCRIPTION_TYPE.trial &&
      !get(sub, 'subscriptionEnd')
    );
  });

  return cancelableSub;
};

export const getSubsWhereBillingChangeable = (equip: Equipment) => {
  const subHistory: Subscription[] = get(equip, 'subscriptionHistory', []);
  const eligibleSubs = subHistory.filter(sub => {
    return (
      get(sub, 'billingPreference') !== SUBSCRIPTION_TYPE.trial &&
      !get(sub, 'subscriptionEnd') &&
      get(sub, 'billingPreference') !== SUBSCRIPTION_TYPE.multiyear
    );
  });
  return eligibleSubs;
};

export const equipmentHasAnyNonTrialSubscriptions = (
  equipment: Equipment
): boolean => {
  const subHistory: Subscription[] = get(equipment, 'subscriptionHistory', []);
  return subHistory.some(
    sub => get(sub, 'billingPreference') !== SUBSCRIPTION_TYPE.trial
  );
};

export const cancelSub = (equip: Equipment, subscriptionEnd: Moment) => {
  const subHistory: Subscription[] = get(equip, 'subscriptionHistory', []);
  const updatedSubHistory = subHistory.map(sub => {
    if (!get(sub, 'subscriptionEnd')) {
      set(
        sub,
        'subscriptionEnd',
        moment(subscriptionEnd)
          .endOf('day')
          .toISOString()
      );
    }
    return sub;
  });
  set(equip, 'subscriptionHistory', updatedSubHistory);
  return equip;
};

export const BigThreeSOs = [
  //patterson dental SO
  '9659b85c-05b7-4a54-895a-c514f7a008c7',
  //patterson dental canada,
  '1efdd550-fa6a-4420-adee-281ede3e7897',
  //Bencon Dental
  '69e40ee2-53e8-4759-b7ad-e37bcc9264fe',
  //Henry Schein
  '03749259-be7b-4802-a15a-dea92a70f7c3',
  //Henry Schein Canada
  '7b53dd8f-958c-42d0-9192-31c2ca3adb17',
];

const maintCMDKeys = [
  'airfilter',
  'amsensor',
  'belt',
  'coafilter',
  'des',
  'desdryers',
  'dewsensors',
  'diprate',
  'enabled',
  'filterpack',
  'general',
  'inuse',
  'mafilter',
  'motor1',
  'motor2',
  'motor3',
  'oilchange',
  'oilfilter',
  'partfilter',
  'pump',
  'serial_numbers',
  'tpsensor',
  'vcfilter',
];

export const getDentalOrgTitle = (value: string) => {
  return DentalOrgTypes[value];
};

const DentalOrgTypes: { [key: string]: string } = {
  dentalPractice: 'Dental Practice',
  dentalSchool: 'Dental School',
  government: 'Government',
  groupDentalPractice: 'Group Dental Practice',
  dso: 'DSO',
};

export const doHasHandpiece = (dentalOrgId: string, sState: AppState) => {
  const { equipment } = get(sState, 'dash');
  const equipForOrg = equipment.filter(eq => eq.dentalOrgId === dentalOrgId);
  const models = equipForOrg.map(eq =>
    getEquipmentModel(eq, sState)
  ) as Model[];
  const hasHandPiece = !!models.find(model => model.id === 'handpiece');
  return hasHandPiece;
};

// tslint:disable-next-line:no-any
export const makeObjFromArray = (arr: any[] | undefined) => {
  const obj = {};
  (arr || []).map(a => {
    Object.keys(a).map(k => {
      set(obj, k, a[k]);
    });
  });
  return !arr ? undefined : obj;
};

export const deDupeServices = (services: Service[] = []) => {
  if (services.length === 1) return services;
  //we get a publish back from the device even though we already created a service. This deduplicates that
  const servs: Service[] = [];
  services.map((s, i) => {
    const nextServ = services[i + 1];
    const prevServ = services[i - 1];

    const dateKey = 'createdAt';
    const date = moment(get(s, dateKey));
    const prevDate = prevServ ? moment(get(prevServ, dateKey)) : null;
    const nextDate = nextServ ? moment(get(nextServ, dateKey)) : null;

    const diffPrev = !prevDate ? 100 : date.diff(prevDate, 'minutes');
    const diffForw = !nextDate ? 100 : date.diff(nextDate, 'minutes');
    const conflict_prev = Math.abs(diffPrev) <= 1.5;
    const conflict_next = Math.abs(diffForw) <= 1.5;
    const timeconflict = conflict_next || conflict_prev;

    if (timeconflict) { //they are likely matching
      const servCompare = conflict_next ? nextServ : prevServ;
      const eqs = makeObjFromArray(get(s, 'services'));
      const eqnext = makeObjFromArray(get(servCompare, 'services'));
      let equal = isEqual(eqs, eqnext);
      const devpub = 'device publish';
      equal = equal
        ? get(s, 'createdBy') === devpub && get(servCompare, 'createdBy') !== devpub
        : equal;

      if (!equal) {
        servs.push(size(servCompare.services) > size(s.services) ? servCompare : s);
      }
    } else {
      servs.push(s);
    }
  });
  return uniqBy(servs, 'createdAt');
};

export const metricConvert = (
  val: string,
  isMetric: boolean,
  returnObj = false
) => {
  if (!isMetric) {
    return returnObj ? { value: val, unit: null } : val;
  }

  const matched = val.match(/[a-z]/i);
  if (!matched) {
    return returnObj ? { value: val, unit: null } : val;
  }

  const splitAt = (index: number | undefined) => (x: string) => [
    x.slice(0, index),
    x.slice(index),
  ];

  const [v, unit] = splitAt(matched.index)(val);
  const vl = parseFloat(v as string);

  if (!v || !unit) {
    return returnObj ? { value: val, unit: null } : val;
  }

  let value: string | number = vl;
  let newunit = unit;

  if (unit === 'F') {
    // value = ((value - 32) * 5) / 9
    value = (((value - 32) * 5) / 9).toFixed(1);
    newunit = 'C';
  } else if (unit === 'psi' || unit === 'PSI') {
    // kPa	value = value * 6.89476; // psi to kpa
    value = (value * 6.89476).toFixed(1);
    newunit = 'kPa';
  }

  if (returnObj) {
    return {
      value,
      unit: newunit,
    };
  }

  return `${value} ${newunit}`;
};

export const pullOutEquipment = (
  eq?: EquipmentRecord[],
  filterModel = true
) => {
  return (eq || []).filter(e => (filterModel ? !!e.modelId : !!e));
};

export const parseOperator = (
  opString: string,
  // tslint:disable-next-line:no-any
  { val, compare }: { val: any; compare: any }
) => {
  if (opString === '>') {
    return val > compare;
  } else if (opString === '>=') {
    return val >= compare;
  } else if (opString === '<') {
    return val < compare;
  } else if (opString === '<=') {
    return val <= compare;
  }
  return false;
};

export const passesWhere = (where: {}, item: {}) => {
  let passes = true;
  for (let key in where) {
    let obj = get(where, key);
    let val = get(item, key);
    if (passes && val) {
      if (obj.eq) {
        if (val === obj.eq) {
          passes = true;
        } else {
          passes = false;
        }
      }

      if (passes && obj.contains) {
        if ((val || '').indexOf(obj.contains) > -1) {
          passes = true;
        } else {
          passes = false;
        }
      }

      const operator = Object.keys(obj)[0];
      if (passes && ['>', '>=', '<', '<='].indexOf(operator) > -1) {
        passes = parseOperator(operator, { val, compare: obj[operator] });
      }
    } else {
      passes = false;
    }
  }
  return passes;
};

// tslint:disable-next-line:no-any
export const parseResult = (where: {}, result: any[] = []) => {
  // tslint:disable-next-line:no-any
  let toRet: any[] = [];

  try {
    //pluck _ items from where. These are optional settings
    // tslint:disable-next-line:no-any
    const options: {
      limit?: number;
      sortBy?: string;
      sortType?: string;
    } = {};
    const _where = {};

    for (let key in where) {
      if (key.startsWith('_')) {
        const k = key.substring(1, key.length);
        set(options, [k], get(where, [key]));
      } else {
        set(_where, [key], get(where, [key]));
      }
    }

    result.map(item => {
      if (passesWhere(_where, item)) {
        toRet.push(item);
      }
    });

    if (options.sortBy) {
      // tslint:disable-next-line:no-any
      toRet.sort((a: any, b: any) => {
        const sortBy = get(options, 'sortBy', '');
        if (options.sortType && options.sortType === 'date') {
          return new Date(b[sortBy]).getTime() - new Date(a[sortBy]).getTime();
        } else {
          if (a[sortBy] < b[sortBy]) {
            return -1;
          }
          if (a[sortBy] > b[sortBy]) {
            return 1;
          }
          return 0;
        }
      });
    }

    if (options.limit) {
      toRet = take(toRet, options.limit);
    }
  } catch (err) {
    toRet = result;
    console.error(err);
  }

  return toRet;
};

// tslint:disable-next-line:no-any
export const filterResult = (parse: {}, result: any[] = []) => {
  // tslint:disable-next-line:no-any
  let toRet: any[] = result;

  try {
    const options: {
      limit?: number;
      sortBy?: string;
      sortType?: string;
      limitBy?: { key: string; cat: string; limit: number };
    } = parse || {};
    // tslint:disable-next-line:no-any
    const sortOptions = (arr: any[] = []) => {
      // tslint:disable-next-line:no-any
      return arr.sort((a: any, b: any) => {
        const sortBy = get(options, 'sortBy', '');
        if (options.sortType && options.sortType === 'date') {
          return new Date(b[sortBy]).getTime() - new Date(a[sortBy]).getTime();
        } else {
          if (a[sortBy] < b[sortBy]) {
            return -1;
          }
          if (a[sortBy] > b[sortBy]) {
            return 1;
          }
          return 0;
        }
      });
    };

    if (get(options, 'limitBy')) {
      toRet = sortOptions(toRet);
      const lBy = options.limitBy;
      const map: {
        [key: string]: {
          [key: string]: [];
        };
      } = {};
      toRet.map(item => {
        const key = item[get(lBy, 'key', '')];
        const cat = item[get(lBy, 'cat', '')];

        if (!map[key]) {
          map[key] = {};
        }

        if (!map[key][cat]) {
          map[key][cat] = [];
        }

        if (map[key][cat].length < get(lBy, 'limit', 0)) {
          map[key][cat].push(item as never);
        }
      });
      toRet = [];
      for (let k in map) {
        for (let kk in map[k]) {
          toRet = [...toRet, ...map[k][kk]];
        }
      }
    }

    if (options.sortBy) {
      toRet = sortOptions(toRet);
    }

    if (options.limit) {
      toRet = take(toRet, options.limit);
    }
  } catch (err) {
    toRet = result;
    console.error(err);
  }

  return toRet;
};

export const renderHWVersion = (str = '') => {
  return str
    .split('.')
    .join('')
    .split('0')
    .join('')
    .toLowerCase();
};

export const handpieceIsDueForService = (
  sState: AppState,
  e: Equipment,
  serviceHistory: Service[]
): [boolean, number, Moment | undefined] => {
  if (isHandpiece(e)) {
    const sensors = get(
      sState,
      `dash.equipMap.${e.deviceId}.sensorStatistics`,
      []
    );

    const latest = findLatestPublish(
      sensors.filter(
        (s: { event: string }) =>
          s.event === 'dz_sensor_statistics' || s.event === 'dz_sensor_instant'
      )
    );

    if (latest && latest.data && latest.data.MTR != undefined) {
      const isLatestPublishInstant =
        get(latest, 'event', '').indexOf('instant') > -1;
      const rawAccessor = isLatestPublishInstant ? '' : '.0';
      const runtime = get(latest, `data.MTR${rawAccessor}`, 0);

      const hours = runtime / 60; // reported as minutes, multiply

      var lastServicedDTime: Moment | undefined = undefined;
      if (serviceHistory.length) {
        const ordered = orderBy(
          serviceHistory.filter(i => i.serviceDate),
          'serviceDate',
          'desc'
        );

        if (ordered && ordered.length > 0) {
          lastServicedDTime = moment(ordered[0].serviceDate);
        }
      }
      return [hours >= 450, hours, lastServicedDTime];
    }
  }
  return [false, -1, undefined];
};

export const jformat = (str = {}) => {
  return prettifyJson(str as string, true);
};
export function prettifyJson(json = '', prettify = true) {
  if (typeof json !== 'string') {
    if (prettify) {
      json = JSON.stringify(json, undefined, 4);
    } else {
      json = JSON.stringify(json);
    }
  }
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      let cls = '<span>';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "<span class='text-danger'>";
        } else {
          cls = '<span>';
        }
      } else if (/true|false/.test(match)) {
        cls = "<span class='text-primary'>";
      } else if (/null/.test(match)) {
        cls = "<span class='text-info'>";
      }
      return cls + match + '</span>';
    }
  );
}

//@ts-ignore
// tslint:disable-next-line
export const exportToCsv = (filename: string, rows: any[]) => {
  // tslint:disable-next-line
  var processRow = function (row: any) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      } else if (row[j] instanceof Object) {
        innerValue = get(row[j], 'name', '');
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  //@ts-ignore
  // tslint:disable-next-line
  if (navigator.msSaveBlob) {
    // IE 10+
    //@ts-ignore
    // tslint:disable-next-line
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getSterilizerFlagText = (flag: number | string) => {
  const flags = {
    0: 'Manual Stop',
    1: 'Low Heat',
    2: 'Low Pressure',
    3: 'High Pressure',
    4: 'Low Temp',
    5: 'High Temp',
    6: 'Low Water',
    7: 'Power Down',
  };

  return get(flags, flag);
};

export const getSterilizerFlagTextFromArray = (
  arr: string[] | number[] = []
) => {
  //@ts-ignore
  const t = (arr || [])
    .map((a: number | string) => getSterilizerFlagText(a))
    //@ts-ignore
    // tslint:disable-next-line:no-any
    .filter(f => !!f)
    .join(', ');
  return t;
};

export const getChartValues = (arr: number[] | number) => {
  //mostly comes over as [1, 2, 3] being min, max, avg
  //but sometimes will come over as [1, 2, 3, 4] being min, max, total, avg

  const obj = {
    min: undefined,
    max: undefined,
    total: undefined,
    avg: undefined,
    has: function (key: string) {
      return get(obj, key) !== undefined;
    },
  };

  if (Array.isArray(arr)) {
    //avg is always last in the array of numbers
    const items = size(arr);

    set(obj, 'min', get(arr, '0'));
    set(obj, 'max', get(arr, '1'));
    set(obj, 'avg', last(arr));

    if (items >= 4) {
      set(obj, 'total', get(arr, '2'));
    }
  } else {
    set(obj, 'avg', arr);
  }

  return obj;
};

export const getServiceType = (s: {}) => {
  const key = Object.keys(s).find(k => k.includes('_'));
  return get(serviceType, `${key}`);
};

//@ts-ignore
// tslint:disable-next-line
export const sortAlphaNumeric = (array: any[] = [], key?: string) => {
  const collator = new Intl.Collator('en', {
    numeric: true,
    sensitivity: 'base',
  });

  return array.sort((_a, _b) => {
    const a = key ? get(_a, key) : _a;
    const b = key ? get(_b, key) : _b;
    return collator.compare(a, b);
  });
};

export const getAvg = (grades: number[]) => {
  const total = grades.reduce((acc, c) => acc + c, 0);
  return total / grades.length;
};

export const getSum = (arr: number[]) => {
  return arr.reduce((partialSum, a) => partialSum + a, 0);
}

//@ts-ignore
// tslint:disable-next-line
export const mergeDeep = (...objects) => {
  //@ts-ignore
  // tslint:disable-next-line
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = oVal;
      }
      else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export const isBetween = (val: number, arr: number[] = []) => {
  return val >= arr[0] && val <= arr[1];
}

export const firstKey = (obj = {}) => {
  const key = Object.keys(obj)[0];
  return get(obj, key);
}

/**
 * Compares the similarity between two strings using an n-gram comparison method. 
 * The grams default to length 2.
 * @param str1 The first string to compare.
 * @param str2 The second string to compare.
 * @param gramSize The size of the grams. Defaults to length 2.
 */
export const stringSimilarity = (str1: string, str2: string, gramSize: number = 2) => {
  function getNGrams(s: string, len: number) {
    s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
    let v = new Array(s.length - len + 1);
    for (let i = 0; i < v.length; i++) {
      v[i] = s.slice(i, i + len);
    }
    return v;
  }

  if (!str1.length || !str2.length) { return 0.0; }

  //Order the strings by length so the order they're passed in doesn't matter 
  //and so the smaller string's ngrams are always the ones in the set
  let s1 = str1.length < str2.length ? str1 : str2;
  let s2 = str1.length < str2.length ? str2 : str1;

  let pairs1 = getNGrams(s1, gramSize);
  let pairs2 = getNGrams(s2, gramSize);
  let set = new Set<string>(pairs1);

  let total = pairs2.length;
  let hits = 0;
  for (let item of pairs2) {
    if (set.delete(item)) {
      hits++;
    }
  }
  return hits / total;
}

export const stopProp = (e: any) => {
  e && e.stopPropagation && e.stopPropagation();
  e && e.preventDefault && e.preventDefault();
}

export function isPositiveInteger(x: any) {
  return /^\d+$/.test(x);
}

export function compareVersionNumbers(v1: any, v2: any) {
  var v1parts = `${v1}`.split(".").map((v: any) => parseInt(v));
  var v2parts = `${v2}`.split(".").map((v: any) => parseInt(v));

  // First, validate both numbers are true version numbers
  function validateParts(parts: any) {
    for (var i = 0; i < parts.length; ++i) {
      if (!isPositiveInteger(parts[i])) {
        return false;
      }
    }
    return true;
  }

  if (!validateParts(v1parts) || !validateParts(v2parts)) {
    return NaN;
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    }
    if (v1parts[i] > v2parts[i]) {
      return 1;
    }
    return -1;
  }

  if (v1parts.length != v2parts.length) {
    return -1;
  }

  return 0;
}

export const filterActive = (e: Org | Location) => {
  return !!get(e, 'isActive');
}