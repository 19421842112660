/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { TableListHeader } from '../../_shared/table-list';
import { DatePicker, message } from 'antd';
import { Button } from '../../_shared/button';
import { Select } from '../../_shared/select';
import { useSelector, useStore } from 'react-redux';
import styles from './styles';
import { size, get, keyBy, toArray } from 'lodash';
import moment from 'moment';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { generateReport } from '../../_shared/services/analytics.service';
import { _Sensor, Sensor } from '../../_shared/interfaces/sensor';
import { Loading } from '../../_shared/icon';
import { Column } from '../../_shared/row';
import defaultStyles from '../../_shared/styles';

export const GenerateReports = (props = {}) => {
  const store = useStore();
  const [models] = useState(keyBy(get(store.getState(), 'dash.models'), 'sk'));
  const [eqTypes] = useState(keyBy(toArray(models), 'type'));
  const user = useSelector(s => get(s, 'auth.user'));

  const [state, setState] = useState({
    loading: false,
    data: [],
    input: '',
    date: {
      selected: '6_month',
      start: moment().subtract(6, 'month'),
      end: moment(),
    },
    selFilters: {
      sensor_statistics: ['1'],
    },
    filters: [
      {
        id: 'eqType',
        opts: Object.keys(eqTypes).map((k) => ({ label: k, value: k })),
        props: {
          placeholder: 'Equipment Type',
          mode: 'default',
        },
      },
      {
        id: 'model',
        //@ts-ignore
        opts: (opts = {}) => Object.keys(models).filter(k => get(models, [k, 'type']) == get(opts, 'state.selFilters.eqType')).map((k) => ({ label: models[k].id, value: models[k].sk })),
        //@ts-ignore
        props: (opts = {}) => ({
          placeholder: 'Model',
          disabled: !get(opts, 'state.selFilters.eqType', false),
        }),
      },
      {
        id: 'sensor',
        opts: (opts = {}) => {
          const sensors = store.getState().dash.sensors;

          const turnLabel = (s: Sensor) => {
            return { label: s.name, value: JSON.stringify(s) }
          }

          const etype = get(opts, 'state.selFilters.eqType');

          if (!etype) { return sensors.map(turnLabel) }
          
          return sensors.filter((s: Sensor) => {
            const ss = new _Sensor(s);
            return ss.isType(etype);
          }).map(turnLabel);
        },
        props: (opts = {}) => ({
          placeholder: 'Sensor',
          mode: 'default',
          disabled: !get(opts, 'state.selFilters.eqType', false),
        }),
      },
      {
        id: 'sensor_statistics',
        opts: [
          { label: 'Hourly', value: '0' },
          { label: 'Daily', value: '1' },
        ],
        props: {
          placeholder: 'Publish Type',
        },
      },
    ],
  });

  const retrieveData = async () => {
    try {
      setState(s => ({ ...s, loading: true, data: [] }));

      const data = (await generateReport({
        date: state.date,
        user,
        ...state.selFilters,
      }, 'TechData'))

      setState(s => ({
        ...s,
        data,
      }));
    } catch (err) {
      const msg = err.message;
      if ((msg || '').toLowerCase().indexOf('network error') > -1) {
        message.info('The network request has timed out, but your report is still being generated and will be sent to your email.')
      } else {
        message.error(`Failed to generate report: ${err.message}`);
      }
    } finally {
      setState(s => ({ ...s, loading: false }));
    }
  };

  const changeSelect = (filt: string, values: string[]) => {
    //@ts-ignore
    setState(s => ({ 
      ...s, 
      data: [],
      selFilters: {
        ...(filt == 'eqType' ? { sensor_statistics: ['1'] } : s.selFilters), 
        [filt]: values 
      } 
    }));
  };

  // #region date stuff
  const dateRangeChange = (
    _pickerVals: RangePickerValue,
    dates: [string, string]
  ) => {
    const date = { ...state.date };
    date.start = moment(dates[0]);
    date.end = moment(dates[1]);
    setState(s => ({ ...s, date }));
  };

  const datePickerChange = (date: moment.Moment) => {
    const _date = { ...state.date };
    _date.start = moment(date).startOf('day');
    _date.end = _date.start.clone().endOf('day');
    setState(s => ({ ...s, date: _date }));
  };

  const dateChange = (val: string) => {
    const date = { ...state.date };
    if (val === '1_day') {
      date.start = moment().subtract(1, 'day');
    } else if (val === '1_week') {
      date.start = moment().subtract(1, 'week');
    } else if (val === '1_month') {
      date.start = moment().subtract(1, 'month');
    } else if (val === '3_month') {
      date.start = moment().subtract(3, 'month');
    } else if (val === '6_month') {
      date.start = moment().subtract(6, 'month');
    } else if (val === '1_year') {
      date.start = moment().subtract(1, 'year');
    }
    date.end = moment();
    date.selected = val;
    setState(s => ({ ...s, date }));
  };

  const renderDateDrop = () => {
    const {
      date: { selected, start, end },
    } = state;

    const options = [
      { title: '1 day', value: '1_day' },
      { title: '1 week', value: '1_week' },
      { title: '1 month', value: '1_month' },
      { title: '3 months', value: '3_month' },
      { title: '6 months', value: '6_month' },
      { title: '1 year', value: '1_year' },
      // { title: 'All', value: 'all' },
    ];

    return (
      <div>
        <Select onChange={v => dateChange(v as string)} defaultValue={selected}>
          {options.map((opt, i) => (
            <Select.Option value={opt.value} key={i}>
              {opt.title}
            </Select.Option>
          ))}
        </Select>
        {selected === 'custom_date' && (
          <DatePicker.RangePicker
            onChange={dateRangeChange}
            defaultValue={[start, end]}
          />
        )}
        {selected === '1_day' && (
          <DatePicker onChange={datePickerChange} defaultValue={start} />
        )}
      </div>
    );
  };
  //#endregion

  const { loading, data } = state;

  const deps = [
    'selFilters.eqType',
    'selFilters.model',
    'selFilters.sensor',
    'selFilters.sensor_statistics',
  ]

  const canRun = size(deps.filter(d => get(state, d))) >= size(deps);

  return (
    <div style={{ height: '100%' }}>
      <TableListHeader style={`justify-content: flex-start;`}>
        {state.filters.map(filt => {
          return (
            <Select
              key={filt.id}
              mode="multiple"
              css={css(styles.select)}
              onChange={value => changeSelect(filt.id, value as string[])}
              filterOption={(input, option) => {
                const name = option.props.children as string;
                if (name)
                  return name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                return true;
              }}
              value={get(state, `selFilters.${filt.id}`)}
              {...(typeof filt.props == 'function' ? filt.props({ state }) : filt.props || {})}
            >
              {(typeof filt.opts == 'function' ? filt.opts({ state }) : filt.opts || []).map(
                (item: { label: string; value: string | number }) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                )
              )}
            </Select>
          );
        })}

        {renderDateDrop()}

        <Button title="Run Report" disabled={!canRun} onClick={retrieveData} />
      </TableListHeader>

      <Column center fullHeight>
        {loading ? <>
          <Loading />
          <p css={css(styles.generateText)}>Generating your report. You will receive an email when it finishes.</p>
        </> : <>
          <p css={css(defaultStyles.header)}>Technical Data Report</p>
          <p css={css(styles.generateText)}>Use the filters above to define the query, and then select 'Run Report'.  The results will be emailed to you within a few minutes.</p>
          {!!get(data, 'url') && <div style={{ textAlign: 'center', marginTop: 20 }}><a href={get(data, 'url')} target='_blank'>Download Report</a></div>}
        </>}
      </Column>

      {!!get(data, 'url') && <div style={{ textAlign: 'center', marginTop: 20 }}><a href={get(data, 'url')} target='_blank'>Download Report</a></div>}
    </div>
  );
};
