import { Component } from 'react';
import Pusher from 'pusher-js';
import CONFIG, { getEnv } from '../../config';
import { flatten, get, unset } from 'lodash';
import { Equipment } from '../interfaces/equipment';
import { addToMap, clearEquipMap } from '../../dashboard/dashboard.actions';
import { Store } from 'redux';
import { getAllDeviceIds } from '../../utils';
import { getHistory } from '../services/device-history.service';
import { Publish } from '../interfaces/publish';
import { awaitAll } from '../../utils/awaitAll';

const config = CONFIG();

export class EquipPusherComponent<P, S> extends Component<P, S> {
  constructor(props: P) {
    super(props);
    const getStore = get(window, 'SUPPORT.getStore', () => ({ store: {}, getState: () => 0 }))();
    this.store = get(getStore, 'store');
  }

  store: Store | undefined;
  pusher: Pusher.Pusher | undefined;
  channel: Pusher.Channel | undefined
  mounted = true;
  loading = false;

  unloadPusher = () => {
    this.channel && this.channel.unbind_all();
    this.pusher && this.pusher.disconnect();
    unset(this, 'channel');
    unset(this, 'pusher');
  }

  clearEquipMap = () => {
    //@ts-ignore
    this.store && this.store.dispatch && this.store.dispatch(clearEquipMap());
  }

  loadPusherChannel = async (_e: Equipment | Equipment[], _opts = {}) => {
    if (this.loading) { return }
    try {
      this.loading = true;
      const opts = {
        loadDetailedData: false,
        eventsToGet: [
          'dz_alert',
          'dz_sensor_statistics',
          'dz_sensor_instant',
          'dz_alert_data',
          'dz_profile_runtimes',
          'chair_sensor_statistics',
          'chair_sensor_instant',
          'chair_alert',
          'ster_cycle_end',
          'ster_sensor_instant',
          'ster_sensor_statistics',
  
          'ai_sensor_instant',
          'ai_sensor_statistics',
          'ai_alert_data',
          'ai_alert',
  
          //eventually remove these
          'iot_board/chair_sensor_statistics',
          'iot_board/chair_sensor_instant',
          'iot_board/chair_alert',
        ],
        ..._opts,
      }
  
      this.unloadPusher();
  
      this.pusher = new Pusher(config.pusher.key, {
        cluster: config.pusher.cluster,
        forceTLS: true,
      });
  
      this.channel = this.pusher.subscribe(config.pusherChannelName);
  
      const eqs = Array.isArray(_e) ? _e : [_e];
  
      const result = await Promise.all(eqs.map(async e => {
        this.channel && this.channel.bind(`publishCreate_${e.deviceId}`, data => {
          //@ts-ignore
          this.store && this.store.dispatch && this.store.dispatch(addToMap([data]));
        });
  
        if (opts.loadDetailedData) {
          const allDeviceIds = getAllDeviceIds(e);
  
          const allHistory = flatten(
            await Promise.all(allDeviceIds.map(async (ids) => {
              return !this.mounted ? [] : await awaitAll(opts.eventsToGet.map(ev => () => async () => await getHistory(ids.id, ev, ids.replaceDate)), { queueSize: 5 })
            }))
          );
  
          const flat = flatten(allHistory).map(p => ({
            ...p,
            coreid: get(allDeviceIds, [0, 'id']),
          })) as Publish[];
  
          if (this.mounted) {
            //@ts-ignore
            this.store && this.store.dispatch && this.store.dispatch(addToMap(flat, {
              clear: ['alerts', 'sensorStatistics']
            }));
          }
  
          return { data: flat }
        }
      }))
  
      return result;
    } catch (err) {
      console.log('Failed to channel', err);
    } finally {
      this.loading = false;
    }
  }

  unmount = () => {
    this.mounted = false;
    this.unloadPusher();
    this.clearEquipMap();
  }

  //ONLY WORKS IF YOUR COMPONENT DOESN"T OVERRIDE COMPONENTWILLUNMOUNT
  componentWillUnmount(): void {
    this.unmount();
  }
}