/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component, FormEvent, ReactNode } from 'react';
import { AppState } from '../../app.state';
import {
  Form,
  Input,
  Alert,
  DatePicker,
  InputNumber,
  Checkbox,
  Icon,
} from 'antd';
import moment, { Moment } from 'moment';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import FormTitle from '../../_shared/form-title';
import SharedStyles from '../../_shared/styles';
import { Button } from '../../_shared/button';
import Link from '../../_shared/link';
import styles from './edit-equipment.styles';
import {
  getEquipment,
  updateEquipment,
} from '../../_shared/services/manage-equipment.service';
import { _Equipment, Equipment } from '../../_shared/interfaces/equipment';
import { Location } from '../../_shared/interfaces/location';

import {
  findModel,
  userHasRole,
  isDentalOrgUser,
  isServiceOrgUser,
  getEquipmentModel,
  uppercaseFirst,
  getEquipConfig,
  sortByValue,
  checkEquipmentSN,
  checkEqSNUniq,
  isHandpiece,
  cleanCopy,
  getActiveModels,
  buildErrorMsgFromForm,
  _checkEquipmentSN,
  getEqType,
  isAerasOne,
} from '../../utils';
import { Org } from '../../_shared/interfaces/org';
import { Sidebar } from '../equipment-details/sidebar.component';
import { first, get, set } from 'lodash';
import { getOrg } from '../../_shared/services/manage-orgs.service';
import { Model } from '../../_shared/interfaces/model';
import { Select } from '../../_shared/select';
import { Opt } from '../../_shared/interfaces/opt';
import colors from '../../_shared/colors';
import { sendDeviceCommand } from '../../_shared/services/manage-schedule.service';
import {
  eventTypes,
  trackEvent,
} from '../../_shared/services/analytics.service';

interface IProps extends RouteComponentProps, FormComponentProps {
  sState: AppState;
  updateMyEquipment: () => void;
}

export interface IFormItem {
  title: () => string | string;
  var: string;
  type?: string;
  opts?: Opt[] | (() => Opt[]);
  match?: string;
  // tslint:disable-next-line:no-any
  options?: { rules: any[] };
  transform?: (str: string) => number | string;
  canDisplay?: () => boolean;
  props?: {};
  label?: string;
  onChange: () => void;
  allowClear: boolean;
  disabled?: boolean;
  subText?: (item: IFormItem) => string | ReactNode;
  style?: () => string | string;
  initialDate?: Moment;
}

interface IState {
  loading: boolean;
  error?: string;
  success?: string;
  equipment: Equipment;
  location?: Location;
  serviceOrg?: Org;
  dentalOrg?: Org;
  toDisplay: IFormItem[];
  isEdit: boolean;
  validationError?: boolean;
  booby?: boolean;
  cancelDate?: string;
  allEquipment?: Equipment[];
}

interface IPrefixMatches {
  ACR: string[];
  AQT: string[];
  ABN: string[];
  [key: string]: string[];
}

export class _EditEquipmentComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { history, sState } = props;

    const equipment = get(history, 'location.state.equipment');
    const model = getEquipmentModel(equipment, sState);
    const eqType = getEqType(equipment);
    const location = get(history, 'location.state.location');
    const serviceOrg = get(history, 'location.state.serviceOrg');
    const dentalOrg = get(history, 'location.state.dentalOrg');
    const isCompressor = get(model, 'type') === 'compressor';
    const toDisplay = ([
      {
        title: 'Equipment Name',
        var: 'name',
        options: {
          validateTrigger: 'onBlur',
          rules: [{ max: 25, message: 'Name is limited to 25 characters.' }],
        },
      },
      {
        title: () => {
          const {
            form: { getFieldValue },
          } = this.props;
          const model = getEquipmentModel(
            this.getEquipment(),
            this.props.sState
          );
          const val = getFieldValue('alexaNumber');
          if (model && val) {
            return `Alexa Name: ${uppercaseFirst(model.type || '')} ${val}`;
          }
          return `Alexa Name`;
        },
        type: 'number',
        var: 'alexaNumber',
        props: {
          min: 0,
          max: 99999,
          type: 'number',
        },
        canDisplay: () =>
          !isHandpiece(equipment) &&
          (userHasRole([0, 1], this.props.sState) ||
            isDentalOrgUser(this.props.sState)),
        transform: (val: string) => parseInt(val),
      },
      {
        var: 'onConsignment',
        type: 'checkbox',
        label: 'Consignment',
        canDisplay: () => userHasRole([0, 1], this.props.sState),
        options: {
          valuePropName: 'checked',
        },
      },
      {
        title: 'Service Org Purchase Order',
        var: 'soPurchaseOrder',
        canDisplay: () => true,
      },
      {
        title: 'Dental Org Purchase Order',
        var: 'doPurchaseOrder',
        canDisplay: () => true,
      },
      {
        title: 'Install Date',
        var: 'installDate',
        type: 'datepicker',
        canDisplay: () =>
          userHasRole([0, 1], this.props.sState) ||
          isServiceOrgUser(this.props.sState),
        props: {
          onBlur: async () => {
            const {
              form: { setFields, getFieldValue },
            } = this.props;
            let value = getFieldValue('installDate');
            let installDate = get(equipment, 'installDate');
            if (value) {
              setFields({
                installDate: {
                  value,
                  errors: undefined,
                },
              });
            }
            if (!installDate && value) {
              setFields({
                onConsignment: {
                  value: false,
                  errors: undefined,
                },
              });
            }
          },
        },
      },
      {
        title: 'Model Number',
        var: 'modelId',
        type: 'dropdown',
        canDisplay: () => userHasRole([0, 1], this.props.sState),
        opts: () => {
          let activeModels = getActiveModels();

          if (model && isAerasOne(eqType)) {
            activeModels = activeModels.filter((m: Model) => m.type == eqType);
          } else {
            activeModels = activeModels.filter((m: Model) => !m.type.includes('aerasone'))
          }
          
          return activeModels.map((m: Model) => ({
            label: `${m.name} (${m.id})`,
            value: m.id,
          })).sort(sortByValue)
        },
        onChange: (value: string) => {
          const {
            form: { setFields, getFieldValue },
          } = this.props;
          const equipmentSN = getFieldValue('equipmentSN');
          const str = 'state.toDisplay.7';
          if (equipmentSN) {
            const eSNIsValid = checkEquipmentSN(equipmentSN, value)[0];
            if (!!eSNIsValid) {
              this.setState({ validationError: false });
              setFields({
                equipmentSN: {
                  value: equipmentSN,
                  errors: undefined,
                },
              });
              set(this, str + '.props.suffix', null);
            } else {
              this.setState({ validationError: true });
              setFields({
                equipmentSN: {
                  value: equipmentSN,
                  errors: [
                    new Error(
                      'Validation error:  Equipment Serial Number entered does not match format \
                            for selected model'
                    ),
                  ],
                },
              });
              set(
                this,
                str + '.props.suffix',
                <Icon
                  type={'close-circle'}
                  css={css(`color: ${colors.error};`)}
                />
              );
            }
          }
        },
      },
      {
        title: 'Equipment Serial Number',
        var: 'equipmentSN',
        canDisplay: () => userHasRole([0, 1], this.props.sState),
        suffix: <span />,
        props: {
          onBlur: () => {
            const {
              form: { setFields, getFieldValue },
            } = this.props;
            const { allEquipment, equipment } = this.state;
            const str = 'state.toDisplay.8';
            const that = get(this, str);
            const value = getFieldValue('equipmentSN');
            let modelId = getFieldValue('modelId');
            if (!value) return;

            set(that, 'props.suffix', <Icon type="loading" />);
            const isUniq = checkEqSNUniq(
              allEquipment as Equipment[],
              value,
              equipment
            );
            const validcheck = first(_checkEquipmentSN(value, modelId, getEqType(equipment)));
            const isValid = !validcheck;
            if (!isValid || !isUniq) {
              this.setState({ validationError: true }, () => {
                setFields({
                  equipmentSN: {
                    value,
                    errors: [
                      !isValid && validcheck,
                      !isUniq &&
                        new Error(
                          'Validation error: Equipment Serial Number must be unique.'
                        ),
                    ],
                  },
                });
                set(
                  this,
                  str + '.props.suffix',
                  <Icon
                    type={'close-circle'}
                    css={css(`color: ${colors.error};`)}
                  />
                );
              });
              return;
            }
            this.setState({ validationError: false });
            setFields({
              equipmentSN: {
                value,
                errors: undefined,
              },
            });
            set(
              this,
              str + '.props.suffix',
              <Icon
                type={'check-circle'}
                css={css(`color: ${colors.success};`)}
              />
            );
          },
        },
        options: {
          validateTrigger: 'onBlur',
          rules: [
            {
              len: 10,
              message: 'EquipmentSN must be exactly 10 alphanumeric characters',
            },
          ],
        },
      },
      {
        title: 'Control Module SN',
        var: 'controlModuleSN',
        canDisplay: () =>
          userHasRole([0, 1], this.props.sState) && isHandpiece(equipment),
      },
      {
        title: 'Device ID',
        var: 'deviceId',
        canDisplay: () => userHasRole([0], this.props.sState),
      },
      {
        title: isCompressor ? 'Motor Head 1 SN' : 'Motor SN',
        var: 'mSerial1',
        canDisplay: () => userHasRole([0, 1], this.props.sState) && !isAerasOne(eqType),
      },
      {
        title: 'Motor Head 2 SN',
        var: 'mSerial2',
        canDisplay: () =>
          isCompressor &&
          get(this.getEquipment(), 'mSerial2') &&
          userHasRole([0, 1], this.props.sState) && !isAerasOne(eqType),
      },
      {
        title: 'Motor Head 3 SN',
        var: 'mSerial3',
        canDisplay: () =>
          isCompressor &&
          get(this.getEquipment(), 'mSerial3') &&
          userHasRole([0, 1], this.props.sState) && !isAerasOne(eqType),
      },
      {
        title: 'Tank SN',
        var: 'tankSerial',
        canDisplay: () =>
          isCompressor &&
          get(this.getEquipment(), 'tankSerial') &&
          userHasRole([0, 1], this.props.sState) && !isAerasOne(eqType),
      },
      {
        title: 'Dryer SN',
        var: 'dryerSerial',
        canDisplay: () =>
          isCompressor &&
          get(this.getEquipment(), 'dryerSerial') &&
          userHasRole([0, 1], this.props.sState) && !isAerasOne(eqType),
      },
      {
        title: 'Vacuum Pump SN',
        var: 'pumpSerial',
        canDisplay: () =>
          !isCompressor &&
          !isHandpiece(equipment) &&
          userHasRole([0, 1], this.props.sState) && !isAerasOne(eqType),
      },
    ] as unknown) as IFormItem[];

    this.state = {
      isEdit: true,
      canEditRole: false,
      loading: false,
      error: undefined,
      success: undefined,
      equipment,
      serviceOrg,
      dentalOrg,
      location,
      toDisplay,
      cancelDate: undefined,
      allEquipment: [],
    } as IState;
  }
  trackEvent = (event = '', data = {}) => {
    trackEvent(event, { ...data, equipment: this.state.equipment });
  };
  componentDidMount = () => {
    this.retrieveEquipment();
    this.setFieldsOriginal();
  };

  getEquipment = () => {
    return get(this, 'state.equipment');
  };
  retrieveEquipment = async () => {
    this.setState({ loading: true });

    const e = this.getEquipment();
    const allEquipment = get(this, 'props.sState.dash.equipment', []);
    try {
      const equipment = await getEquipment(e.deviceId);
      if (!equipment.installDate) {
        this.props.form.setFieldsValue({ installDate: null });
      }
      const serviceOrg = await getOrg(e.serviceOrgId).catch(() => undefined);
      this.setState({
        loading: false,
        serviceOrg,
        equipment: new _Equipment(equipment),
        allEquipment,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };
  getItems = (item: 'locations' | 'orgs') => {
    const {
      sState: { dash },
    } = this.props;
    const items = (dash[item] as unknown) as Location[];
    return items.map(item => ({ label: item.name, value: item.id })) as Opt[];
  };

  submit = async (e?: MouseEvent | FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (this.state.loading) {
      return;
    }

    const state = { ...this.state };

    state.loading = true;
    state.error = undefined;
    state.success = undefined;
    this.setState(state);

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return this.setState({
          error: buildErrorMsgFromForm(err),
          loading: false,
        });
      }
      try {
        const { sState } = this.props;
        const equipment = new _Equipment(this.getEquipment());
        const userId = get(sState, 'auth.user.userId');

        const originalEquipment = cleanCopy(equipment);

        this.state.toDisplay.map(item => {
          const val = item.transform
            ? item.transform(values[item.var])
            : values[item.var];

          const canDisplay = item.canDisplay ? item.canDisplay() : true;

          if (item.type === 'datepicker' && canDisplay) {
            if (val) {
              equipment[item.var] = moment(val)
                .toDate()
                .toISOString();
            } else {
              delete equipment[item.var];
            }
          } else if (val !== undefined && canDisplay) {
            equipment[item.var] = val;
          }
        });

        const dte = new Date().toISOString();
        set(equipment, 'updatedAt', dte);
        set(equipment, 'updatedBy', userId);

        if (!equipment.createdAt) {
          set(equipment, 'createdAt', dte);
        }
        if (!equipment.createdBy) {
          set(equipment, 'createdBy', userId);
        }

        if (!equipment.installDate) {
          delete equipment.installDate;
        }

        const equipments = (this.state.allEquipment || []).filter(
          e => e.dentalOrgId === equipment.dentalOrgId
        );
        const model = getEquipmentModel(equipment, sState);

        const eqNums = equipments.filter(e => {
          const em = getEquipmentModel(e, sState);
          if (
            em &&
            model &&
            em.type === model.type &&
            equipment.alexaNumber &&
            e.alexaNumber === equipment.alexaNumber &&
            e.id !== equipment.id
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (eqNums.length > 0) {
          return this.setState({
            loading: false,
            error:
              'That Alexa Number already exists within the Dental Orgs equipment.',
          });
        }

        const updateSerial = async (
          deviceId: string,
          name: string,
          serial: string
        ) => {
          await sendDeviceCommand(
            deviceId,
            { arg: `set_sn,${name},${serial}` },
            { showErrorToast: true },
            isHandpiece(equipment)
          );
        };

        const origEquip = this.getEquipment();

        const checks = [
          { var: 'mSerial1', name: 'motor1' },
          { var: 'mSerial2', name: 'motor2' },
          { var: 'mSerial3', name: 'motor3' },
        ];

        await Promise.all(
          checks.map(async c => {
            const val = get(equipment, c.var);
            if (val && val !== get(origEquip, c.var)) {
              await updateSerial(equipment.deviceId, c.name, val as string);
            }
          })
        );

        if (get(equipment, 'modelId') !== get(origEquip, 'modelId')) {
          await sendDeviceCommand(
            equipment.deviceId,
            { arg: `set_model,${equipment.modelId}` },
            { showErrorToast: true },
            isHandpiece(equipment)
          );
        }

        await updateEquipment(equipment);

        this.trackEvent(eventTypes.eq_update, {
          old: originalEquipment,
          new: equipment,
        });
        this.setState({
          loading: false,
          equipment,
          success: 'Data saved successfully!',
        });

        this.goBack();
      } catch (err) {
        return this.setState({
          error: 'Save failed: ' + err.message,
          loading: false,
        });
      }
    });
  };

  setFieldsOriginal = () => {
    const { toDisplay, equipment } = this.state;

    const {
      form: { setFieldsValue },
    } = this.props;

    toDisplay.map(item => {
      let val = equipment[item.var];
      if (item.match) {
        val = get(this, item.match, val);
      }
      if (item.type === 'datepicker') {
        val = (moment(val as string) as unknown) as number;
      }
      setFieldsValue({ [item.var]: val });
    });
  };
  goBack = () => {
    this.props.history.goBack();
  };
  goToBillingHistory = (equtipmentId: string) => {};
  render() {
    const {
      sState,
      form: { getFieldDecorator },
    } = this.props;
    const {
      toDisplay,
      loading,
      error,
      success,
      equipment,
      validationError,
    } = this.state;
    const { isPhoneBreak } = get(sState, 'dash.view');
    const config = getEquipConfig(equipment, sState);
    return (
      <div css={css(styles.container)}>
        <div css={css(SharedStyles.row, `height: 100%; width: 100%;`)}>
          {!isPhoneBreak && (
            <Sidebar
              equipment={equipment}
              model={findModel(equipment, sState)}
              updateEquipment={this.retrieveEquipment}
              config={config}
              goToBillingHistory={this.goToBillingHistory}
            />
          )}

          <div css={css(styles.mainContent)}>
            <div
              css={css(
                SharedStyles.formContainer,
                `width: 80%; padding-bottom: 20px;`
              )}
            >
              <Link css={css('margin-bottom: 5px;')} onClick={this.goBack}>
                {'< Back'}
              </Link>

              <FormTitle size={'20px'} title={`Edit Equipment Information`} />

              {error && (
                <Alert
                  css={css(SharedStyles.formAlert)}
                  type="error"
                  message={error}
                  closable
                  onClose={() => this.setState({ error: undefined })}
                />
              )}

              {success && (
                <Alert
                  css={css(SharedStyles.formAlert)}
                  type="success"
                  message={success}
                  closable
                  onClose={() => this.setState({ success: undefined })}
                />
              )}

              <Form layout="vertical" onSubmit={this.submit}>
                {toDisplay.map((item, i) => {
                  const canDisplay = item.canDisplay ? item.canDisplay() : true;
                  const sty =
                    typeof item.style === 'function'
                      ? item.style()
                      : item.style;
                  return !canDisplay ? null : (
                    <Form.Item
                      key={i}
                      css={css(sty)}
                      label={
                        typeof item.title === 'function'
                          ? item.title()
                          : item.title
                      }
                    >
                      {getFieldDecorator(item.var, item.options)(
                        item.type === 'dropdown' && item.opts ? (
                          <Select onChange={item.onChange}>
                            {(typeof item.opts == 'function' ? item.opts() : item.opts).map((opt, i) => (
                              <Select.Option key={i} value={opt.value}>
                                {opt.label}
                              </Select.Option>
                            ))}
                          </Select>
                        ) : item.type === 'datepicker' ? (
                          <DatePicker
                            {...item.props}
                            disabled={item.disabled}
                            defaultPickerValue={undefined}
                          />
                        ) : item.type === 'number' ? (
                          <InputNumber {...item.props} />
                        ) : item.type === 'checkbox' ? (
                          <Checkbox>{item.label}</Checkbox>
                        ) : (
                          <Input
                            ref={comp => set(this, item.var, comp)}
                            {...item.props}
                          />
                        )
                      )}
                      {item && item.subText && item.subText(item)}
                    </Form.Item>
                  );
                })}

                <Button
                  title={'Cancel'}
                  disabled={loading}
                  css={css`
                    margin-right: 10px;
                  `}
                  outline={true}
                  onClick={this.goBack}
                />
                <Button
                  title={'Save'}
                  loading={loading}
                  disabled={!!validationError}
                  onClick={this.submit}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const EditEquipmentComponent = Form.create()(
  withRouter(_EditEquipmentComponent)
);
