/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core';
import { Component, MouseEvent } from 'react';
import { Modal, Icon, message } from 'antd';
import { Equipment } from '../interfaces/equipment';
import { Button } from '../button';
import SharedStyles from '../styles';
import styles, { globals } from './modal-event.styles';
import moment from 'moment';
import { Publish, _Publish } from '../interfaces/publish';
import { sendDeviceCommand } from '../services/manage-schedule.service';
import {
  errorDef,
  generatePublish,
  getEqType,
  getFullEqType,
  getTimeForPubAlert,
  isAerasOne,
  isChair,
  isHandpiece,
} from '../../utils';
import { get } from 'lodash';
import { createPublish } from '../services/publish.service';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { Sensor } from '../interfaces/sensor';
import { getAlert, getMessage, getInfo } from '../../utils/publish';
import { getEquipmentType } from '../../utils';
import { eventTypes, trackEvent } from '../services/analytics.service';
import { getHistory } from '../services/device-history.service';
import { Loading } from '../icon';

interface IProps {
  sState?: AppState;
  allowClear?: boolean;
  visible: boolean;
  onClose: (e?: MouseEvent) => void;
  equipment: Equipment;
  publish?: Publish;
  onClear?: () => void;
  isActive?: boolean;
  supressEvent?: boolean;
}

export class _ModalEvent extends Component<IProps> {
  state = {
    loading: false,
    loadingData: false,
  };
  trackEvent = (event = '', data = {}) => {
    if (this.props.supressEvent) {
      return;
    }
    trackEvent(event, { equipment: this.props.equipment, ...data });
  };
  clearAlert = async () => {
    const { equipment, publish } = this.props;

    const finish = async () => {
      try {
        this.setState({ loading: true });

        const source = get(publish, 'data.source');
        const errorCode = get(publish, `data.error`, 0);
        const eDef = errorDef(errorCode);

        const eType = getFullEqType(equipment);

        if (!equipment.deviceId) {
          this.props.onClose();
          console.error('No device ID');
          return;
        }

        if (!isChair(equipment)) {
          await sendDeviceCommand(
            equipment.deviceId,
            eType.isAerasOne ?
              { cmd: 'clear_alert', threshold: `${source},${get(eDef, 'clearCmd')}` } :
              { arg: `clear_alert,${source}` },
            { showErrorToast: isHandpiece(equipment) ? true : false },
            isHandpiece(equipment)
          );
        }

        this.trackEvent(eventTypes.eq_alert_clear, { publish });

        await createPublish({
          ...generatePublish(this.props.sState),
          event: 'dz_alert',
          data: JSON.stringify({
            seq: 1,
            source: get(publish, 'data.source'),
            error: 0,
          }),
          coreid: equipment.deviceId,
        });

        if (this.props.onClear) {
          this.props.onClear();
        }
      } catch (err) {
        console.error(err);
        this.setState({
          loading: false,
        });

        const respValue = get(err, 'data.return_value');

        if (respValue === -10) {
          message.error('Alert has already been cleared');
        } else {
          Modal.error({
            title: 'Error',
            content: 'Clearing of Alert failed. Please try again.',
          });
        }
      }
    };

    Modal.confirm({
      title: 'Confirmation: Clear Alert',
      content: 'Are you sure you want to clear this alert?',
      onCancel: () => 0,
      onOk: finish,
      okText: 'Clear Alert',
    });
  };
  loadData = async () => {
    try {
      const equipType = getEqType(this.props.equipment);
      if (isAerasOne(equipType)) {
        this.setState({ loadingData: true });
        const hist = await getHistory(this.props.equipment.deviceId, 'ai_alert', undefined, 100);

        const publish = hist.find(h => get(h, 'data.time') == get(this, 'props.publish.data.time') && get(h, 'data.error') == get(this, 'props.publish.data.error'));

        this.setState({ hist, publish })
      }
    } catch (err) {

    } finally {
      this.setState({ loadingData: false });
    }
  }
  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.visible && this.props.visible) {
      this.trackEvent(eventTypes.eq_alert_details, {
        publish: this.props.publish,
      });
      this.loadData();
    }
  }
  render() {
    const {
      onClose,
      allowClear,
      visible,
      equipment,
      publish,
      sState,
      isActive,
    } = this.props;

    const { loadingData } = this.state;

    const rawDataAccessor = isHandpiece(equipment) ? 'data.alert' : 'data';

    const { loading } = this.state;
    const pub = (publish || new _Publish()) as Publish;
    const source = get(pub, `${rawDataAccessor}.source`, 'AT');
    let sensor = (source &&
      get(sState, 'dash.sensors', []).find((s: Sensor) => s.id === source)) || {
      id: source,
    };

    const errorCode = get(pub, `${rawDataAccessor}.error`, 0);
    const eDef = errorDef(errorCode);
    const equipType = getEqType(equipment);

    const isAOne = isAerasOne(equipType);

    return (
      <Modal
        visible={visible}
        centered
        closable
        onCancel={onClose}
        title={
          <div css={css(SharedStyles.row)}>
            <Icon
              type="warning"
              theme="filled"
              css={css(`
                font-size: 16px;
                color: ${eDef.color};
              `)}
            />
            <div css={css(styles.titleText)}>{`${equipment.name} Alert`}</div>
          </div>
        }
        footer={
          <div css={css(SharedStyles.row, 'justify-content: center;')}>
            <Button title="Okay" onClick={onClose} loading={loading} />
            {allowClear && publish && eDef.isMinor && isActive && (
              <Button
                outline={true}
                css={css('margin-left: 10px;')}
                title="Clear Alert"
                loading={loading}
                onClick={this.clearAlert}
              />
            )}
          </div>
        }
      >
        <Global styles={globals} />
        <div css={css(SharedStyles.column)}>
          {loading && <Icon type={'loading'} />}
          {!loading && (
            <div css={css(styles.text)}>
              <div css={css(styles.dateHeader)}>
                {`Alert published at ${moment(pub.published_at).format(
                  'LT on L'
                )}`}
              </div>
              <ul css={css('margin-top: 10px;')}>
                <li>
                  {sensor &&
                    `Alert: ${getAlert(sensor.id, errorCode, equipType)}`}
                </li>
                <li>
                  {sensor &&
                    `Message: ${getMessage(sensor.id, errorCode, equipType)}`}
                </li>
                {isAOne && <li>Threshold: {loadingData ? <Loading size={12} /> : get(this, 'state.publish.data.threshold')}</li>}
                {isAOne && <li>Measured Value: {loadingData ? <Loading size={12} /> : get(this, 'state.publish.data.value')}</li>}
                <li>{`Time of alert: ${moment(getTimeForPubAlert(pub)).format(
                  'lll'
                )}`}</li>
              </ul>
              {!!sensor && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: getInfo(sensor.id, errorCode, equipType),
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export const ModalEvent = connect((sState: AppState) => ({ sState }))(
  _ModalEvent
);
