/* tslint:disable */
import { store } from '../app.component';
import { get, set, size, take, first, uniqBy } from 'lodash';
import { Equipment, _Equipment } from '../_shared/interfaces/equipment';
import moment from 'moment';
import { Publish } from '../_shared/interfaces/publish';
import { hydrateSensors, hydrateModels2 } from '../_shared/lib/hydrate';
import {
  updateEquipment,
  createEquipment,
  getEquipments,
  deleteEquipment,
  getEquipmentRecords,
} from '../_shared/services/manage-equipment.service';
import { thresholds } from '../_shared/lib/thresholds';
import { Model, _Model } from '../_shared/interfaces/model';
import { Location } from '../_shared/interfaces/location';
import {
  createModel,
  getModels,
  updateModel,
} from '../_shared/services/manage-models.service';
import { updateLocation } from '../_shared/services/manage-locations.service';
import { sendDeviceCommand } from '../_shared/services/manage-schedule.service';
import { Org } from '../_shared/interfaces/org';
import { Emails } from '../_shared/lib/emails';
import { sendEmail } from '../_shared/services/email.service';
import { getEnv, getUrl } from '../config';
import { OrgType } from '../_shared/interfaces/orgType';
import { updateOrgType } from '../_shared/services/manage-org-types.service';
import { getHistory } from '../_shared/services/device-history.service';
import { createPublish } from '../_shared/services/publish.service';
import { cleanPhoneNumber, removeEmpty } from '.';
import { updateOrg } from '../_shared/services/manage-orgs.service';
import { awaitAll, prog } from './awaitAll';
import { createSensor, updateSensor } from '../_shared/services/manage-sensors.service';
import axios from 'axios';
import { updateParticleName } from '../dashboard/manufacture-process/manufacture-process.utils';

export const findEquipFromCoreid = (coreid: string) => {
  const sState = store.getState();
  const eqs = get(sState, 'dash.equipment', []) as Equipment[];
  // tslint:disable-next-line:no-console
  console.log(get(sState, ['dash', 'equipMap', coreid]));
  return {
    equipment: eqs.find(e => e.deviceId === coreid),
    eMap: get(sState, ['dash', 'equipMap', coreid]),
  };
};

export const findEquipWhere = async (where = {}) => {
  // tslint:disable-next-line:max-line-length
  const eqs = await getEquipments(undefined, false); // get(sState, 'dash.equipment', []) as Equipment[];

  const eqret = eqs.filter(e => {
    let match = false;
    for (let k in where) {
      match = get(e, k) === get(where, k);
    }
    return match;
  });
  // tslint:disable-next-line:no-console
  console.log(eqret);
  return eqret;
};

export const findEquipsWhere = async (where = {}) => {
  // tslint:disable-next-line:max-line-length
  const eqs = await getEquipments(undefined, false); // get(sState, 'dash.equipment', []) as Equipment[];
  const { dash } = store.getState();
  // tslint:disable-next-line:no-console
  const eqret = eqs.filter(e => {
    e = {
      ...e,
      emap: get(dash, `equipMap.${e.deviceId}`, {}),
    };

    if (typeof where === 'function') {
      try {
        return where(e);
      } catch (err) { }
    } else {
      let match = false;
      for (let k in where) {
        match = get(e, k) === get(where, k);
      }
      return match;
    }
  });
  return eqret;
};

export const findLocationsWhere = (where = {}) => {
  // tslint:disable-next-line:max-line-length
  const sState = store.getState();
  const eqs = get(sState, 'dash.locations', []) as Location[];
  // tslint:disable-next-line:no-console
  console.log(
    eqs.filter(e => {
      let match = false;
      for (let k in where) {
        match = get(e, k) === get(where, k);
      }
      return match;
    })
  );
};

export const findLocationsWithCode = () => {
  // tslint:disable-next-line:max-line-length
  const sState = store.getState();
  const eqs = get(sState, 'dash.locations', []) as Location[];
  // tslint:disable-next-line:no-console
  console.log(eqs.filter(e => e.code));
};

export const getOrgById = (id: string) => {
  const sState = store.getState();
  const eqs = get(sState, 'dash.orgs', []) as Org[];
  // tslint:disable-next-line:no-console
  console.log(eqs.filter(e => e.id === id));
};

export const findPreviousSerials = async (where = {}) => {
  // tslint:disable-next-line:max-line-length
  const eqs = await getEquipments(undefined, false); // get(sState, 'dash.equipment', []) as Equipment[];
  // tslint:disable-next-line:no-console
  console.log(
    eqs.filter(e => {
      return e.previousSerials;
    })
  );
};

export const findEquipFromSN = (coreid: string) => {
  const sState = store.getState();
  const eqs = get(sState, 'dash.equipment', []) as Equipment[];
  return eqs.find(e => e.equipmentSN === coreid);
};

export const updateEquipModel = () => {
  const sState = store.getState();

  const equip = get(sState, 'dash.equipment', []);
  const models = get(sState, 'dash.models', []);
  const me = get(sState, 'auth.user');

  const findOldModel = (modelId: string) => {
    // tslint:disable-next-line:no-any
    return models.find((m: any) => m.oldModel === modelId);
  };

  equip.map((e: Equipment) => {
    const modelId = get(e, 'modelId');
    if (modelId) {
      const newModel = findOldModel(modelId);
      if (newModel) {
        e.modelId = newModel.id;
        set(e, 'updatedAt', moment().toISOString());
        set(e, 'updatedBy', me.userId);
        updateEquipment(e);
      }
    }
  });
};

export const updateModelImage = () => {
  const sState = store.getState();

  const models = get(sState, 'dash.models', []);

  models.map((m: Model) => {
    if (m.type === 'compressor') {
      m.image = 'compressor.png';
      updateModel(m);
    }
  });
};

export const cleanEquipment = () => {
  const sState = store.getState();
  const me = get(sState, 'auth.user');
  const equip = get(sState, 'dash.equipment', []);

  equip.map((e: Equipment) => {
    if (e.key) {
      delete e.key;
      set(e, 'updatedAt', moment().toISOString());
      set(e, 'updatedBy', me.userId);
      updateEquipment(e);
    }
  });
};

export const updateAllEquipment = () => {
  const sState = store.getState();
  const me = get(sState, 'auth.user');
  const equip = get(sState, 'dash.equipment', []);

  equip.map((e: Equipment) => {
    set(e, 'updatedAt', moment().toISOString());
    set(e, 'updatedBy', me.userId);
    e.showOnDash = true;
    updateEquipment(e);
  });
};

const setNewThresholds = () => {
  const sState = store.getState();
  const models = get(sState, 'dash.models', []);

  models.map((m: Model) => {
    const thresh = get(thresholds, m.id);
    if (thresh) {
      set(m, 'thresholds', thresh);
      updateModel(m);
    }
  });
};

export const getStore = () => {
  const sState = store.getState();
  return {
    store,
    sState,
  };
};

export const findDailys = () => {
  const sState = store.getState();
  const emap = get(sState, 'dash.equipMap');
  const found = [];
  for (let k in emap) {
    const e = emap[k];
    const sensors = get(e, 'sensorStatistics', []);
  }
};

export const findAlertData = (coreid: string) => {
  const sState = store.getState();
  const sensors = get(
    sState,
    `dash.equipMap.${coreid}.sensorStatistics`
  ) as Publish[];
  const alerts = sensors.filter(s => {
    return s.event === 'dz_alert_data';
  });
  return alerts;
};

export const setLocationDates = () => {
  const sState = store.getState();
  const locations = get(sState, 'dash.locations', []) as Location[];
  locations.map(l => {
    updateLocation({
      ...l,
      activationDate: new Date('1/1/2020').toISOString(),
    } as Location)
      .then(resp => console.warn(resp))
      .catch(err => console.error(err));
  });
};

export const findLocationCancel = () => {
  const sState = store.getState();
  const locations = get(sState, 'dash.locations', []) as Location[];
  return locations.filter(l => l.cancelDate);
};

export const updateDeviceLocationHistories = () => {
  const url =
    'https://5i0j2yyd8b.execute-api.us-east-1.amazonaws.com/dev/history/create';
  const sState = store.getState();
  const equipment = get(sState, 'dash.equipment', []) as Location[];
  equipment.map(l => {
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({ ...l }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(resp => console.warn(resp))
      .catch(err => console.error(err));
  });
};

export const createEquip = () => {
  const eqs = [
    {
      id: '12a79176-39a0-47a4-82dd-bd74d191a344',
      alert: 0,
      alwaysSmart: false,
      bars: 3,
      controllerSerial: 'P007AB1342UMNPY',
      createdAt: '2020-10-01T15:50:05.203Z',
      createdBy: 'rpierce@dentalez.com',
      deviceId: 'e00fce68dc6a55656bac0f45',
      deviceStatus: 'on',
      doPurchaseOrder: '85856-3-966 ',
      DOSaleDate: '2020-10-24T00:52:25.627Z',
      dryerSerial: '111900166',
      equipmentSN: 'ACR2010115',
      fw_version: 54,
      heads_enabled: [true, true],
      isActive: 1,
      model: 'Aeras 6 Compressor',
      modelId: 'C6-2',
      modes: {
        debug: 0,
        high_pressure: false,
        high_voltage: false,
        maintenance: false,
      },
      mSerial1: '0320329144',
      mSerial2: '0320326013',
      name: 'ACR2010115',
      onConsignment: false,
      schedule_enabled: true,
      serviceOrgId: '1efdd550-fa6a-4420-adee-281ede3e7897',
      showOnDash: true,
      SOSaleDate: '2020-11-18T16:24:38.930Z',
      subscriptionHistory: [
        {
          billingPreference: 'trial',
          subscriptionEnd: '2021-11-08T04:59:59.999Z',
          subscriptionStart: '2021-05-07T04:00:00.000Z',
        },
      ],
      tankSerial: '2250919',
      test: '2020-10-01T16:16:17.069Z',
      testCompletion: '2020-10-01T17:14:38.926Z',
      testDataUrl:
        'https://publish-test-results-dev.s3.amazonaws.com/ACR2010115/2020-10-01T17:14:42.157Z-ACR2010115.pdf',
      testStartedBy: 'Renee Pierce',
      type: 'compressor',
      updatedAt: '2022-04-16T14:24:01.176Z',
      updatedBy: 'publish@dentalez.com',
      previousSerials: [
        {
          dateChange: '2020-05-20T17:27:41.455Z',
          controllerSerial: 'P007VN952DTFYJK',
          deviceId: 'e00fce6811c0e0f01a11e152',
          replaceDate: '2020-05-20T17:27:41.455Z',
        },
      ],
    },
  ];

  eqs.map(e => {
    const eq = new _Equipment((e as unknown) as Equipment);
    if (eq.serialNumber) {
      delete eq.serialNumber;
    }
    createEquipment(eq);
  });
};

export const createChair = async () => {
  const eq = new _Equipment({
    modelId: 'CHAIR-1',
    deviceId: 'e00fce687bba5984a68chair',
  } as Equipment);

  // tslint:disable-next-line:no-console
  console.log({ eq });

  const created = await createEquipment(eq);

  return created;
};

export const getChair = async () => {
  const eq = await findEquipFromCoreid('e00fce687bba5984a68chair');
  // tslint:disable-next-line:no-console
  console.log({ eq });
  return eq;
};

export const updateChair = async (obj = {}) => {
  const eq = await getChair();

  // obj = {
  //   showOnDash: true,
  //   modelId: 'CHAIR-6400',
  //   equipmentSN: 'BU22060001',
  //   fw_version: 'v12.1',
  //   hardwareVersion: 'v3',
  //   serviceOrgId: '84e15035-c067-4de5-9584-d636bd33003f',
  //   dentalOrgId: '8da147de-ac76-46e0-928f-0b86fe270a3f',
  //   locationId: '80cba88f-14a2-43a4-8c31-c75b3218ef4b',
  //   ...obj,
  // }

  const upd = new _Equipment({ ...(eq.equipment || {}), ...obj } as Equipment);
  const created = await createEquipment(upd);

  // tslint:disable-next-line:no-console
  console.log({ eq, created });

  return eq;
};

export const deviceCommand = async (id: string, command: string) => {
  const resp = await sendDeviceCommand(id, { arg: command });
  console.warn(resp);
};

export const sendTemplateEmail = async () => {
  const email = Emails.event({
    ToAddresses: ['bruce@go-helios.com'],
    Body: 'Stuff',
    Subject: 'Test Email',
  });

  const resp = await sendEmail(email, {
    type: 'aeras_device_alert',
    variables: {
      devicetype: 'Vacuum',
      alertname: 'Aeras Vacuum Reminder',
      alerttype: 'Maintenance',
      alertdescription:
        // tslint:disable-next-line:max-line-length
        'Oil and oil filter are due to be replaced.  V-belt and oil drip rate should also be inspected.  Only genuine Ramvac parts are to be used for repairs and maintenance in order to comply with the warranty terms.',
      alerticon: 'calendar',
      modelname: 'Aeras 4 Dry Vacuum',
      modelnumber: 'RV4-12',
      alertdatestamp: 'Thursday, 28 October, 2021',
      equipmentserial: 'AQT1111111',
      equipmenturl: 'https://aeras.dentalez.com',
      dentalorgname: 'Test Alert Dental Org Name',
      dentallocation: 'Test Alert Dental Org Location',
    },
  });

  console.warn(resp);
};

export const fixOrgTypes = async () => {
  const { sState } = getStore();
  const orgTypes = get(sState, 'dash.orgTypes', []);
  const newOrgTypes = orgTypes.map((ot: OrgType) => {
    if (ot.id === 1) {
      ot.types = [
        {
          value: 'government',
          id: 3,
          name: 'Government',
        },
        {
          value: 'dentalSchool',
          id: 2,
          name: 'Dental School',
        },
        {
          value: 'groupDentalPractice',
          id: 4,
          name: 'Group Dental Practice',
        },
        {
          value: 'dentalPractice',
          id: 1,
          name: 'Dental Practice',
        },
        {
          value: 'dso',
          id: 5,
          name: 'DSO',
        },
      ];
    }
    return ot;
  });
  await Promise.all(newOrgTypes.map((ot: OrgType) => updateOrgType(ot)));
};

export const deleteBlankEquipment = async () => {
  const { sState } = getStore();
  const blank = get(sState, 'dash.equipment', []).filter(
    (e: { modelId: string }) => !e.modelId
  );
  // await Promise.all(blank.map((e: Equipment) => deleteEquipment(e)));
  return blank;
};

export const fixTestResults = async () => {
  const eqs = await getEquipments(undefined, false); // get(sState, 'dash.equipment', []) as Equipment[];

  await awaitAll(
    eqs.map((e: Equipment) => () => async () => {
      const isundefined =
        (get(e, 'testDataUrl', '') || '').indexOf('/undefined') > -1;

      if (!e.deviceId || (e.testDataUrl && !isundefined)) {
        return;
      }

      const testresult = first(await getHistory(e.deviceId, 'dz_testresults'));

      if (testresult) {
        await createPublish(testresult);
      }
    }),
    { queueSize: 1 }
  );

  // tslint:disable-next-line:no-console
  console.log('COMPLETE');
};

export const fixOrgPhone = async () => {
  const { sState } = getStore();
  const orgs = get(sState, 'dash.orgs', []);

  const fixedorgs: Org[] = [];
  orgs.map((org: Org) => {
    if (!!org.phone) {
      const phone = cleanPhoneNumber(org.phone);
      if (phone !== org.phone) {
        fixedorgs.push({ ...org, phone });
      }
    }
  });

  await Promise.all(
    fixedorgs.map(async org => {
      updateOrg(org);
    })
  );
};

export const findSKEQ = async () => {
  // tslint:disable-next-line:max-line-length
  const eqs = await getEquipmentRecords(undefined, true); // get(sState, 'dash.equipment', []) as Equipment[];
  const eqret = eqs.filter(e => {
    return e.sk !== e.deviceId;
  });

  return eqret;
};

export const fixHpEquipment = async () => {
  const eqs = await getEquipments(undefined, false); // get(sState, 'dash.equipment', []) as Equipment[];

  const hpeq = eqs.filter(e => e.modelId === 'E900');
};

export const createChairModel = async () => {
  const { sState } = getStore();
  // const eqs = await getEquipments(undefined, false); // get(sState, 'dash.equipment', []) as Equipment[];
  const models = get(sState, 'dash.models', []);

  const chairs = models.filter((e: Model) => e.id === '6400');

  if (!size(chairs)) {
    await createModel(
      new _Model({
        id: '6400',
        sk: '6400',
        description: 'AERAS 6400 CHAIR',
        family: 'Aeras',
        heads: 0,
        image: '3900_Chair.jpg',
        isActive: 1,
        name: 'Aeras 6400 Chair',
        thresholds: {},
        type: 'chair',
      })
    );
  }
};

export const fixModelSensors = async () => {
  const { sState } = getStore();
  const sensors = get(sState, 'dash.sensors', []);
  const news = [
    { name: 'Mainboard Comms', id: 'MC', description: 'Main control board' },
    {
      name: 'Cycle Count',
      id: 'cycle_count',
      description: 'Increments each time the pump is used',
      isChair: 1,
    },
    {
      name: `Today's Sessions`,
      id: 'sessions_per_day',
      description: 'Session count for this chair, per day',
      showMin: false,
      showMax: false,
      showLegend: false,
      isChair: 1,
    },
    {
      name: 'Patient In Chair',
      id: 'in_chair',
      description:
        'Indicator of whether or not there is a patient in the chair',
      isChair: 1,
    },
    {
      name: 'Line Pressure',
      id: 'LP',
      description: 'Line pressure from facility',
      suffix: 'PSI',
      isChair: 1,
    },
    {
      name: 'Line Vacuum',
      id: 'LV',
      description: 'Line vacuum from facility',
      suffix: 'inH',
      isChair: 1,
    },
    {
      name: 'Chair Base Limit',
      id: 'BASES',
      description:
        'Indicator of whether or not the chair back position sensor is tripped',
      isChair: 1,
    },
    {
      name: 'Chair Base Position',
      id: 'BASEP',
      description:
        'Indicator of whether or not the chair back position sensor is connected',
      isChair: 1,
    },
    {
      name: 'Chair Seat Limit',
      id: 'SEATS',
      description:
        'Indicator of whether or not the chair seat position sensor is tripped',
      isChair: 1,
    },
    {
      name: 'Chair Seat Position',
      id: 'SEATP',
      description:
        'Indicator of whether or not the char seat position sensor is connected',
      isChair: 1,
    },
    {
      name: 'Cantilever Arm Limit',
      id: 'CANTIS',
      description:
        'Indicator of whether or not the cantilever arm sensor is tripped',
      isChair: 1,
    },
    {
      name: 'Button Combination or Out of Range',
      id: 'BCR',
      description: 'Unknown button combination or out of range',
      isChair: 1,
    },
    {
      name: 'Signal Strength',
      id: 'bars',
      description:
        'The strength of the cellular connection for this piece of equipment',
      showMin: false,
      showMax: false,
      isChair: 1,
    },
  ];

  const all = [...sensors, ...news];

  await Promise.all(
    all.map(async s => {
      const create = {
        ...s,
        sk: s.sk || s.id,
        isChair: s.isChair || 0,
        isCompressor: s.isCompressor || 0,
        isVacuum: s.isVacuum || 0,
        isHandpiece: s.isHandpiece || 0,
        isSterilizer: s.isSterilizer || 0,
      };

      await createSensor(create);
    })
  );
};

export const fixDisplayModels = async () => {
  const { sState } = getStore();
  const models = ['C3-12SS', 'C5-12SS'];
  const validmodels = get(sState, 'dash.models', []).filter(
    (m: Model) => models.indexOf(m.id) > -1
  );

  await Promise.all(
    validmodels.map(async (m: Model) => {
      set(m, 'showOnManu', false);
      await createModel(m);
    })
  );

  console.log({
    validmodels,
  });
};

export const transferModelsToProd = async () => {
  // const qamodels = get(await axios.get(`${getUrl('qa')}/model`), 'data.models', []);
  // await Promise.all(qamodels.map(async model => {
  //   await axios.post(`${getUrl('prod')}/model/create`, {
  //     model: removeEmpty(model),
  //   })
  // }))
  // console.log({
  //   qamodels,
  // })
};

export const transferSensorsToProd = async () => {
  // const qamodels = get(await axios.get(`${getUrl('qa')}/sensor`), 'data.sensors', []);
  // await Promise.all(qamodels.map(async sensor => {
  //   await axios.post(`${getUrl('prod')}/sensor/create`, {
  //     sensor: removeEmpty(sensor),
  //   })
  // }))
  // console.log({
  //   qamodels,
  // })
};

export const fixEq = async (coreid: string, obj = {}) => {
  const eq = findEquipFromCoreid(coreid);

  if (get(eq, 'equipment')) {
    await updateEquipment(({
      ...get(eq, 'equipment', {}),
      ...obj,
    } as unknown) as Equipment);
  }
};

export const fixValidEq = async () => {
  const eqs = await getEquipments();

  const notValid = eqs.filter(e => !e.equipmentSN);

  await awaitAll(notValid.map(eq => () => async () => {
    await updateEquipment(eq);
  }), {
    queueSize: 10,
    onProgress: prog,
  })

  console.log({
    notValid,
  })
}

// tslint:disable-next-line:no-any
(window as any).SUPPORT = {
  getEnv,
  findEquipFromCoreid,
  moment,
  getStore,
  findAlertData,
  hydrateSensors,
  hydrateModels2,
  updateEquipModel,
  setNewThresholds,
  setLocationDates,
  updateDeviceLocationHistories,
  cleanEquipment,
  createEquip,
  updateModelImage,
  updateAllEquipment,
  findEquipFromSN,
  findEquipWhere,
  findEquipsWhere,
  findLocationCancel,
  findPreviousSerials,
  deviceCommand,
  findLocationsWhere,
  findLocationsWithCode,
  getOrgById,
  sendTemplateEmail,
  fixOrgTypes,
  deleteBlankEquipment,
  fixTestResults,
  fixOrgPhone,
  findSKEQ,
  createChair,
  updateChair,
  getChair,
  fixHpEquipment,
  createChairModel,
  fixModelSensors,
  fixDisplayModels,
  transferModelsToProd,
  transferSensorsToProd,
  fixEq,
  fixValidEq,
  updateParticleName,
};
