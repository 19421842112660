export interface Sensor {
  id: string;
  name: string;
  description: string;
  isCompressor: number;
  isVacuum: number;
  isHandpiece: number;
  isSterilizer: number;
  isChair: number;
  suffix?: string;
  isAerasOne?: number;
}

export class _Sensor {
  constructor(props?: Sensor) {
    for (let key in props) {
      //@ts-ignore
      this[key] = props[key];
    }
  }

  [key: string]: string | number | undefined;
  id = '';
  name = '';
  description = '';
  isCompressor = 0;
  isVacuum = 0;
  isHandpiece = 0;
  isSterilizer = 0;
  isChair = 0;
  isAerasOne = 0;
  suffix = '';

  //@ts-ignore
  isType (eqType: string = '') {
    const ecompressor = eqType.includes('compressor');
    const evac = eqType.includes('vacuum');
    const aerasone = eqType.includes('aerasone');
    const chair = eqType.includes('chair');
    const hp = eqType.includes('handpiece');
    const ster = eqType.includes('sterilizer');


    if (aerasone) {
      return this.isAerasOne ? (ecompressor ? this.isCompressor : this.isVacuum) : 0;
    }

    if (ecompressor) {
      return this.isCompressor;
    } else if (evac) {
      return this.isVacuum;
    } else if (chair) {
      return this.isChair;
    } else if (hp) {
      return this.isHandpiece;
    } else if (ster) {
      return this.isSterilizer;
    }
  }
}