import React from 'react';
import { Equipment, ETYPES } from '../../_shared/interfaces/equipment';
import { Model, InfoRow, InfoCol } from '../../_shared/interfaces/model';
import { ICol } from '../../_shared/info-row';
import { get, size, set } from 'lodash';
import {
  renderCol,
  renderPosition,
  renderSignalStrength,
} from '../../utils/mixins';
import {
  singleOrAverage,
  userHasRole,
  errorDef,
  equipIsMetric,
  metricConvert,
  getEqType,
  getEquipmentModel,
  chairMountTrue,
  cleanCopy,
  getAvg,
  convertHex,
} from '../../utils';
import colors from '../../_shared/colors';
import { ISensorInfo } from './equipment-details.component';
import { EquipmentStatus } from '../../_shared/interfaces/equipmentStatus';
import moment from 'moment';
import { AppState } from '../../app.state';
import { HandpieceConfigPData } from '../../_shared/interfaces/publishData';
import { Publish } from '../../_shared/interfaces/publish';

//this belongs somewhere else but I've stolen enough refactor time for this ticket
interface Alert {
  data: { error: number };
}

type ColClickHandler = (
  sensor: string,
  sensorInfo: ISensorInfo,
  opts?: {}
) => void;

type ColBuilderArgs = {
  sn: ISensorInfo;
  alertMap: { [key: string]: Alert };
  clickCol: ColClickHandler;
  isMetric: boolean;
  fwVersion: number;
  deviceStatus: string;
  eqStatus?: EquipmentStatus;
  equipment: Equipment;
  loading: boolean;
  eqType: string;
  appState: AppState;
  model: Model;
  config: Publish;
};

type ColGenerator = {
  model?: Model;
  sState: AppState;
  loading: boolean;
  click: ColClickHandler;
  pubData: EquipmentStatus | undefined;
  sensorInfo: ISensorInfo;
  alertMap: { [key: string]: Alert };
  config: Publish;
}

export type Row = { title: string; columns: ICol[] };

type RowSet = Row[];

export const sensorColumnBuilder = function(
  appState: AppState,
  loading: boolean,
  clickCol: ColClickHandler,
  eqStatus: EquipmentStatus | undefined,
  sn: ISensorInfo,
  alertMap: { [key: string]: Alert },
  equipment: Equipment,
  config: Publish,
): RowSet {
  const isMetric = equipIsMetric(equipment, appState);
  const eqType = getEqType(equipment);
  const model = getEquipmentModel(equipment);
  const deviceStatus = get(equipment, 'deviceStatus');
  const fwVersion = get(equipment, 'fw_version', 0);

  const props = {
    sn,
    alertMap,
    clickCol,
    isMetric,
    loading,
    fwVersion,
    equipment,
    deviceStatus,
    eqStatus,
    appState,
    model,
    config,
  } as ColBuilderArgs;

  const rows = (() => {
    const components = {
      //new eq type just add in here
      compressor: compressorRows,
      vacuum: vacuumRows,
      chair: chairRows,
      sterilizer: sterilizerRows,
      handpiece: handpieceRows,
      unknown: compressorRows,
    };

    return get(components, eqType, components.unknown)(props);
  })();

  return (rows || []).filter((f: { title: string; columns: ICol[] }) =>
    size(f.columns)
  );
}

const valueText = (
  val: string | number,
  ifVal: string,
  isMetric = false,
  ifNull = '--'
) => {
  const v = singleOrAverage(val);
  const ret =
    typeof v !== 'boolean' && (v === null || isNaN(v)) ? ifNull : ifVal;
  return metricConvert(ret, isMetric) as string;
};

export const timeText = (val: string | number | undefined, _opts = {}) => {
  const opts = {
    ifnone: '--',
    timeValue: 'seconds',
    includeSeconds: false,
    ..._opts,
  };

  //@ts-ignore
  const dur = moment.duration(val, opts.timeValue);
  const _mins = dur.asMinutes();

  const hrs = parseInt(`${_mins / 60}`);
  const mins = parseInt(`${_mins - hrs * 60}`);
  const minPlur = mins > 1 ? 'mins' : 'min';

  if (!val) {
    return opts.ifnone;
  }

  const hoursonly = get(opts, 'hoursonly', false);

  if (hrs > 0 || hoursonly) {
    const hrPlur = hrs > 1 ? 'hrs' : 'hr';

    if (hoursonly) {
      return `${hrs} ${hrPlur}`;
    }

    return `${hrs} ${hrPlur} ${mins} ${minPlur}`;
  }

  let secs = '';
  if (opts.includeSeconds) {
    let _secs = parseInt(`${(_mins - mins) * 60}`);
    let secPlr = _secs > 1 ? 'secs' : 'sec';
    if (_secs > 0) {
      secs = ` ${_secs} ${secPlr}`;
    }
  }

  return `${mins} ${minPlur}${secs}`;
};

const getColor = (sensor: string, alertMap: { [key: string]: Alert }) => {
  const hasAlert = get(alertMap, sensor);
  return hasAlert ? errorDef(hasAlert.data.error).color : undefined;
};

const commonRows = (
  args: ColBuilderArgs
): { performance: ICol[]; sensors: ICol[]; motors: ICol[] } => {
  const {
    sn,
    clickCol,
    fwVersion,
    eqStatus,
    deviceStatus,
    appState,
    eqType,
  } = args;

  let performance: ICol[] = [];

  if (fwVersion >= 38 && eqType !== ETYPES.handpiece) {
    performance.push({
      onClick: () => clickCol('bars', sn),
      render: () => {
        return renderSignalStrength(
          { value: sn.bars },
          { text: 'Signal Strength', icon: 'towerBroadcast' }
        );
      },
    });
  }

  //only DZ techs and admins need to see how many hours the device has been on consecutively
  const onarr = get(eqStatus, 'dz_config.data.device_on');
  if (userHasRole([0, 1, 7], appState) && size(onarr) >= 2) {
    const isDeviceOn =
      get(onarr, 0) && (deviceStatus || '').toLowerCase().includes('on');

    // start by assuming we are getting unix (in seconds) timestamp
    let deviceOnChangedTimeStamp = moment.unix(get(onarr, '1'));

    // if the timestamp is in the future, WE'VE GONE TOO FAR,
    // and probably should have parsed that timestamp as milliseconds
    if (deviceOnChangedTimeStamp > moment()) {
      deviceOnChangedTimeStamp = moment(get(onarr, 1));
    }

    const text = isDeviceOn
      ? `${moment().diff(deviceOnChangedTimeStamp, 'hours')} hrs`
      : '--';

    performance.push({
      render: () =>
        renderCol({ text }, { text: 'Continuous On Time', icon: 'clock' }),
    });
  }

  if (sn.AMG !== undefined && sn.AMG !== null && sn.AMG !== 'null') {
    performance.push({
      onClick: () => clickCol('AMG', sn),
      render: () => {
        const text = sn.AMG === 100 ? 'Full' : 'Working';
        const color = sn.AMG === 100 ? colors.error : undefined;
        return renderCol({ text, color }, { text: 'Amalgam Separator' }, true);
      },
    });
  }

  return { performance, sensors: [], motors: [] };
};

const vacuumRows = (args: ColBuilderArgs): RowSet => {
  const { sn, alertMap, clickCol, isMetric } = args;

  const common = commonRows(args);

  const performance: ICol[] = [
    {
      render: () =>
        renderCol(
          {
            text: valueText(sn.PR, `${singleOrAverage(sn.PR)} hrs`, isMetric),
            color: getColor('PR', alertMap),
          },
          { text: 'Pump Runtime', icon: 'hourglassEmpty' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(sn.ER, `${singleOrAverage(sn.ER)} hrs`, isMetric),
            color: getColor('ER', alertMap),
          },
          { text: 'Equipment Runtime', icon: 'hourglassEmpty' }
        ),
    },
    {
      onClick: () => clickCol('IP', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.IP, `${singleOrAverage(sn.IP)} inHg`, isMetric),
            color: getColor('IP', alertMap),
          },
          { text: 'Vacuum Level', icon: 'gaugeMed' },
          true
        ),
    },
    {
      onClick: () => clickCol('PT', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.PT, `${singleOrAverage(sn.PT)} F`, isMetric),
            color: getColor('PT', alertMap),
          },
          { text: 'Pump Temperature', icon: 'thermometer' },
          true
        ),
    },
    {
      render: () => {
        const latestOilChange = get(sn, 'latestMaint.data.oilchange', []);

        const maintInterval = get(latestOilChange, 2, 2000);
        const sinceLastService = get(latestOilChange, 1, 0);
        
        const oilLife = Math.round(
          ((maintInterval - singleOrAverage(sinceLastService)) / maintInterval) * 100
        );
        return renderCol(
          {
            text:
              oilLife > 0 ? valueText(sinceLastService, `${oilLife}%`, isMetric) : '0%',
            color: getColor('ER', alertMap),
          },
          { text: 'Oil Life', icon: 'oilCan' }
        );
      },
    },
  ];

  const sensors: ICol[] = [
    {
      onClick: () => clickCol('AT', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.AT, `${singleOrAverage(sn.AT)} F`, isMetric),
            color: getColor('AT', alertMap),
          },
          { text: 'Ambient Temperature', icon: 'thermometer' },
          true
        ),
    },
    {
      onClick: () => clickCol('AH', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.AH, `${singleOrAverage(sn.AH)} %`, isMetric),
            color: getColor('AH', alertMap),
          },
          { text: 'Ambient Humidity', icon: 'dropletPercent' },
          true
        ),
    },
    {
      onClick: () => clickCol('VL', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.VL, `${singleOrAverage(sn.VL)} V`, isMetric),
            color: getColor('VL', alertMap),
          },
          { text: 'Voltage Line', icon: 'meterBolt' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn.WO,
              `${singleOrAverage(sn.WO) ? 'Yes' : 'No'}`,
              isMetric
            ),
            color: getColor('WO', alertMap),
          },
          { text: 'Water in Oil', icon: 'dropletSlash' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn.OL,
              `${singleOrAverage(sn.OL) ? 'No' : 'Yes'}`,
              isMetric
            ),
            color: getColor('OL', alertMap),
          },
          { text: 'Oil Level Low', icon: 'oilCan' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn.WP,
              `${singleOrAverage(sn.WP) >= 10 ? 'Yes' : 'No'}`
            ),
            color: getColor('WP', alertMap),
          },
          { text: 'Moisture in Pump Inlet', icon: 'droplet' }
        ),
    },
  ];

  const motors: ICol[] = [
    {
      onClick: () => clickCol('M1T', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.M1T, `${singleOrAverage(sn.M1T)} F`, isMetric),
            color: getColor('M1T', alertMap),
          },
          { text: 'Motor Temp', icon: 'thermometer' },
          true
        ),
    },
    {
      onClick: () => clickCol('M1P', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.M1P, `${singleOrAverage(sn.M1P)} W`, isMetric),
            color: getColor('M1P', alertMap),
          },
          { text: 'Motor Power', icon: 'wrench' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(sn.M1R, `${singleOrAverage(sn.M1R)} hrs`, isMetric),
            color: getColor('M1R', alertMap),
          },
          { text: 'Motor Runtime', icon: 'hourglassEmpty' }
        ),
    },
  ];

  return [
    { title: 'Performance', columns: [...common.performance, ...performance] },
    { title: 'Vacuum Sensors', columns: [...common.sensors, ...sensors] },
    { title: 'Motor', columns: [...common.motors, ...motors] },
  ];
};

const compressorRows = (args: ColBuilderArgs): RowSet => {
  const { sn, alertMap, clickCol, isMetric, model } = args;
  const heads = get(model, 'heads', 1);

  const common = commonRows(args);

  const performance: ICol[] = [
    {
      onClick: () => clickCol('PDP', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.PDP, `${singleOrAverage(sn.PDP)} F`, isMetric),
            color: getColor('PDP', alertMap),
          },
          { text: 'Pressure Dew Pt.', icon: 'dropletPercent' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(sn.RGT, `${singleOrAverage(sn.RGT)} sec`, isMetric),
            color: getColor('RGT', alertMap),
          },
          { text: 'Regen Time', icon: 'timer' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn.PU,
              `${singleOrAverage(
                sn.PU,
                val =>
                  (parseFloat(val ? val.toString() : '0').toFixed(
                    1
                  ) as unknown) as number
              )} kWh`,
              isMetric
            ),
            color: getColor('PU', alertMap),
          },
          { text: 'Power Usage', icon: 'wrench' }
        ),
    },
    {
      onClick: () => clickCol('HDC', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.HDC, `${singleOrAverage(sn.HDC)}%`, isMetric),
            color: getColor('HDC', alertMap),
          },
          { text: 'Head Duty Cycle', icon: 'percent' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(sn.ER, `${singleOrAverage(sn.ER)} hrs`, isMetric),
            color: getColor('ER', alertMap),
          },
          { text: 'Equipment Runtime', icon: 'hourglassEmpty' }
        ),
    },
  ];

  const sensors: ICol[] = [
    {
      onClick: () => clickCol('TP', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.TP, `${singleOrAverage(sn.TP)} PSI`, isMetric),
            color: getColor('TP', alertMap),
          },
          { text: 'Tank Pressure', icon: 'gaugeMed' },
          true
        ),
    },
    {
      onClick: () => clickCol('TT', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.TT, `${singleOrAverage(sn.TT)} F`, isMetric),
            color: getColor('TT', alertMap),
          },
          { text: 'Tank Temperature', icon: 'thermometer' },
          true
        ),
    },
    {
      onClick: () => clickCol('TH', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.TH, `${singleOrAverage(sn.TH)}%`, isMetric),
            color: getColor('TH', alertMap),
          },
          { text: 'Tank Humidity', icon: 'dropletPercent' },
          true
        ),
    },
    {
      onClick: () => clickCol('AT', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.AT, `${singleOrAverage(sn.AT)} F`, isMetric),
            color: getColor('AT', alertMap),
          },
          { text: 'Ambient Temperature', icon: 'thermometer' },
          true
        ),
    },
    {
      onClick: () => clickCol('AH', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.AH, `${singleOrAverage(sn.AH)}%`, isMetric),
            color: getColor('AH', alertMap),
          },
          { text: 'Ambient Humidity', icon: 'dropletPercent' },
          true
        ),
    },
    {
      onClick: () => clickCol('VL', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.VL, `${singleOrAverage(sn.VL)} V`, isMetric),
            color: getColor('VL', alertMap),
          },
          { text: 'Voltage Line', icon: 'meterBolt' },
          true
        ),
    },
  ];

  const motors: ICol[] = [
    {
      onClick: () => clickCol('M1T', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.M1T, `${singleOrAverage(sn.M1T)} F`, isMetric),
            color: getColor('M1T', alertMap),
          },
          { text: 'Motor 1 Temp', icon: 'thermometer' },
          true
        ),
    },
    {
      onClick: () => clickCol('M1P', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.M1P, `${singleOrAverage(sn.M1P)} W`, isMetric),
            color: getColor('M1P', alertMap),
          },
          { text: 'Motor 1 Power', icon: 'wrench' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(sn.M1R, `${singleOrAverage(sn.M1R)} hrs`, isMetric),
            color: getColor('M1R', alertMap),
          },
          { text: 'Motor 1 Runtime', icon: 'hourglassEmpty' }
        ),
    },
    ...(heads >= 2
      ? [
          {
            onClick: () => clickCol('M2T', sn),
            render: () =>
              renderCol(
                {
                  text: valueText(
                    sn.M2T,
                    `${singleOrAverage(sn.M2T)} F`,
                    isMetric
                  ),
                  color: getColor('M2T', alertMap),
                },
                { text: 'Motor 2 Temp', icon: 'thermometer' },
                true
              ),
          },
        ]
      : []),
    ...(heads >= 2
      ? [
          {
            onClick: () => clickCol('M2P', sn),
            render: () =>
              renderCol(
                {
                  text: valueText(
                    sn.M2P,
                    `${singleOrAverage(sn.M2P)} W`,
                    isMetric
                  ),
                  color: getColor('M2P', alertMap),
                },
                { text: 'Motor 2 Power', icon: 'wrench' },
                true
              ),
          },
        ]
      : []),
    ...(heads >= 2
      ? [
          {
            render: () =>
              renderCol(
                {
                  text: valueText(
                    sn.M2R,
                    `${singleOrAverage(sn.M2R)} hrs`,
                    isMetric
                  ),
                  color: getColor('M2R', alertMap),
                },
                { text: 'Motor 2 Runtime', icon: 'hourglassEmpty' }
              ),
          },
        ]
      : []),
    ...(heads >= 3
      ? [
          {
            onClick: () => clickCol('M3T', sn),
            render: () =>
              renderCol(
                {
                  text: valueText(
                    sn.M3T,
                    `${singleOrAverage(sn.M3T)} F`,
                    isMetric
                  ),
                  color: getColor('M3T', alertMap),
                },
                { text: 'Motor 3 Temp', icon: 'thermometer' },
                true
              ),
          },
        ]
      : []),
    ...(heads >= 3
      ? [
          {
            onClick: () => clickCol('M3P', sn),
            render: () =>
              renderCol(
                {
                  text: valueText(
                    sn.M3P,
                    `${singleOrAverage(sn.M3P)} W`,
                    isMetric
                  ),
                  color: getColor('M3P', alertMap),
                },
                { text: 'Motor 3 Power', icon: 'wrench' },
                true
              ),
          },
        ]
      : []),
    ...(heads >= 3
      ? [
          {
            render: () =>
              renderCol(
                {
                  text: valueText(
                    sn.M3R,
                    `${singleOrAverage(sn.M3R)} hrs`,
                    isMetric
                  ),
                  color: getColor('M3R', alertMap),
                },
                { text: 'Motor 3 Runtime', icon: 'hourglassEmpty' }
              ),
          },
        ]
      : []),
  ];

  return [
    { title: 'Performance', columns: [...common.performance, ...performance] },
    { title: 'Compressor Sensors', columns: [...common.sensors, ...sensors] },
    { title: 'Motor Heads', columns: [...common.motors, ...motors] },
  ];
};

const handpieceRows = (args: ColBuilderArgs): RowSet => {
  const { sn, alertMap, clickCol, isMetric, eqStatus, loading } = args;
  const loadingSensor = loading;

  let configData: HandpieceConfigPData;

  if (eqStatus && eqStatus.dz_config) {
    configData = eqStatus.dz_config.data as HandpieceConfigPData;
  }

  const formatConfigData = (raw: string): string => {
    //these will come in formatted as pre_x_y where x is profile and y is preset (both numbers)
    const stringComponents = raw.split('_');
    if (stringComponents.length >= 3) {
      return `Profile ${stringComponents[1]} - Preset ${stringComponents[2]}`;
    }

    return 'None';
  };

  const common = commonRows(args);

  const performance: ICol[] = [
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['TOQAVG'],
              `${singleOrAverage(sn['TOQAVG'])}ncm`,
              isMetric
            ),
            color: getColor('TOQ', alertMap),
          },
          { text: 'Average Torque', icon: 'wrench' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['TOQMAX'],
              `${singleOrAverage(sn['TOQMAX'])}ncm`,
              isMetric
            ),
            color: getColor('TOQ', alertMap),
          },
          { text: 'Max Torque', icon: 'wrench' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['SPDAVG'],
              `${singleOrAverage(sn['SPDAVG'])}rpm`,
              isMetric
            ),
            color: getColor('SPD', alertMap),
            icon: 'chart-line',
          },
          { text: 'Average Speed', icon: 'gaugeLow' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['SPDMAX'],
              `${singleOrAverage(sn['SPDMAX'])}rpm`,
              isMetric
            ),
            color: getColor('SPD', alertMap),
            icon: 'chart-line',
          },
          { text: 'Max Speed', icon: 'gaugeLow' },
          true
        ),
    },
  ];

  const sensors: ICol[] = [
    {
      onClick: () => clickCol('bars', sn),
      render: () => {
        return renderSignalStrength(
          { value: sn.bars },
          { text: 'Signal Strength', icon: 'towerBroadcast' }
        );
      },
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['MTR'],
              `${parseInt(`${singleOrAverage(sn['MTR']) / 60}`)} hrs`
            ),
            color: getColor('MTR', alertMap),
          },
          { text: 'Runtime', icon: 'gaudMed' }
        ),
    },
    {
      onClick: () => clickCol('AP', sn),
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['AP'],
              `${singleOrAverage(sn['AP'])}PSI`,
              isMetric
            ),
            color: getColor('AP', alertMap),
          },
          { text: 'Handpiece Air Pressure', icon: 'gaugeMed' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['TMP'],
              `${singleOrAverage(sn['TMP'])}F`,
              isMetric
            ),
            color: getColor('TMP', alertMap),
          },
          { text: 'Handpiece Temperature', icon: 'thermometer' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['VDC'],
              `${singleOrAverage(sn['VDC'])}V`,
              isMetric
            ),
            color: getColor('VDC', alertMap),
          },
          { text: 'Supply Voltage', icon: 'meterBolt' },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: loadingSensor
              ? '--'
              : !!get(configData, 'current_preset')
              ? formatConfigData(configData.current_preset)
              : 'None',
            color: getColor('ER', alertMap),
            fontSize: loadingSensor ? undefined : 14,
          },
          { text: 'Current Preset' }
        ),
    },
  ];

  const motors: ICol[] = [
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['IA'],
              `${singleOrAverage(sn['IA'])}A`,
              isMetric
            ),
            color: getColor('IA', alertMap),
            icon: 'chart-line',
          },
          { text: 'Phase A Current', icon: 'bolt' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['IB'],
              `${singleOrAverage(sn['IB'])}A`,
              isMetric
            ),
            color: getColor('IB', alertMap),
            icon: 'chart-line',
          },
          { text: 'Phase B Current', icon: 'bolt' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['IC'],
              `${singleOrAverage(sn['IC'])}A`,
              isMetric
            ),
            color: getColor('IC', alertMap),
            icon: 'chart-line',
          },
          { text: 'Phase C Current', icon: 'bolt' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['TOTI'],
              `${singleOrAverage(sn['TOTI'])}A`,
              isMetric
            ),
            color: getColor('TOTI', alertMap),
            icon: 'chart-line',
          },
          { text: 'Phase Current Sum', icon: 'bolt' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['EMFA'],
              `${singleOrAverage(sn['EMFA'])}V`,
              isMetric
            ),
            color: getColor('EMFA', alertMap),
            icon: 'chart-line',
          },
          { text: 'Coil A Voltage', icon: 'meterBolt' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['EMFB'],
              `${singleOrAverage(sn['EMFB'])}V`,
              isMetric
            ),
            color: getColor('EMFB', alertMap),
            icon: 'chart-line',
          },
          { text: 'Coil B Voltage', icon: 'meterBolt' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['EMFC'],
              `${singleOrAverage(sn['EMFC'])}V`,
              isMetric
            ),
            color: getColor('EMFC', alertMap),
            icon: 'chart-line',
          },
          { text: 'Coil C Voltage', icon: 'meterBolt' }
        ),
    },
  ];

  return [
    { title: 'Sensors', columns: [...common.sensors, ...sensors] },
    { title: 'Performance', columns: [...common.performance, ...performance] },
    { title: 'Motor Heads', columns: [...common.motors, ...motors] },
  ];
};

const chairRows = (args: ColBuilderArgs): RowSet => {
  const { sn, alertMap, clickCol, isMetric, appState, config } = args;

  const chairMount = chairMountTrue(config);

  const stat2pubdate = get(sn, 'latestStat2.published_at');
  const stat2isSameDay = stat2pubdate
    ? moment(stat2pubdate).isSame(moment(), 'day')
    : false;

  const performance: ICol[] = [
    {
      onClick: () => clickCol('bars', sn),
      render: () => {
        return renderSignalStrength(
          { value: sn.bars },
          { text: 'Signal Strength', icon: 'towerBroadcast' }
        );
      },
    },
    {
      render: () =>
        renderPosition({
          sn,
          key: 'in_chair',
          title: 'Patient In Chair',
          icon: 'users',
        }),
    },
    chairMount && {
      onClick: () => clickCol('LP', sn),
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['LP'],
              `${singleOrAverage(sn['LP'])} PSI`,
              isMetric
            ),
            color: getColor('LP', alertMap),
          },
          { text: 'Line Pressure', icon: 'gaugeMed' },
          true
        ),
    },
    chairMount && {
      onClick: () => clickCol('LV', sn),
      render: () =>
        renderCol(
          {
            text: valueText(
              sn['LV'],
              `${singleOrAverage(sn['LV'])} inHg`,
              isMetric
            ),
            color: getColor('LV', alertMap),
          },
          { text: 'Line Vacuum', icon: 'gaugeMed' },
          true
        ),
    },
    {
      onClick: () => clickCol('AT', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.AT, `${singleOrAverage(sn.AT)} F`, isMetric),
            color: getColor('AT', alertMap),
          },
          { text: 'Ambient Temperature', icon: 'thermometer' },
          true
        ),
    },
    {
      onClick: () => clickCol('AH', sn),
      render: () =>
        renderCol(
          {
            text: valueText(sn.AH, `${singleOrAverage(sn.AH)}%`, isMetric),
            color: getColor('AH', alertMap),
          },
          { text: 'Ambient Humidity', icon: 'dropletPercent' },
          true
        ),
    },
  ].filter(f => !!f) as ICol[];

  const sensors: ICol[] = [
    {
      onClick: () =>
        clickCol(
          'sessions_per_day',
          get(sn, 'latestStat.data', {}) as ISensorInfo,
          { 
            latestSensorInfo: get(sn, 'latestStat2.data.session_count.0', get(sn, 'totalChairCount', 0)),
            currentReadingTitle: 'Total Sessions',
          }
        ),
      render: () =>
        renderCol(
          {
            text: valueText(get(sn, 'latestStat2.data.session_count.0', get(sn, 'totalChairCount')), `${get(sn, 'latestStat2.data.session_count.0', get(sn, 'totalChairCount'))}`),
          },
          { text: `Total Sessions`, icon: 'calendar' },
          true
        ),
    },
    {
      onClick: () =>
        clickCol(
          'total_session_time',
          get(sn, 'latestStat.data', {}) as ISensorInfo,
          { 
            latestSensorInfo: get(sn, 'latestStat2.data.total_session_time.0', {}),
            chartType: 'bar',
            showDateSelect: false,
            date: { selected: '1_day', start: moment().startOf('day'), end: moment().endOf('day') },
            chartOpts: [
              ['options.scales.xAxes.0.time', undefined],
              ['options.scales.xAxes.0.ticks', undefined],
              //@ts-ignore
              ['options.scales.yAxes.0.ticks.callback', (v) => `${v} mins`],
              ['options.barThickness', 10],
            ],
            pointBackgroundColor: convertHex('#000000', 0.6),
            //@ts-ignore
            data: !size(get(sn, 'currentDay', [])) ? null : cleanCopy(get(sn, 'currentDay', [])).map(s => {
              const news = { ...s };
              const k = 'data.total_session_time.3';
              const published_at = moment(s.published_at).subtract(get(s, k), 'seconds');
              news.published_at = published_at;

              set(s, k, parseFloat((get(s, k) / 60).toFixed(1)));
              return news;
            }),
            headerCols: [
              { 
                title: 'Avg Session Duration', 
                val: () => {
                  //@ts-ignore
                  const avg = getAvg(get(sn, 'currentDay', []).map(d => get(d, 'data.total_session_time.3')));
                  return `${(avg / 60).toFixed(0)} mins`
                }
              },
              { 
                title: 'Avg Time Between Sessions', 
                val: () => {
                  //@ts-ignore
                  const avg = getAvg(get(sn, 'currentDay', []).map(d => get(d, 'data.chair_turnover_time.3')));
                  return `${(avg / 60).toFixed(0)} mins`
                }
              },
            ]
          }
        ),
      render: () =>
        renderCol(
          {
            text: stat2isSameDay ? valueText(
              get(sn, 'latestStat2.data.sessions_per_day'),
              `${get(sn, 'latestStat2.data.sessions_per_day')}`
            ) : '--',
          },
          { text: `Today's Sessions`, icon: 'calendar' },
          true
        ),
    },
    {
      onClick: () =>
        clickCol(
          'total_session_time',
          get(sn, 'latestStat2.data', {}) as ISensorInfo,
          {
            latestSensorInfo: stat2isSameDay
              ? get(sn, 'latestStat2.data.total_session_time.0', {})
              : '--',
            isTime: { transformValue: (v: number) => v / 60 },
          }
        ),
      render: () =>
        renderCol(
          {
            text: timeText(
              stat2isSameDay
                ? get(sn, 'latestStat2.data.total_session_time.0')
                : undefined
            ),
          },
          { text: `Previous Session - Total` },
          true
        ),
    },
    {
      onClick: () =>
        clickCol(
          'total_consultation_time',
          get(sn, 'latestStat2.data', {}) as ISensorInfo,
          {
            latestSensorInfo: stat2isSameDay
              ? get(sn, 'latestStat2.data.total_consultation_time.0', {})
              : '--',
            isTime: { transformValue: (v: number) => v / 60 },
          }
        ),
      render: () =>
        renderCol(
          {
            text: timeText(
              stat2isSameDay
                ? get(sn, 'latestStat2.data.total_consultation_time.0')
                : undefined
            ),
          },
          { text: `Previous Session - Consultation` },
          true
        ),
    },
    {
      onClick: () =>
        clickCol(
          'total_treatment_time',
          get(sn, 'latestStat2.data', {}) as ISensorInfo,
          {
            latestSensorInfo: stat2isSameDay
              ? get(sn, 'latestStat2.data.total_treatment_time.0', {})
              : '--',
            isTime: { transformValue: (v: number) => v / 60 },
          }
        ),
      render: () =>
        renderCol(
          {
            text: timeText(
              stat2isSameDay
                ? get(sn, 'latestStat2.data.total_treatment_time.0')
                : undefined
            ),
          },
          { text: `Previous Session - Treatment` },
          true
        ),
    },
    {
      onClick: () =>
        clickCol(
          'chair_turnover_time',
          get(sn, 'latestStat2.data', {}) as ISensorInfo,
          {
            latestSensorInfo: stat2isSameDay
              ? get(sn, 'latestStat2.data.chair_turnover_time.0', {})
              : '--',
            isTime: { transformValue: (v: number) => v / 60 },
          }
        ),
      render: () =>
        renderCol(
          {
            text: timeText(
              stat2isSameDay
                ? get(sn, 'latestStat2.data.chair_turnover_time.0')
                : undefined
            ),
          },
          { text: `Time Between Sessions` },
          true
        ),
    },
  ];

  const motors: ICol[] = [
    {
      render: () =>
        renderCol(
          { text: get(sn, 'cycle_count', 0) },
          { text: `Equipment Cycle Count` }
        ),
    },
    {
      render: () =>
        renderCol(
          { text: get(sn, 'latestInstant.data.errors_count', 0) },
          { text: `Control Board Alert Count` }
        ),
    },
    {
      render: () =>
        renderCol(
          { text: get(sn, 'latestStat.data.control_board_reset_count', 0) },
          { text: `Control Board Reset Count` }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: timeText(
              get(sn, 'latestStat.data.control_board_total_time_on'),
              { hoursonly: true }
            ),
          },
          { text: `Control Board Total Time On` }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: timeText(
              get(sn, 'latestStat.data.control_board_session_time_on', 0)
            ),
          },
          { text: `Control Board Session Time On` }
        ),
    },
  ];

  const isTech = userHasRole([0, 1], appState);

  return [
    { title: 'Chair Sensors', columns: performance },
    { title: 'Session Data', columns: sensors },
    { title: 'Technical Details', columns: isTech ? motors : [] },
  ];
};

const sterilizerRows = (args: ColBuilderArgs): RowSet => {
  const { sn, alertMap, clickCol, isMetric } = args;

  const statpubdate = get(sn, 'latestStat.published_at');
  const statisSameDay = statpubdate
    ? moment(statpubdate).isSame(moment(), 'day')
    : false;

  const cSuccess = get(sn, 'cyclesSuccess', 0);
  const cTotal = get(sn, 'cyclesTotal', 0);

  const performance: ICol[] = [
    {
      render: () => {
        return renderSignalStrength(
          { value: 3 },
          { text: 'Signal Strength', icon: 'towerBroadcast' },
          { canClick: false }
        );
      },
    },
    {
      onClick: () =>
        clickCol(
          'cycles_per_day',
          get(sn, 'latestInstant.data', {}) as ISensorInfo,
          {
            latestSensorInfo: `${cSuccess}/${cTotal}`,
            headerCols: [
              {
                title: `Today's Cycles`,
                val: () => cTotal,
              },
              {
                title: 'Total Cycles',
                val: () => get(sn, 'latestDaily.data.total_cycle_count'),
              },
            ],
          }
        ),
      render: () =>
        renderCol(
          {
            text: `${cSuccess}/${cTotal}`,
            color: cSuccess < cTotal ? colors.error : colors.black,
          },
          { text: `Today's Cycles`, icon: 'arrowspin' },
          true
        ),
    },
    {
      render: () =>
        renderPosition({
          sn,
          key: 'latestInstant.data.in_use',
          title: 'Status',
          icon: 'dryerheat',
          inuseText: 'RUNNING',
          notInuseText: 'OFF',
        }),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              get(sn, 'latestInstant.data.CT'),
              `${singleOrAverage(get(sn, 'latestInstant.data.CT'))} F`,
              isMetric
            ),
            color: getColor('CT', alertMap),
          },
          { text: 'Chamber Temp', icon: 'thermometer' }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: valueText(
              get(sn, 'latestInstant.data.CP'),
              `${singleOrAverage(get(sn, 'latestInstant.data.CP'))} PSI`,
              isMetric
            ),
            color: getColor('CP', alertMap),
          },
          { text: 'Chamber Pressure', icon: 'gaugeMed' }
        ),
    },
  ];

  const dateFormat = 'MMM Do h:mm a';
  const isPass = get(sn, 'latestStat.data.success', false);
  const tReached = get(sn, 'latestStat.data.temp_reached', 0);
  const tTarget = get(sn, 'latestStat.data.temp_target', 0);

  const sensors: ICol[] = [
    {
      render: () =>
        renderCol(
          {
            text: isPass ? 'PASS' : 'FAIL',
            color: isPass ? colors.success : colors.error,
          },
          { text: `Status` }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: statisSameDay
              ? moment(getRightTime(get(sn, 'latestStat.data.start'))).format(
                  dateFormat
                )
              : '--',
          },
          { text: `Start time` }
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: statisSameDay
              ? moment(getRightTime(get(sn, 'latestStat.data.end'))).format(
                  dateFormat
                )
              : '--',
          },
          { text: `End Time` }
        ),
    },
    {
      onClick: () =>
        clickCol(
          'time_elapsed',
          get(sn, 'latestStat.data', {}) as ISensorInfo,
          {
            latestSensorInfo: get(sn, 'latestStat.data.time_elapsed'),
            isTime: {
              transformValue: (v: number) => v / 60,
              includeSeconds: true,
            },
          }
        ),
      render: () =>
        renderCol(
          {
            text: statisSameDay
              ? timeText(get(sn, 'latestStat.data.time_elapsed'), {
                  includeSeconds: true,
                })
              : '--',
          },
          { text: `Time Elapsed` },
          true
        ),
    },
    {
      render: () =>
        renderCol(
          {
            text: statisSameDay ? `${tReached} F` : '--',
            color: tReached < tTarget ? colors.error : colors.black,
          },
          { text: 'Temp Reached' }
        ),
    },
  ];

  return [
    { title: 'Sterilizer Sensors', columns: performance },
    { title: 'Previous Cycle Data', columns: sensors },
  ];
};

export const getRightTime = (time: number | string = '') => {
  return parseInt(`${parseInt(time.toString()) * 1000}`);
};

export const generateInfoRows = (e: Equipment, opts: ColGenerator) => {
  opts = {
    ...opts,
  }

  const model = get(opts, 'model');

  if (!model) { return }

  //new method of generating rows
  if (model.infoRows) {
    const isMetric = equipIsMetric(e, opts.sState);

    return model.infoRows.map((ir: InfoRow, i) => {
      return { 
        ...ir, 
        columns: ir.columns.filter(c => {
          let show = get(c, 'show', true);
          
          if (typeof show === 'string') {
            show = eval(show);
          }

          return show;
        }).map((c: InfoCol) => ({
          onClick: () => opts.click(c.key, opts.sensorInfo),
          render: () => c.key === 'bars' ? renderSignalStrength(
            { value: get(opts, `sensorInfo.${c.key}`) },
            { text: c.text || 'Signal Strength', icon: c.icon || 'towerBroadcast' }
          ) : renderCol(
            {
              text: valueText(get(opts, `sensorInfo.${c.key}`), `${singleOrAverage(get(opts, `sensorInfo.${c.key}`))}${c.suffix || ''}`, isMetric),
              color: getColor(c.key, opts.alertMap),
            },
            { text: c.text, icon: c.icon },
            get(c, 'canClick', true),
          ),
        }))
      }
    }) as unknown as RowSet;
  }

  return sensorColumnBuilder(opts.sState, opts.loading, opts.click, opts.pubData, opts.sensorInfo, opts.alertMap, e, opts.config);
}