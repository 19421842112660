import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { Model } from '../interfaces/model';
import { instance } from './instance';
import { RcFile } from 'antd/lib/upload';
import { AxiosResponse } from 'axios';

export const getModel = async (id: string) => {
  const resp = await instance.get(`/model/${id}`, { data: {} });
  return get(resp, 'data.model', null) as Model;
};

export const getModels = async (where?: {}) => {
  let resp = {};
  if (where) {
    resp = await instance.post(`/model`, {
      where,
    });
  } else {
    resp = await instance.get(`/model`, { data: {} });
  }
  return get(resp, 'data.models', []) as Model[];
};

export const createModel = async (model: Model) => {
  const resp = await instance.post('/model/create', {
    model: removeEmpty(model),
  });
  return get(resp, 'data.model', null) as Model;
};

export const updateModel = (model: Model) => {
  return createModel(model);
};

export const deleteModel = async (model: Model) => {
  const resp = await instance.delete(`/model/${model.id}`);
  return get(resp, 'data.model', null) as Model;
};

interface UploadModelImageResponse {
  success: boolean,
  filename?: string,
  error?: string
};

export const uploadModelImage = async (file: RcFile) => {
  if (file.size > (10 * 1024 * 1024)) {
    return "Image must be less than 1MB.";
  }

  let formData = new FormData();
  formData.append('image', file);

  try {
    let result = await instance.post('/model/uploadImage', formData) as AxiosResponse<UploadModelImageResponse>;
    console.log(`upload result: ${JSON.stringify(result)}`);
    return result;
  } catch (err) {
    console.error(`uploadModelImage failed: ${err}`);
    debugger;
    return `${err}`;
  }
};

export const syncModelThresholds = async (model: Model) => {
  try {
    const result = await instance.post('/model/SyncThresholds', {
      model_id: model.id,
    });
  } catch (err) {
    console.log(`syncModelThresholds failed: ${JSON.stringify(err)}`);
  }
};