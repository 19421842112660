/** @jsx jsx */
import './edit-instructions.css'
import React, { useState } from 'react';
import { css, jsx } from '@emotion/core';
import { InstructionsPage, Model } from "../../_shared/interfaces/model";
import { ChangeEvent } from "react";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { EditImageComponent } from "../../_shared/edit-image";
import { range } from 'lodash';
import { Button } from '../../_shared/button';

const EditImagesComponent: React.FC<{
  images: string[],
  setImages: (images: string[]) => void,
}> = props => {
  const { setImages } = props;
  const images = props.images ? props.images.filter(i => !!i && i.length > 0) : [];
  const gridTemplateRows = images.length > 1 ? '1fr 1fr' : '1fr 0fr';
  return <div className='edit-images-container' style={{ gridTemplateRows }}>
    {
      images.map((image, index) => <EditImageComponent
        imagePath={image}
        onChange={(filename?: string) => {
          if (filename) {
            images[index] = filename;
          } else {
            images.splice(index, 1);
            setImages(images);
          }
        }}
      />
      )
    }
    {
      (images.length < 4) &&
      <EditImageComponent
        imagePath={undefined}
        onChange={(filename?: string) => {
          console.log(`editImage onChange ${filename || 'undefined'}`);
          if (filename) {
            images.push(filename);
            setImages(images);
          }
        }} />
    }

  </div>
};

interface EditInstructionsPageProps {
  pageNum: number,
  title: string,
  setTitle: (title: string) => void,
  body: string,
  setBody: (body: string) => void,
  images: string[],
  setImages: (images: string[]) => void,
  onRemove?: () => void,
  showRemove?: boolean,
};

const EditInstructionsPage: React.FC<EditInstructionsPageProps> = props => {
  const { pageNum, title, setTitle, body, setBody, images, setImages, onRemove, showRemove } = props;

  return (
    <div className='edit-instructions-page'>
      <div className='eip-left-panel'>
        <div className='eip-item'>
          <div className='panel-title'>Images:</div>
          <EditImagesComponent images={images} setImages={setImages} /></div>
      </div>
      <div className='eip-right-panel'>
        <div className='eip-item'>
          <div className='panel-title'>Title:</div>
          <Input onChange={(event: ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)} value={title} />
        </div>
        <div className='eip-item'>
          <div className='panel-title'>Body:</div>
          <TextArea
            key={`eip-body-${pageNum}`}
            rows={3}
            onBlur={(event) => setBody(event.target.value)}
            defaultValue={body}
          />
        </div>
      </div>
      {showRemove && <Button
        css={css(`margin-top: 10px;`)}
        onClick={onRemove || (() => null)}
        title='Remove Page'></Button>}
      <hr></hr>
    </div>

  )
};

export const EditInstructionsComponent: React.FC<{
  instructions?: InstructionsPage[];
  setInstructions: (instructions: InstructionsPage[]) => void;
  allowPageEdit?: boolean;
}> = props => {
  const { setInstructions, allowPageEdit } = props;
  const instructions = props.instructions || [];

  const setPage = (i: number, page: InstructionsPage) => {
    instructions[i] = page;
    setInstructions(instructions);
  };

  return (
    <div className='edit-instructions-pages-container'>
      {instructions.map((page, i) => <EditInstructionsPage
        pageNum={i}
        title={page.title}
        setTitle={title => setPage(i, { ...page, title })}
        body={page.body}
        setBody={body => setPage(i, { ...page, body })}
        images={page.images}
        setImages={images => setPage(i, { ...page, images })}
        onRemove={() => {
          instructions.splice(i, 1);
          setInstructions(instructions);
        }}
        showRemove={allowPageEdit}
      />)}
      {allowPageEdit && <Button
        title='Add page'
        css={css(`margin-top: 10px; visibility:${allowPageEdit ? 'visible' : 'hidden'}`)}
        onClick={() => setInstructions([...instructions, { title: '', body: '', images: [] }])}>
      </Button>}
    </div>
  );
};

const InstructionsPageIndicator: React.FC<{
  currentPage: number,
  setCurrentPage: (page: number) => void,
  maximumPages: number,
}> = props => {
  const { currentPage, setCurrentPage, maximumPages } = props;

  const elements: JSX.Element[] = [];
  range(maximumPages).forEach((i) => {
    const className = i === currentPage ? 'ipi ipi-active' : 'ipi ipi-inactive';
    elements.push(<div
      className={className}
      onClick={() => setCurrentPage(i)}>{`${i + 1}`}</div>);
    if (i < (maximumPages - 1)) {
      elements.push(<div className='ipi-separator'>
        <div className='ipi-dot'></div>
        <div className='ipi-dot'></div>
        <div className='ipi-dot'></div>
        <div className='ipi-dot'></div>
        <div className='ipi-dot'></div>
      </div>)
    }
  });

  return <div className='ipi-container'>
    {elements}
  </div>;
};

export const EditInstructions: React.FC<{
  instructions: InstructionsPage[],
  onSave?: (instructions: InstructionsPage[]) => void,
  onCancel?: () => void,
  initialPage?: number,
}> = props => {
  const { instructions, onSave, onCancel, initialPage } = props;
  const [workingInstructions, setWorkingInstructions] = useState<InstructionsPage[]>(instructions);
  const [currentPage, setCurrentPage] = useState<number>(initialPage || 0);

  const page = instructions[currentPage];

  const setPage = (i: number, page: InstructionsPage) => {
    workingInstructions[i] = page;
    setWorkingInstructions(workingInstructions);
  }

  return <div className='edit-instructions-modal'>
    <InstructionsPageIndicator
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      maximumPages={instructions.length} />

    <div className='eim-page-container'>
      <EditInstructionsPage
        pageNum={currentPage}
        title={page.title}
        setTitle={title => setPage(currentPage, { ...page, title })}
        body={page.body}
        setBody={body => setPage(currentPage, { ...page, body })}
        images={page.images}
        setImages={images => setPage(currentPage, { ...page, images })}
        showRemove={false} />
    </div>

    <div className='eim-buttons'>
      <Button
        title='Cancel'
        onClick={() => {
          setCurrentPage(0);
          onCancel && onCancel();
        }}
        style={{ marginRight: '5px' }}
      />
      <Button
        title='Save'
        onClick={() => onSave && onSave(workingInstructions)}
      />
    </div>
  </div>;
};
