/** @jsx jsx */
import './edit-image.css'
import React from 'react';
import { jsx } from '@emotion/core';
import { useState } from "react";
import { uploadModelImage } from "../services/manage-models.service";
import { RcFile } from "antd/lib/upload";
import { Icon } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import getConfig from "../../config"
import colors from '../colors';

export const EditImageComponent: React.FC<{
  imagePath?: string,
  onChange: (filename?: string) => void,
}> = props => {

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const onRemove = () => {
    onChange(undefined);
  };

  const { imagePath, onChange } = props;

  const upload = async (file: RcFile) => {
    if (file.size > (10 * 1024 * 1024)) {
      console.error(`Image size ${file.size} too large`);
      setError('Image must be less than 1 MB.');
    }

    let result = await uploadModelImage(file);
    if (typeof (result) === 'string' || !result.data.success) {
      console.error(`Edit image failed: ${JSON.stringify(result)}`);
      const error = (typeof (result) === 'string') ? result : result.data.error;
      setError(error || 'upload failed');
      throw Error(`Upload failed: ${error}`);
    }
    // hacky - to implement custom upload, antd docs suggest
    // using beforeUpload and returning false or a rejected Promise
    // from it to prevent the "action" from being called. leaving
    // "action" undefined just results in the dragger posting the
    // file to the current URL. so we either throw a bogus error
    // here, or hit a 404.
    if (result.data.success) {
      onChange(result.data.filename);
      throw Error(`Upload succeeded`);
    } else {
    }
  };

  if (imagePath) {
    const fullPath = `${getConfig().model_photos}${imagePath}`;
    return (
      <div
        style={{ borderWidth: '1px', borderColor: colors.panelBorder, borderStyle: 'solid', borderRadius: '5px' }}
        className={'edit-image-container'}>
        <img
          src={fullPath}
          alt={'Model'}
          style={{ opacity: loading ? 0 : 1 }}
          onLoad={(() => {
            console.log('image loaded');
            setLoading(false);
          })} />
        <Icon className='loading-icon' type="loading" style={{ opacity: loading ? 1 : 0 }} />
        <div onClick={onRemove} className='delete-button'>
          <Icon
            className='delete-icon'
            onClick={onRemove}
            style={{ fontSize: '24px', color: colors.white, backgroundColor: colors.highlight }}
            type='delete'
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={'edit-image-container'}>
        <Dragger
          name='image'
          height={142}
          multiple={false}
          showUploadList={false}
          accept=".png,.jpg,.jpeg,image/jpeg,image/png"
          beforeUpload={upload}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag image to this area to upload
          </p>
          <p className="ant-upload-error">
            {error}
          </p>
        </Dragger>
      </div>
    );
  }
};

