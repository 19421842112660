/** @jsx jsx */
import './edit-model.css'
import { css, jsx } from '@emotion/core';
import styles from './edit-model.styles';
import SharedStyles from '../../_shared/styles';
import { ChangeEvent, FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { find, get, isEqual, set, uniq } from 'lodash';
import Link from '../../_shared/link';
import { Button } from '../../_shared/button';
import { Alert, Form, Icon, Input, Modal, Switch, } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { AppState } from '../../app.state';
import Select, { SelectValue } from 'antd/lib/select';
import { TableTitleHeader } from '../../_shared/table-list';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { Opt } from '../../_shared/interfaces/opt';
import { Model, Threshold, Thresh, EquipmentType, DefaultInfoRows } from '../../_shared/interfaces/model';
import { getModel, getModels, syncModelThresholds, updateModel, uploadModelImage } from '../../_shared/services/manage-models.service';
import { DataSourceItemType } from 'antd/lib/auto-complete';
import { AutoComplete } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { EditableFormTable } from '../../_shared/editable-table';
import { getSensorsForEquipmentType, sortByKey, uppercaseWords } from '../../utils';
import { RcFile } from 'antd/lib/upload';
import getConfig from '../../config'
import Dragger from 'antd/lib/upload/Dragger';
import { InstructionsPage } from '../../_shared/interfaces/model';
import colors from '../../_shared/colors';
import { EditInstructions } from './edit-instructions';
import ModelImage from '../../_shared/model-image';

interface EquipmentType_option {
  name: EquipmentType;
  displayName: string;
  defaultThresholds: Thresh;
};

const validateVersion = (rule: any, value: string, cb: any) => {
  const regex = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
  const passes = regex.test(value);
  cb(!value || passes ? undefined : rule.message);
};

const versionRules = [
  {
    required: true,
    message: 'Value is required. ',
  },
  {
    max: 8,
    message: 'Value is limited to 8 characters. ',
  },
  {
    validateVersion,
    message: 'Value is not valid. ',
  },
];

const sharedDefaultThresholds: Thresh = {
  AH: {
    minorUpper: 95,
  },
  AT: {
    minorLower: 32,
    minorUpper: 120,
  },
  BP: {
    minorLower: 90,
    majorLower: 20,
  },
};

const defaultInstruction = (eqType: EquipmentType): InstructionsPage[] =>
  (eqType === "aerasone_compressor") ? [
    {
      title: 'Compressor Step 1: Install Current Clamp',
      body: '',
      images: []
    },
    {
      title: 'Compressor Step 2: Connect to Remote Indicator',
      body: '',
      images: []
    },
    {
      title: 'Compressor Step 3: Install Pressure Sensor',
      body: '',
      images: []
    },
    {
      title: 'Compressor Step 4: Attach Accelerometer',
      body: '',
      images: []
    }
  ] : (eqType === "aerasone_vacuum") ? [
    {
      title: 'Vacuum Step 1: Install Current Clamp',
      body: '',
      images: []
    },
    {
      title: 'Vacuum Step 2: Connect to Remote Indicator',
      body: '',
      images: []
    },
    {
      title: 'Vacuum Step 3: Install & Calibrate Suction Sensor',
      body: '',
      images: []
    },
    {
      title: 'Vacuum Step 4: Attach Accelerometer',
      body: '',
      images: []
    }
  ] : [
  ];
;

const equipmentTypes = [
  {
    name: "aerasone_compressor",
    displayName: "Aeras One - Compressor",
    defaultThresholds: sharedDefaultThresholds,
  },
  {
    name: "aerasone_vacuum",
    displayName: "Aeras One - Vacuum",
    defaultThresholds: sharedDefaultThresholds,
  },
  {
    name: "compressor",
    displayName: "Compressor",
    defaultThresholds: [],
  },
  {
    name: "vacuum",
    displayName: "Vacuum",
    defaultThresholds: [],
  },
  {
    name: "chair",
    displayName: "Chair",
    supportedSignals: [],
    defaultThresholds: [],
  },
  {
    name: "handpiece",
    displayName: "Handpiece",
    supportedSignals: [],
    defaultThresholds: [],
  },
  {
    name: "sterilizer",
    displayName: "Sterilizer",
    supportedSignals: [],
    defaultThresholds: [],
  },
] as EquipmentType_option[];

const thresholdColumns = [
  {
    title: 'Sensor ID',
    dataIndex: 'key',
  },
  {
    title: 'Sensor',
    dataIndex: 'sensorName',
  },
  {
    title: 'Units',
    dataIndex: 'suffix',
  },
  {
    title: 'Minor Lower',
    dataIndex: 'minorLower',
    editable: true,
  },
  {
    title: 'Major Lower',
    dataIndex: 'majorLower',
    editable: true,
  },
  {
    title: 'Minor Upper',
    dataIndex: 'minorUpper',
    editable: true,
  },
  {
    title: 'Major Upper',
    dataIndex: 'majorUpper',
    editable: true,
  },
  {
    title: 'Duration (Lower)',
    dataIndex: 'durationLower',
    editable: true,
  },
  {
    title: 'Duration (Upper)',
    dataIndex: 'durationUpper',
    editable: true,
  }
];

const modelIdDoesntExist = async (rule: any, value: string) => {
  let model = await getModel(value);
  if (model) {
    throw new Error(`Model already exists with ID ${value}`);
  }
}

interface IProps {
  sState: AppState;
  model?: Model;
  backText: string;
  editingModel: boolean;
  goToPage?: number;
};

type InputType = "dropdown" | "text" | "autocomplete" | "textarea" | "image" | "boolean";
interface TDisplay {
  title?: string;
  var: string;
  canDisplay?: () => boolean;
  transform?: (value: string) => string;
  options?: GetFieldDecoratorOptions;
  data?: () => string[];
  opts?: Opt[];
  type: InputType;
  value?: string;
  label?: string;
  disabled?: boolean;
  suffixIcon?: ReactNode;
  completions?: DataSourceItemType[];
  rows?: number;
  className?: string;
};

function validHeads(equipmentType: string): number[] {
  switch (equipmentType) {
    case "aerasone_compressor":
    case "compressor":
      return [1, 2, 3];
    case "aerasone_vacuum":
    case "vacuum":
    case "handpiece":
      return [1];
    default:
      return [0];
  }
}

const EditModelImage: React.FC<{
  model: Model,
  imagePath: string,
  onChange: (filename?: string) => void,
}> = props => {

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  const onRemove = () => {
    onChange(undefined);
  };

  const { model, imagePath, onChange } = props;

  const upload = async (file: RcFile) => {
    setError('');
    let result = await uploadModelImage(file);
    // hacky - to implement custom upload, antd docs suggest
    // using beforeUpload and returning false or a rejected Promise
    // from it to prevent the "action" from being called. leaving
    // "action" undefined just results in the dragger posting the
    // file to the current URL. so we either throw a bogus error
    // here, or hit a 404.
    if (typeof (result) === 'string' || !result.data.success) {
      const error = (typeof (result) === 'string') ? result : result.data.error;
      setError(error || 'upload failed');
      throw Error(`Upload failed: ${error}`);
    } else {
      onChange(result.data.filename);
      throw Error(`Upload succeeded`);
    }
  };

  const image = get(model, imagePath, undefined) as string;
  if (image) {
    const fullPath = `${getConfig().model_photos}${image}`;
    return (
      <div
        className={'image-container'}
      >
        <img src={fullPath} alt={'Model'} onLoad={(() => { setLoading(false); })} />
        <Icon className='loading-icon' type="loading" style={{ opacity: loading ? 1 : 0 }} />
        <div onClick={onRemove} className='delete-button'>
          <Icon
            className='delete-icon'
            onClick={onRemove}
            style={{ fontSize: '24px', color: colors.white, backgroundColor: colors.highlight }}
            type='delete'
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Dragger
          name='image'
          multiple={false}
          showUploadList={false}
          accept=".png,.jpg,.jpeg,image/jpeg,image/png,image/jpg"
          beforeUpload={upload}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag image to this area to upload
          </p>
          <p className="ant-upload-error">
            {error}
          </p>
        </Dragger>
      </div>
    );
  }
};

interface EditModelComponentProps {
  sState: AppState;
}

const _EditModelComponent: React.FC<
  RouteComponentProps & FormComponentProps & EditModelComponentProps
> = props => {

  const emptyModel: Model = {
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    id: '',
    isActive: 1,
    type: 'compressor',
    family: '',
    manufacturer: '',
    name: '',
    image: '',
    images: {},
    heads: 1,
    thresholds: {},
    description: '',
    infoRows: DefaultInfoRows('compressor'),
  };

  const { form, sState } = props;

  const {
    model,
    backText,
    editingModel,
    goToPage,
  } = props.location.state as IProps;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState<boolean>(false);
  const [workingModel, setWorkingModel] = useState<Model>(model || emptyModel);
  const [models, setModels] = useState<Model[]>([]);
  const [editingThresholds, setEditingThresholds] = useState<boolean>(false);
  const [thresholdsEdited, setThresholdsEdited] = useState<boolean>(false);
  const [showEditInstructions, setShowEditInstructionsModal] = useState<boolean>(goToPage !== undefined);

  // hard-coded for four-page instructions for now

  const isAerasOne = useMemo(
    () => {
      return workingModel ? workingModel.type.includes('aerasone') : false;
    }, [workingModel]
  );

  if (isAerasOne && (!workingModel.instructions || workingModel.instructions.length !== 4)) {
    setWorkingModel({ ...workingModel, instructions: defaultInstruction(workingModel.type) });
  }

  const showVersions = useMemo(
    () => {
      return ['6400'].includes(get(workingModel, 'id', ''));
    }, [workingModel]
  );

  const manufacturers = useMemo(
    () => {
      const allMfrs = models.map(m => m.manufacturer || '');
      return uniq(allMfrs).filter(m => m.length > 0);
    },
    [models]
  );

  const families = useMemo(
    () => {
      const allFamilies = models.map(m => m.family || '');
      return uniq(allFamilies).filter(m => m.length > 0);
    },
    [models]
  );

  const formData: TDisplay[] = useMemo(() => {
    return [
      {
        title: 'Model ID',
        var: 'id',
        type: 'text',
        disabled: editingModel,
        options: {
          initialValue: workingModel.id || '',
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Model ID is required',
            },
            {
              max: 15,
              message: 'Model ID is limited to 15 characters.',
            },
            ...editingModel ? [] : [{
              validator: modelIdDoesntExist,
              message: 'This model ID is already in use - please enter a new model ID.',
            }]
          ],
        },
      },
      {
        title: 'Equipment Type',
        var: 'type',
        type: 'dropdown',
        opts: equipmentTypes.map(t => ({ label: t.displayName, value: t.name })),
        options: {
          initialValue: workingModel.type,
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Equipment type is required',
            },
          ],
        },
      },
      {
        title: 'Model Name',
        var: 'name',
        type: 'text',
        options: {
          initialValue: workingModel.name,
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Model name is required',
            },
            {
              max: 75,
              message: 'Name is limited to 75 characters.',
            }
          ],
        },
      },
      {
        title: 'Manufacturer',
        var: 'manufacturer',
        type: 'autocomplete',
        options: {
          initialValue: workingModel.manufacturer,
          validateTrigger: 'onBlur',
          rules: [
            {
              required: true,
              message: 'Manufacturer is required.',
            },
            {
              max: 30,
              message: 'Manufacturer is limited to 30 characters.',
            },
            {
              min: 1,
              message: 'Manufacturer is required.',
            }
          ]
        },
        completions: manufacturers,
        transform: uppercaseWords,
      },
      {
        title: 'Family',
        var: 'family',
        type: 'autocomplete',
        options: {
          initialValue: workingModel.family,
          validateTrigger: 'onBlur',
          rules: [
            {
              max: 30,
              message: 'Family is limited to 30 characters.',
            },
          ]
        },
        completions: families,
        transform: uppercaseWords,
      },
      {
        title: 'Heads',
        var: 'heads',
        type: 'dropdown',
        opts: validHeads(workingModel.type).map(h => ({ value: h, label: h })),
        canDisplay: () => { return validHeads(workingModel.type).length > 1; },
        options: {
          initialValue: workingModel.heads,
          validateTrigger: 'onBlur',
          rules: [
            {
              required: workingModel.type === 'aerasone_compressor',
              message: '# of heads is required for compressors',
            },
          ],
        },
      },
      {
        title: 'Description',
        var: 'description',
        type: 'textarea',
        className: 'form-item-wide',
        options: {
          initialValue: workingModel.description,
          rules: [
            {
              max: 500,
              message: 'Description is limited to 500 characters.',
            }
          ]
        },
        rows: 3,
      },
      {
        title: 'Control Board Hardware',
        var: 'control_board_hw',
        type: 'text',
        canDisplay: () => showVersions,
        options: {
          initialValue: workingModel.control_board_hw,
          validateTrigger: 'onChange',
          rules: versionRules,
        },
      },
      {
        title: 'Control Board Firmware',
        var: 'control_board_fw',
        type: 'text',
        canDisplay: () => showVersions,
        options: {
          initialValue: workingModel.control_board_fw,
          validateTrigger: 'onChange',
          rules: versionRules,
        },
      },
      {
        title: 'Daughterboard Firmware Version',
        var: 'fw_version',
        type: 'text',
        canDisplay: () => showVersions,
        options: {
          initialValue: workingModel.fw_version,
          validateTrigger: 'onChange',
          rules: versionRules
        },
      },
      {
        title: 'Show on Manufacturing',
        var: 'showOnManu',
        type: 'boolean',
        canDisplay: () => !isAerasOne,
        options: {
          initialValue: !!workingModel.showOnManu,
        },
      },
      {
        title: 'Model Image',
        var: 'image',
        type: 'image',
      },
    ];
  }, [workingModel, families, manufacturers, editingModel, isAerasOne, showVersions]);

  const goBack = useCallback((force: boolean = false) => {
    const onOk = () => {
      props.history.goBack();
    }
    const onCancel = () => null;

    if (force) {
      onOk();
    } else {
      Modal.confirm({
        title: 'Navigation Confirmation',
        content: `Are you sure you want to navigate away from ${editingModel ? 'Edit' : 'Add'
          } Model? The ${editingModel ? '' : 'new '}model will not be ${editingModel ? 'saved' : 'created'
          } and any progress will be lost.`,
        onOk,
        onCancel,
        okText: 'Leave',
      });
    }
  }, [props.history, editingModel]);

  const hasFieldError = useCallback((): boolean => {
    const errors = form.getFieldsError();
    const hasErrors = (x: any) => {
      for (let key in x) {
        if (typeof (x[key]) == 'object') {
          if (hasErrors(x[key])) {
            return true;
          }
        } else if (x[key]) {
          return true;
        }
      }
      return false;
    };
    if (!errors) {
      return false;
    }
    return hasErrors(errors);
  }, [form]);

  const onChange = useCallback(
    (value: SelectValue | string | number | boolean | undefined, variable: string) => {
      const oldHeads = validHeads(workingModel.type as EquipmentType);
      set(workingModel, variable, value);

      if (variable === 'type') {
        // If equipment type has changed, reinitialize dependent parameters with defaults
        if (value === undefined || value === '') {
          workingModel.type = 'compressor';
        } else {
          let et = find(equipmentTypes, et => et.name === value, undefined);
          if (et) {
            workingModel.thresholds = et.defaultThresholds;
            workingModel.instructions = defaultInstruction(value as EquipmentType);
            workingModel.infoRows = DefaultInfoRows(et.name);
          }
        }

        const newValidHeads = validHeads(workingModel.type as EquipmentType);
        if (!isEqual(oldHeads, newValidHeads)) {
          workingModel.heads = newValidHeads[0];
        }

        switch (workingModel.type as EquipmentType) {
          case "aerasone_compressor":
          case 'aerasone_vacuum':
          case 'handpiece':
          case 'chair':
            workingModel.showOnManu = false;
            break;
          case 'compressor':
          case 'sterilizer':
          case 'vacuum':
            workingModel.showOnManu = true;
            break;
        }

      }

      setWorkingModel({ ...workingModel });
    },
    [workingModel]
  );

  const onSave = useCallback(
    async (e: MouseEvent | FormEvent) => {
      const { validateFields } = form;
      e.preventDefault();
      if (loading || !workingModel) {
        return;
      }

      try {
        setSaving(true);

        let validated = await new Promise((resolve, reject) => {
          try {
            validateFields((errors, values) => {
              resolve(!errors);
            });
          }
          catch (err) {
            console.error(err);
            resolve(false);
          }
        });

        if (!validated) return;

        formData.forEach(j => {
          const val = get(workingModel, j.var, undefined);
          if (typeof (val) === 'string' && j.transform) {
            set(workingModel, j.var, j.transform(val));
          }
        });

        const result = await updateModel(workingModel);
        if (thresholdsEdited) {
          syncModelThresholds(workingModel);
        }
        props.history.replace('/dashboard/manageModel', {
          model: result,
        });
      } catch (err) {
        setError(err.Message);
      } finally {
        setSaving(false);
      }
    }, [workingModel, loading, props.history, formData, thresholdsEdited, form]
  );


  const allSensors = getSensorsForEquipmentType(workingModel.type, sState);

  interface thresholdRow {
    key: string,
    sensorName: string,
    minorLower?: number;
    majorLower?: number;
    minorUpper?: number;
    majorUpper?: number;
    durationLower?: number;
    durationUpper?: number;
    suffix: string;
  };

  const thresholdData: thresholdRow[] = allSensors
    .map(sensor => {
      const sensorId = sensor.id;
      const thresh = get(workingModel.thresholds, sensorId, undefined) as Threshold | undefined;

      const row = {
        key: sensorId,
        sensorName: get(sensor, 'name', ''),
        suffix: get(sensor, 'suffix', ''),
      } as thresholdRow;

      if (thresh) {
        row.majorLower = thresh.majorLower;
        row.minorLower = thresh.minorLower;
        row.minorUpper = thresh.minorUpper;
        row.majorUpper = thresh.majorUpper;
        row.durationLower = thresh.durationLower;
        row.durationUpper = thresh.durationUpper;
      }

      return row;
    }).sort((a, b) => sortByKey(a, b, 'sensorName'));

  const renderForm = useCallback(() => {
    const { getFieldDecorator } = form;

    const renderFormElement = (item: TDisplay, key: number) => {
      const itemOptions = item.options;
      const canDisplay = item.canDisplay ? item.canDisplay() : true;
      if (!canDisplay) return null;
      return (
        <Form.Item key={key} className={item.className} label={item.title}>
          <div>
            {getFieldDecorator(item.var, itemOptions)(
              item.type === 'dropdown' && item.opts ? (
                <Select
                  suffixIcon={item.suffixIcon}
                  disabled={item.disabled}
                  onChange={(value: SelectValue) => onChange(value, item.var)}
                >
                  {item.opts.map((opt, i) => (
                    <Select.Option key={i} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              ) : (item.type === 'autocomplete') ? (
                <AutoComplete
                  onChange={(value: SelectValue) => onChange(value, item.var)}
                  placeholder={item.title}
                  dataSource={item.completions}
                  filterOption={(inputValue, option) =>
                    option.props.children!.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
              ) : (item.type === 'boolean') ? (
                <Switch
                  checked={!!workingModel.showOnManu}
                  onChange={(checked: boolean, event: MouseEvent) => onChange(checked, item.var)}
                />
              ) : (item.type === 'textarea') ? (
                <TextArea
                  rows={item.rows || 3}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value, item.var)}
                />
              ) : (item.type === 'image') ? (
                <EditModelImage
                  model={workingModel}
                  imagePath={item.var}
                  onChange={(filename?: string) => { onChange(filename, item.var); }}
                />
              ) : (
                <Input
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value, item.var)}
                  disabled={item.disabled}
                />
              )
            )}
          </div>
        </Form.Item>
      );
    };

    const onThresholdChanged = async (newData: thresholdRow[], key: string, index: number) => {
      // newData is entire array of thresholdRows passed in
      // key is key of changed row, index is index of changed row in newData
      const newThreshold = newData[index];
      set(workingModel, `thresholds.${key}`, {
        minorLower: newThreshold.minorLower,
        minorUpper: newThreshold.minorUpper,
        majorLower: newThreshold.majorLower,
        majorUpper: newThreshold.majorUpper,
        durationLower: newThreshold.durationLower,
        durationUpper: newThreshold.durationUpper,
      });
      setWorkingModel({ ...workingModel });
      setThresholdsEdited(true);
    };

    const renderThresholds = () => {
      if (!isAerasOne) return null;
      // no type signature for EditableFormTable props so we bypass type checking by doing this
      const props = {
        dataSource: thresholdData,
        columns: thresholdColumns,
        loading,
        inputType: 'number',
        isRequired: false,
        onSave: onThresholdChanged,
        setEditing: (isEditing: boolean) => setEditingThresholds(isEditing),
      } as any;
      return (
        <div className='thresholds-panel'>
          <div className='ant-form-item-label' style={{ marginLeft: '5px' }}>Alert Thresholds</div>
          <EditableFormTable id='thresholdstable' {...props} />
        </div>
      );
    };

    const mainFields = formData.filter(item => item.type !== 'image');
    const singleColumnFields = mainFields.filter(item => item.className !== 'form-item-wide');
    const wideFields = mainFields.filter(item => item.className === 'form-item-wide');
    const imageFields = formData.filter(item => item.type === 'image');
    return (
      <Form layout="vertical" onSubmit={onSave}>
        <div className='panels-grid'>
          <div className='left-panel panel'>
            {imageFields.map(renderFormElement)}
            {(workingModel.images && workingModel.images.chairMount0) && (
              <div>
                <label className='chairmount-label'>Chair Mount</label>
                <ModelImage className='image-container' model={workingModel} />
              </div>
            )
            }
          </div>
          <div className='right-panel panel'>
            {singleColumnFields.map(renderFormElement)}
            {wideFields.map(renderFormElement)}
            {isAerasOne && <Button
              className='edit-instructions-btn'
              title='Edit Installation Instructions'
              onClick={() => setShowEditInstructionsModal(true)}
            />}
            {isAerasOne && renderThresholds()}
          </div>
        </div>
      </Form>
    );
  }, [workingModel, form, formData, onChange, onSave, isAerasOne, loading, thresholdData]);


  useEffect(() => {
    const work = async () => {
      setLoading(true);
      setModels(await getModels());
      setLoading(false);
    };
    work();
  }, []);

  const renderEditModel = useCallback(() => {
    if (!workingModel) return null;

    const renderButtons = () => {
      const cancel = (
        <Button
          title={'Cancel'}
          disabled={loading}
          css={css`
            margin: 0 5px;
          `}
          onClick={() => {
            return goBack();
          }}
        />
      );

      const submitButton = (
        <Button
          title={'Save'}
          loading={saving || loading}
          css={css`
            margin: 0 5px;
          `}
          disabled={saving || loading || hasFieldError() || editingThresholds}
          onClick={onSave}
        />
      );

      return (
        <div css={css(SharedStyles.row, `margin-top: 15px; align-self: center;`)}>
          {cancel}
          {submitButton}
        </div>
      );
    };


    return [
      renderForm(),
      renderButtons(),
    ];
  }, [workingModel, renderForm, editingThresholds, goBack, hasFieldError, loading, onSave, saving]);

  const renderEditInstructions = useCallback(() => {
    if (!workingModel || !isAerasOne) return null;
    return <EditInstructions
      instructions={workingModel.instructions || []}
      initialPage={goToPage}
      onCancel={() => {
        if (goToPage !== undefined) {
          goBack(true);
        } else {
          setShowEditInstructionsModal(false);
        }
      }}
      onSave={(newInstructions) => {
        setWorkingModel({ ...workingModel, instructions: newInstructions });
        setShowEditInstructionsModal(false);
      }} />;
  }, [workingModel, goBack, goToPage, isAerasOne]);

  return (
    <div className='edit-model-container' css={css(styles.container)}>
      {!!backText && (
        <Link
          css={css`
            margin-bottom: 5px;
            margin-right: auto;
          `}
          onClick={() => goBack()}
        >
          {backText}
        </Link>
      )}
      {success && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="success"
          message={success}
          closable
          onClose={() => setSuccess(undefined)}
        />
      )}
      {error && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="error"
          message={error}
          closable
          onClose={() => setError(undefined)}
        />
      )}

      <div css={css(SharedStyles.row, styles.rowMargin)}>
        <TableTitleHeader>
          {editingModel ? 'Edit Model' : 'Add Model'}
          {loading && <Icon css={css('margin-left: 5px;')} type="loading" />}
        </TableTitleHeader>
      </div>

      {showEditInstructions ? renderEditInstructions() : renderEditModel()}
    </div>
  );
};

export const EditModelComponent = Form.create()(
  withRouter(_EditModelComponent)
);
