/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Input, Icon, Alert } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { AppState } from '../../app.state';
import {
  userHasRole,
  getMyOrg,
  sortByLabel,
  sortByLabel2,
  sortByKey,
  getTandemGroupsForUser,
  isPB,
} from '../../utils';
import { SelectValue } from 'antd/lib/select';
import { chain, get } from 'lodash';
import { Equipment } from '../../_shared/interfaces/equipment';
import { getLocations } from '../../_shared/services/manage-locations.service';
import { TandemGroup } from '../../_shared/interfaces/tandemGroup';
import { Button } from '../../_shared/button';
import colors from '../../_shared/colors';
import { Org } from '../../_shared/interfaces/org';
import { Location } from '../../_shared/interfaces/location';
import Link from '../../_shared/link';
import { getEquipments } from '../../_shared/services/manage-equipment.service';
import { ColumnProps } from 'antd/lib/table';
import { SelectLimited } from '../../_shared/autocomplete/autocomplete-limited';

interface IProps extends RouteComponentProps {
  sState: AppState;
}
interface IOpt {
  label: string;
  value: string | number;
}

const Columns = (_this: ManageTandemGroups): ColumnProps<TandemGroup>[] => {
  const myOrg = getMyOrg(_this.props.sState);
  const isSuper = userHasRole([0, 1], _this.props.sState);
  const canShowOrgCol = () => {
    if (isSuper || (myOrg && myOrg.orgType === 0)) {
      return true;
    }
    return false;
  };
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      defaultSortOrder: 'ascend',
      sorter: (a: TandemGroup, b: TandemGroup) => sortByKey(a, b, 'name'),
    },
    {
      title: 'Number',
      dataIndex: 'groupNumber',
      width: '12.5%',
    },
    ...canShowOrgCol() ? [{
      title: 'Organization',
      dataIndex: 'orgId',
      width: '15%',
      render: (text: string, record: TandemGroup) => {
        const orgs: Org[] = get(_this.state, 'orgs', []);
        const locations: Location[] = get(_this.state, 'allLocations', []);
        const location = locations.find(loc => loc.id === record.locationId);
        const orgId = get(location, 'orgId');
        const org = orgs.find((org: Org) => {
          return org.id === orgId;
        });
        return <span>{org ? org.name : ''}</span>;
      },
    }] : [],
    {
      title: 'Location',
      dataIndex: 'locationId',
      width: '15%',
      render: (text: string, record: TandemGroup) => {
        const allLocations: Location[] = get(_this.state, 'allLocations', []);
        const loc = allLocations.find((loc: Location) => {
          return loc.id === record.locationId;
        });
        return <span>{loc ? loc.name : ''}</span>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'equipType',
      width: '15%',
    },
    {
      title: 'Equipment',
      dataIndex: 'equipmentCount',
      width: '10%',
    },
    {
      title: 'Central',
      dataIndex: 'central',
      width: '12.5%',
      sorter: (a: TandemGroup, b: TandemGroup) => sortByKey(a, b, 'central'),
      render: (text: string, record: TandemGroup) => {
        const allEquipment: Equipment[] = get(_this.state, 'allEquipment', []);
        const equipment: Equipment | undefined = allEquipment.find(
          (equip: Equipment) => {
            return equip.id === record.central;
          }
        );
        return (
          <span>
            {equipment ? equipment.equipmentSN || equipment.name : ''}
          </span>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '5%',
      render: (text: string, record: TandemGroup) => {
        const canShow = userHasRole([0, 1, 2, 3], _this.props.sState);

        return !canShow ? null : (
          <Icon
            onClick={tg => {
              tg.preventDefault();
              tg.stopPropagation();
              _this.editTandemGroup(record);
            }}
            type={'edit'}
            css={css(`color: ${colors.highlight};`)}
          />
        );
      },
    },
  ];
};

class ManageTandemGroups extends Component<IProps> {
  state = {
    columns: [...Columns(this)],
    loading: true,
    success: null,
    error: null,
    data: [],
    tandemGroups: [],
    orgs: [],
    allLocations: [],
    searchValue: null,
    allEquipment: [],
    show: true,
    showFilters: true,
    dentalOrgs: {
      canDisplay: () => {
        const isSuper = userHasRole([0, 1], this.props.sState);
        const myOrg = getMyOrg(this.props.sState);

        if (isSuper) {
          return true;
        } else {
          if (myOrg && myOrg.orgType === 1) {
            return false;
          } else if (myOrg && myOrg.orgType === 0) {
            return true;
          } else {
            return false;
          }
        }
      },
      opts: this.props.sState.dash.orgs
          .filter(o => o.orgType === 1)
          .map(org => ({
            label: org.name,
            value: org.id,
          }))
          .sort(sortByLabel),
      selected: [] as string[],
    },
    locations: {
      canDisplay: () => true,
      opts: (() => {
        const isSuper = userHasRole([0, 1], this.props.sState);
        const opts = get(this.props.sState, 'dash.locations', [])
          .map((m: Org) => ({
            label: m.name,
            value: m.id,
            orgId: m.orgId,
          }))
          .sort(sortByLabel);

        if (isSuper) {
          return opts;
        } else {
          const orgIds: string[] = [];
          get(this.props.sState, 'dash.orgs', []).map((o: Org) => {
            orgIds.push(o.id);
            if (o.serviceOrgId) {
              orgIds.push(o.serviceOrgId);
            }
          });
          let locs = get(this.props.sState, 'auth.user.locations', []);
          if (!Array.isArray(locs)) {
            locs = [];
          }
          return opts
            .filter(
              (o: IOpt & { orgId: string }) =>
                locs.indexOf(o.value) !== -1 || orgIds.indexOf(o.orgId) !== -1
            )
            .sort(sortByLabel2);
        }
      })(),

      selected: [] as string[],
    },
  };

  componentDidMount = () => {
    const { state } = this.props.history.location;
    const { sState } = this.props;
    const ispb = isPB(sState);

    if (ispb) {
      this.setState({ showFilters: false });
    }

    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    const org = get(state, 'org');

    if (org) {
      const name = org.orgType === 0 ? 'serviceOrgs' : 'dentalOrgs';
      return this.setState(
        {
          show: false,
          [name]: {
            ...get(this.state, name),
            selected: [org.id],
          },
        },
        this.retrieveGroups
      );
    }
    if (get(state, 'locationId')) {
      return this.setState(
        {
          show: false,
          locations: {
            ...get(this.state, 'locations'),
            selected: get(state, 'locationId'),
          },
        },
        this.retrieveGroups
      );
    }
    this.retrieveGroups();
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (
      get(prevProps, 'sState.dash.view.isPhoneBreak') !==
      get(this.props, 'sState.dash.view.isPhoneBreak')
    ) {
      const { sState } = this.props;
      const ispb = isPB(sState);
      if (ispb) {
        this.setState({ showFilters: false });
      } else {
        this.setState({ showFilters: true });
      }
    }
  };

  fireSearch = (value: string) => {
    this.setState({ searchValue: value }, () => this.retrieveGroups(false));
  };
  retrieveGroups = async (shouldFetch = true) => {
    const state = { ...this.state };
    const { searchValue, dentalOrgs, locations } = state;
    const { sState } = this.props;

    this.setState({ loading: true });
    try {
      const allLocations = shouldFetch
        ? await getLocations()
        : sState.dash.locations;

      const orgs = sState.dash.orgs;
      const allEquipment = await getEquipments();
      const tandemGroups: TandemGroup[] = await getTandemGroupsForUser(
        sState,
        allEquipment
      );
      const sterm = (searchValue || '').toLowerCase();

      const orgMap = chain(orgs)
        .keyBy('id')
        .value();

      const locMap = chain(allLocations)
        .keyBy('id')
        .value();

      const data = [...tandemGroups]
        .map(tg => {
          const equipForTGroup = allEquipment.filter(
            equip => equip.tandemGroupId === tg.id
          );
          const centralEq = equipForTGroup.find(
            equip => equip.tandemGroupRole === 'central'
          );
          const centralId = get(centralEq, 'id');
          const location = locMap[tg.locationId];
          const orgId = get(location, 'orgId');
          return {
            ...tg,
            _tandemGroup: { ...tg },
            key: tg.id,
            org: get(orgMap, `${orgId}`),
            location: get(locMap, `${tg.locationId}`),
            equipmentCount: equipForTGroup.length || 0,
            central: centralId,
          };
        })
        .filter(tg => {
          let passes = true;
          const location = locMap[`${tg.locationId}`];
          const orgId = get(location, 'orgId');
          if (searchValue) {
            const tgName = get(tg, 'name', '').toLowerCase();
            if (tgName.indexOf(sterm) === -1) {
              passes = false;
            }
          }
          if (dentalOrgs.selected.length > 0) {
            if (!orgId || dentalOrgs.selected.indexOf(orgId) === -1) {
              passes = false;
            }
          }
          if (locations.selected.length > 0) {
            if (
              !tg.locationId ||
              locations.selected.indexOf(tg.locationId) === -1
            ) {
              passes = false;
            }
          }
          return passes;
        });
      const columns = Columns(this);

      this.setState({
        tandemGroups: tandemGroups,
        orgs: orgs,
        allLocations: allLocations,
        data: data,
        allEquipment: allEquipment,
        columns,
        loading: false,
        show: true,
      });
    } catch (err) {
      console.warn(err);
      this.setState({
        loading: false,
        error: err.message,
      });
    }
  };

  editTandemGroup = (
    tandemGroup: TandemGroup | undefined,
    editMode = true,
    isAdd = false
  ) => {
    this.props.history.push('/dashboard/editTandemGroup', {
      tandemGroup,
      editMode,
      isAdd,
      back: `< Back To Tandem Groups`,
    });
  };
  manageTandemGroup = (tandemGroup: TandemGroup) => {
    this.props.history.push('/dashboard/manageTandemGroup', {
      tandemGroup,
    });
  };
  selectTandemGroup = (
    record: { _tandemGroup: TandemGroup },
    index: number
  ) => {
    this.manageTandemGroup(record._tandemGroup);
  };
  selectedRowsChange = (keys: string[] | number[]) => {
    this.setState({
      selectedRows: keys,
    });
  };

  changeSelect = (item: 'dentalOrgs' | 'locations', value: SelectValue) => {
    this.setState(
      { [item]: { ...this.state[item], selected: [...(value as string[])] } },
      () => this.retrieveGroups(false)
    );
  };

  render() {
    const {
      columns,
      data,
      loading,
      dentalOrgs,
      error,
      success,
      locations,
      show,
      showFilters,
    } = this.state;
    const { sState } = this.props;
    const ispb = isPB(sState);

    return (
      <div>
        {error && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="error"
            message={error}
            closable
            onClose={() => this.setState({ error: null })}
          />
        )}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}
        <TableListHeader style={ispb && SharedStyles.pbFilterContainer}>
          <div
            css={css(SharedStyles.row, ispb ? `width: 100%` : `width: 50%;`)}
          >
            <Input.Search
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 70%;
                      margin-right: 10px;
                    `
              }
              placeholder="Search by name..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />
            <Button
              css={ispb && css(`display: none;`)}
              loading={loading}
              title="Refresh"
              type="link"
              onClick={() => this.retrieveGroups(true)}
            />
          </div>
          <div css={ispb && css(SharedStyles.pbButtonContainer)}>
            <Button
              title={'Add Tandem Group'}
              onClick={() => this.editTandemGroup(undefined, false, true)}
            />
            <div css={!ispb && css(SharedStyles.hidden)}>
              <Link
                css={css(SharedStyles.row)}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                {`${showFilters ? 'Hide Filters' : 'Show Filters'}`}
                <Icon
                  css={css('margin-left: 5px;')}
                  type={showFilters ? 'up' : 'down'}
                />
              </Link>
            </div>
          </div>
        </TableListHeader>

        <TableListHeader
          style={
            ispb
              ? SharedStyles.pbFilterContainer
              : `justify-content: flex-start;`
          }
        >
          {dentalOrgs.canDisplay() && show && showFilters && (
            <SelectLimited 
              mode="multiple"
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              defaultValue={(dentalOrgs.selected as unknown) as string}
              placeholder={'Select Dental Orgs'}
              onChange={value => this.changeSelect('dentalOrgs', value)}
              data={dentalOrgs.opts}
              limit={100}
              showArrow={true}
            />
          )}
          {locations.canDisplay() && show && showFilters && (
            <SelectLimited
              css={
                ispb
                  ? css(SharedStyles.pbFilterStyle)
                  : css`
                      width: 20%;
                      margin-right: 10px;
                    `
              }
              mode="multiple"
              defaultValue={(locations.selected as unknown) as string}
              placeholder={'Select Locations'}
              onChange={value => this.changeSelect('locations', value)}
              data={locations.opts}
              showArrow={true}
              limit={100}
            />
          )}
        </TableListHeader>

        <TableList
          canSelectRows={false}
          loading={loading}
          columns={columns}
          data={data}
          rowClick={this.selectTandemGroup}
          includeCount="Tandem Groups Count"
        />
      </div>
    );
  }
}

export const ManageTandemGroupsComponent = withRouter(ManageTandemGroups);
