/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { Component, createRef } from 'react';
import { Modal, Icon, Select, DatePicker, Checkbox, Skeleton } from 'antd';
import { Equipment } from '../interfaces/equipment';
import { Sensor } from '../interfaces/sensor';
import { get, set, size, reverse, sortBy, PropertyPath, flatten, unset } from 'lodash';
import { Publish } from '../interfaces/publish';
import Chart from 'chart.js';
import SharedStyles from '../styles';
import colors from '../colors';
import {
  convertHex,
  sortPublishByTimeOrDate,
  getEquipSensors,
  getEquipThresholds,
  singleOrAverage,
  findLatestPublish,
  equipIsMetric,
  metricConvert,
  isPB,
  getChartValues,
  getAvg,
  getAllDeviceIds,
} from '../../utils';
import moment, { Moment } from 'moment';
import styles from './modal-chart.styles';
import { connect } from 'react-redux';
import { AppState } from '../../app.state';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { eventTypes, trackEvent } from '../services/analytics.service';
import { timeText } from '../../dashboard/equipment-details/equipment-details-columns';
import { getHistory } from '../services/device-history.service';
import { addToMap } from '../../dashboard/dashboard.actions';

interface IProps {
  sState: AppState;
  visible?: boolean;
  chosenSensor?: string;
  equipment?: Equipment;
  onClose?: () => void;
  loading: boolean;
  //tslint:disable-next-line:no-any
  latestSensorInfo: any;
  deviceId: string;
  alerts?: Publish[];
  isTime?: {};
  headerCols?: ICol[];
  addToMap: (arr: Publish[], opts: {}) => void;
}

interface ICol {
  title: string;
  val: () => string;
  visible?: () => boolean;
}

interface IState {
  hasAll: boolean;
  sensorMap: {};
  chartDataModels: IDataset[];
  filterMap: { [key: string]: boolean };
  date: {
    selected: string;
    start: Moment;
    end: Moment;
  };
}

interface IDataset {
  label: string;
  //tslint:disable-next-line:no-any
  data: any;
  backgroundColor: string;
  pointBorderColor?: string;
  borderColor: string;
  borderWidth: number;
  hidden: boolean;
  show?: boolean;
}

enum StatisticsType {
  DAILY = 0,
  HOURLY = 1,
  DEBUG = 2,
}

interface _Point {
  x: Date | Moment;
  y: number;
  vals?: number[];
}

interface SensorItem {
  min: _Point[];
  max: _Point[];
  hmin: _Point[];
  hmax: _Point[];
  dailyAverage: _Point[];
  hourlyAverage: _Point[];
  total: _Point[];
  alertData: _Point[];
  instants: _Point[];
  threshHigh: _Point[];
  threshLow: _Point[];
}
type SensorMap = { [key: string]: SensorItem };

const createSensorMapItem = (): SensorItem => {
  return {
    min: [] as _Point[],
    max: [] as _Point[],
    hmin: [] as _Point[],
    hmax: [] as _Point[],
    total: [] as _Point[],
    dailyAverage: [] as _Point[],
    hourlyAverage: [] as _Point[],
    alertData: [] as _Point[],
    instants: [] as _Point[],
    threshHigh: [] as _Point[],
    threshLow: [] as _Point[],
  };
};

const dateoptions = [
  { title: '1 day', value: '1_day', days: 1 },
  { title: '1 week', value: '1_week', days: 7 },
  { title: '1 month', value: '1_month', days: 30 },
  { title: '3 months', value: '3_month', days: 90 },
  { title: '6 months', value: '6_month', days: 180 },
  { title: 'Custom', value: 'custom_date', days: Infinity },
];

// tslint:disable-next-line: no-any
let chart: any;
export class _ModalChart extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      hasAll: false,
      sensorMap: {},
      chartDataModels: [],
      filterMap: {},
      date: {
        selected: '1_month',
        start: moment().subtract(1, 'months'),
        end: moment(),
      },
    };
  }
  // tslint:disable-next-line: no-any
  chartRef: any = createRef();
  close = () => {
    const { onClose } = this.props;
    set(this.state, 'date.selected', '1_month');
    set(this.state, 'date.start', moment().subtract(1, 'months'));
    set(this.state, 'date.end', moment());
    set(this.state, 'filterMap', {});
    onClose && onClose();
  };

  getAllData = async () => {
    if (!this.state.hasAll) {
      const eq = this.props.equipment;
      const allDeviceIds = getAllDeviceIds(eq);

      const eventsToGet = [
        'dz_sensor_statistics',
      ];

      const allHistory = flatten(
        await Promise.all(
          allDeviceIds.map(
            async ids =>
              await Promise.all(
                eventsToGet.map(
                  async e => await getHistory(ids.id, e, ids.replaceDate, 5000)
                )
              )
          )
        )
      );

      const flat = flatten(allHistory).map(p => ({
        ...p,
        coreid: get(allDeviceIds, [0, 'id']),
      })) as Publish[];

      this.props.addToMap(flat, {
        clear: ['sensorStatistics'],
      });
    }
    this.setState({ hasAll: true, date: { ...this.state.date, ...get(this, 'props.date', {}) } });
  }
  componentDidUpdate = async (prevProps: IProps) => {
    if (this.props.visible && !prevProps.visible) {
      await this.getAllData();
      this.buildMap();
    }
    if (get(this, 'props.equipment.deviceId') !== get(prevProps, 'equipment.deviceId')) {
      this.setState({ hasAll: false });
    }
  };
  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>): boolean {
    return !![
      [this.props.visible, nextProps.visible], 
      [get(this, 'props.equipment.deviceId'), get(nextProps, 'equipment.deviceId')],
      [this.state.sensorMap, nextState.sensorMap],
      [this.state.filterMap, nextState.filterMap],
      [this.state.date, nextState.date],
      [this.state.chartDataModels, nextState.chartDataModels]
    ].find(f => f[0] != f[1]);
  }

  renderHeader = () => {
    const { chosenSensor } = this.props;
    const { sState, equipment } = this.props;
    const ispb = isPB(sState);
    const deviceId = get(equipment, 'deviceId');
    const sensor = this.getSensorFromSState();

    const isMetric = equipIsMetric(equipment, sState);

    //get all sensor statistics for the device
    const sensors = get(
      sState,
      `dash.equipMap.${deviceId}.sensorStatistics`,
      []
    );

    if (sensors.length <= 0) return null;

    //get the latest daily publish for the chosen sensor
    const daily = sensors.filter(
      (s: Publish) =>
        get(s, 'data.daily') || get(s, 'data.statistics_type') == 0
    );
    const latestDailySensorInfo = findLatestPublish(daily as Publish[]);
    let latestDailyChosenSensor: any = get(
      latestDailySensorInfo,
      `data.${chosenSensor}`,
    );

    if (!!latestDailyChosenSensor) {
      const diff =
        moment().diff(moment(get(latestDailySensorInfo, 'published_at', '')), 'hours') <=
        24;
      if (!diff) {
        latestDailyChosenSensor = undefined;
      }
    }

    //some data gets saved this way because dynamodb can't handle regular nulls.
    if (
      latestDailyChosenSensor === 'null' ||
      !latestDailyChosenSensor
    ) {
      const now = moment();
      const obj = {
        min: Infinity,
        max: -Infinity,
        avg: [] as number[],
      };
      sensors
        .filter((s: Publish) => now.diff(moment(s.published_at), 'hours') <= 24)
        .map((s: Publish) => {
          const d = get(s, `data.${chosenSensor}`);

          if (!!d && Array.isArray(d)) {
            const [min, max, avg] = d;
            if (min < obj.min) {
              obj.min = min;
            }
            if (max > obj.max) {
              obj.max = max;
            }
            obj.avg.push(avg);
          } else if (typeof d === 'number') {
            if (d < obj.min) {
              obj.min = d;
            }
            if (d > obj.max) {
              obj.max = d;
            }
            obj.avg.push(d);
          }
        });

      latestDailyChosenSensor = [
        obj.min >= Infinity ? undefined : obj.min.toFixed(1),
        obj.max <= Infinity ? undefined : obj.max.toFixed(1),
        getAvg(obj.avg).toFixed(1),
      ];
    }

    //Latest instant data from equip-details
    const lastInstant = get(this.props, 'latestSensorInfo', null);

    //make sure data is there
    const valueText = (
      val: string | number | undefined | null,
      ifVal: string,
      ifNull = undefined
    ) => {
      if (val === '--' || (val || '').toString().includes('/')) {
        return val;
      }
      const v = singleOrAverage(val);

      if (this.props.isTime) {
        return timeText(v, this.props.isTime);
      }

      return typeof v !== 'boolean' && (v === null || isNaN(v))
        ? ifNull
        : ifVal;
    };

    const na = 'N/A';

    let cols = get(this, 'props.headerCols', [
      {
        title: (() => get(this, 'props.currentReadingTitle', 'Current Reading'))(),
        val: () => {
          const val = valueText(lastInstant, `${singleOrAverage(lastInstant)}`);
          if (chosenSensor === 'AMG') {
            return val === '0' ? 'Working' : val === '1' ? 'Full' : na;
          }
          return val ? val + this.getSensorSuffix() : na;
        },
      },
      {
        title: 'Min value within last 24 hours',
        visible: () => get(sensor, 'showMin', true),
        val: () => {
          const val = valueText(
            get(latestDailyChosenSensor, 0, null),
            `${singleOrAverage(get(latestDailyChosenSensor, 0, null))}`
          );
          return val ? val + this.getSensorSuffix() : na;
        },
      },
      {
        title: 'Max value within last 24 hours',
        visible: () => get(sensor, 'showMax', true),
        val: () => {
          const val = valueText(
            get(latestDailyChosenSensor, [1], null),
            `${singleOrAverage(get(latestDailyChosenSensor, [1], null))}`
          );
          return val ? val + this.getSensorSuffix() : na;
        },
      },
      //@ts-ignore
    ]).filter(f => !!get(f, 'visible', () => true)()) as ICol[];

    return (
      <div css={css(SharedStyles.row, styles.headerWrap)}>
        {cols.map((c, i) => {
          return (
            <div
              key={i}
              css={css(
                ispb ? styles.pbHeaderCol : styles.headerCol,
                `width: ${100 / cols.length}%;`
              )}
            >
              <div css={css(ispb && `padding: 0 3px; text-align: center;`)}>
                {c.title}
              </div>
              <div css={css(styles.headerColVal)}>
                {metricConvert(c.val(), isMetric)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  getSensorFromMap = () => {
    const { chosenSensor } = this.props;
    return get(this.state.sensorMap, chosenSensor || '', createSensorMapItem());
  };
  getSensorFromSState = () => {
    const { sState, chosenSensor } = this.props;

    const sensor = get(sState, `dash.sensors`, []).find(
      (s: Sensor) => s.id === chosenSensor
    );

    return {
      ...(sensor || {}),
      ...get(this, 'props.sensorOverrides', {}),
    };
  };
  getSensorSuffix = () => {
    const sensor = this.getSensorFromSState();
    return get(sensor, 'suffix', '');
  };
  legendItemToggled = (item: IDataset) => {
    let { filterMap } = this.state;

    //if item isn't in the map yet, add it
    //otherwise invert it in place
    if (filterMap[item.label] != undefined) {
      filterMap[item.label] = !filterMap[item.label];
    } else {
      filterMap[item.label] = !item.hidden;
    }

    this.setState({ filterMap }, this.renderChart);
  };
  renderLegend = (): JSX.Element => {
    const dataSets = this.state.chartDataModels;
    const { sState } = this.props;
    const ispb = isPB(sState);
    if (!dataSets) return <></>;

    const createItem = (i: IDataset, index: number): JSX.Element => {
      const StyledCheckDiv = styled.div`
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${i.borderColor};
          border-color: ${i.borderColor};
        }
      `;

      return (
        <StyledCheckDiv
          css={
            ispb &&
            css`
              width: 50%;
              padding: 3px 0;
            `
          }
          key={index}
        >
          <Checkbox
            checked={get(i, 'show', !i.hidden)}
            onChange={_ => this.legendItemToggled(i)}
          >
            {i.label}
          </Checkbox>
        </StyledCheckDiv>
      );
    };

    return (
      <div css={css([styles.legend, ispb && `flex-wrap: wrap`])}>
        {dataSets.map((item, index) => createItem(item, index))}
      </div>
    );
  };
  isDataSetHidden = (label: string, fallback: boolean): boolean => {
    const { filterMap } = this.state;
    return !!get(filterMap, label, fallback);
  };
  renderChart = async () => {
    if (!this.chartRef.current) return;
    const myChartRef = this.chartRef.current.getContext('2d');
    if (chart !== undefined) chart.destroy();

    const { chosenSensor, equipment, sState } = this.props;
    const ispb = isPB(sState);
    const { filterMap, date } = this.state;
    const sensorSelect = this.getSensorFromMap();
    const suffix = this.getSensorSuffix();
    const sensor = this.getSensorFromSState();

    const datasetProps = {
      showAlerts: true,
      showMin: true,
      showMax: true,
      showHourly: true,
      showTotal: !!size(sensorSelect.total),
      label_daily: 'Daily Average',
      label_min: 'Min Values',
      label_max: 'Max Values',
      label_alert: 'Alert Data',
      label_hourly: 'Hourly Average',
      label_total: 'Daily Total',
      ...(sensor || {}),
    };

    const isMetric = equipIsMetric(equipment, sState);

    const datasets: IDataset[] = [];

    if (chosenSensor !== 'bars' && datasetProps.showAlerts) {
      const label = datasetProps.label_alert;
      datasets.push({
        label: label,
        data: reverse(sensorSelect.alertData),
        backgroundColor: 'transparent',
        borderColor: convertHex(colors.alert, 0.8),
        borderWidth: 1,
        hidden: this.isDataSetHidden(label, false),
      });
    }

    const showHourly =
      datasetProps.showHourly &&
      get(dateoptions.find(d => d.value == date.selected), 'days', Infinity) <=
        7;
    const includeHourly =
      showHourly && !this.isDataSetHidden('Hourly Average', false);

    if (chosenSensor !== 'AMG') {
      const dset = includeHourly
        ? sortBy(
            [...sensorSelect.dailyAverage, ...sensorSelect.hourlyAverage],
            'x'
          )
        : sensorSelect.dailyAverage;
      
      datasets.push({
        label: datasetProps.label_daily,
        data: reverse(dset),
        backgroundColor: get(this, 'props.pointBackgroundColor', 'transparent'),
        pointBorderColor: convertHex('#000000', 0.8),
        borderColor: convertHex('#000000', 0.8),
        borderWidth: 1,
        hidden: this.isDataSetHidden(datasetProps.label_daily, false),
      });

      if (showHourly) {
        datasets.push({
          label: datasetProps.label_hourly,
          data: [], // reverse(sensorSelect.hourlyAverage),
          backgroundColor: 'transparent',
          pointBorderColor: convertHex('#000000', 0.8),
          borderColor: convertHex('#000000', 0.8),
          borderWidth: 1,
          hidden: this.isDataSetHidden(datasetProps.label_hourly, false),
        });
      }
    }

    if (datasetProps.showTotal) {
      const totalLabel = datasetProps.label_total;
      datasets.push({
        label: totalLabel,
        data: reverse(sensorSelect.total),
        backgroundColor: 'transparent',
        pointBorderColor: convertHex(colors.alert, 1),
        borderColor: convertHex(colors.alert, 1),
        borderWidth: 1,
        hidden: this.isDataSetHidden(totalLabel, true),
      });
    }

    if (datasetProps.showMin) {
      const minLabel = datasetProps.label_min;
      datasets.push({
        label: minLabel,
        data: reverse(
          includeHourly
            ? sortBy([...sensorSelect.min, ...sensorSelect.hmin], 'x')
            : sensorSelect.min
        ),
        backgroundColor: 'transparent',
        pointBorderColor: convertHex(colors.highlight, 1),
        borderColor: convertHex(colors.highlight, 1),
        borderWidth: 1,
        hidden: this.isDataSetHidden(minLabel, true),
      });
    }

    if (datasetProps.showMax) {
      const maxLabel = datasetProps.label_max;
      datasets.push({
        label: maxLabel,
        data: reverse(
          includeHourly
            ? sortBy([...sensorSelect.max, ...sensorSelect.hmax], 'x')
            : sensorSelect.max
        ),
        backgroundColor: 'transparent',
        pointBorderColor: convertHex(colors.warning, 1),
        borderColor: convertHex(colors.warning, 1),
        borderWidth: 1,
        hidden: this.isDataSetHidden(maxLabel, true),
      });
    }

    this.setState({ chartDataModels: datasets });

    const chartOpts = {
      type: get(this, 'props.chartType', 'line'),
      data: {
        datasets,
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            //@ts-ignore
            label: tooltipItem => {
              return chosenSensor === 'AMG' && tooltipItem.value === '100'
                ? 'Full'
                : chosenSensor === 'AMG' && tooltipItem.value === '0'
                ? 'Working'
                : (metricConvert(
                    `${tooltipItem.value}${suffix}`,
                    isMetric
                  ) as string);
            },
          },
        },
        showLines: chosenSensor === 'AMG' ? false : true,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                unit: 'day',
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 20,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                //@ts-ignore
                callback: value => {
                  return chosenSensor === 'AMG' && value === 100
                    ? 'Full'
                    : chosenSensor === 'AMG' && value === 0
                    ? 'Working'
                    : chosenSensor === 'AMG'
                    ? ''
                    : (metricConvert(`${value}${suffix}`, isMetric) as string);
                },
                beginAtZero: true,
                max:
                  chosenSensor === 'AMG'
                    ? 200
                    : chosenSensor === 'bars'
                    ? 5
                    : undefined,
                stepSize:
                  chosenSensor === 'AMG'
                    ? 100
                    : chosenSensor === 'bars'
                    ? 1
                    : undefined,
                autoSkip: true,
                maxTicksLimit: ispb ? 6 : 12,
              },
            },
          ],
        },
      },
    };

    //@ts-ignore
    get(this, 'props.chartOpts', []).map(c => {
      const [key, val] = c;
      if (val === undefined) {
        unset(chartOpts, key);
      } else {
        set(chartOpts, key, val);
      }
    })

    //@ts-ignore
    chart = new Chart(myChartRef, chartOpts);
  };
  dateChange = (val: string) => {
    trackEvent(eventTypes.eq_sensor_history_timeframe, {
      timeframe: val,
      equipment: this.props.equipment,
    });
    const state = { ...this.state };
    if (val === '1_day') {
      state.date.start = moment().subtract(1, 'day');
    } else if (val === '1_week') {
      state.date.start = moment().subtract(1, 'week');
    } else if (val === '1_month') {
      state.date.start = moment().subtract(1, 'month');
    } else if (val === '3_month') {
      state.date.start = moment().subtract(3, 'month');
    } else if (val === '6_month') {
      state.date.start = moment().subtract(6, 'month');
    }
    state.date.end = moment();
    state.date.selected = val;
    if (this.props.chosenSensor === 'AMG') {
      this.setState(state, this.buildMapForAMG);
    } else {
      this.setState(state, this.buildMap);
    }
  };
  dateRangeChange = (
    _pickerVals: RangePickerValue,
    dates: [string, string]
  ) => {
    const state = { ...this.state };
    state.date.start = moment(dates[0]);
    state.date.end = moment(dates[1]);
    if (this.props.chosenSensor === 'AMG') {
      this.setState(state, this.buildMapForAMG);
    } else {
      this.setState(state, this.buildMap);
    }
  };
  datePickerChange = (date: moment.Moment) => {
    const state = { ...this.state };
    state.date.start = moment(date).startOf('day');
    state.date.end = state.date.start.clone().endOf('day');
    if (this.props.chosenSensor === 'AMG') {
      this.setState(state, this.buildMapForAMG);
    } else {
      this.setState(state, this.buildMap);
    }
  };
  renderDateDrop = () => {
    const {
      date: { selected, start, end },
    } = this.state;

    const showDateSelect = get(this, 'props.showDateSelect', true);

    if (!showDateSelect) { return null; }

    return (
      <div>
        <Select onChange={this.dateChange} defaultValue={selected}>
          {dateoptions.map((opt, i) => (
            <Select.Option value={opt.value} key={i}>
              {opt.title}
            </Select.Option>
          ))}
        </Select>
        {selected === 'custom_date' && (
          <DatePicker.RangePicker
            onChange={this.dateRangeChange}
            defaultValue={[start, end]}
          />
        )}
        {selected === '1_day' && (
          <DatePicker onChange={this.datePickerChange} defaultValue={start} />
        )}
      </div>
    );
  };
  buildMapForAMG = () => {
    const { date } = this.state;
    const alerts: Publish[] = get(this.props, 'alerts', []);
    const sensorMap: SensorMap = {};
    alerts.map((alert: Publish, i: number) => {
      const data = alert.data;
      const start = moment(get(data, 'time', 1) * 1000);
      const canDisplay =
        start.isBetween(date.start, date.end) &&
        data.source === 'AMG' &&
        data.error === 2;
      if (canDisplay) {
        const error = data.error;
        const val = error === 2 ? 100 : 0;
        let sm = sensorMap['AMG'];
        if (!sm) {
          sm = sensorMap['AMG'] = createSensorMapItem();
        }

        const x = moment(alert.published_at);
        sm.alertData.push({ y: val, x });
      }
    });
    this.setState({ sensorMap }, this.renderChart);
  };
  cleanVal = (val?: number) => {
    const { sState, equipment, isTime } = this.props;
    const suffix = this.getSensorSuffix();
    const isMetric = equipIsMetric(equipment, sState);

    let v = val == undefined ? 0 : val;

    if (isMetric) {
      const { value } = metricConvert(`${val}${suffix}`, isMetric, true) as {
        value: string;
      };

      v = parseFloat(value);
    }

    if (isTime && get(isTime, 'transformValue')) {
      v = get(isTime, 'transformValue')(v);
    }

    return v;
  };
  buildMap = () => {
    if (this.props.chosenSensor === 'AMG') {
      return this.buildMapForAMG();
    }
    const { date } = this.state;
    const { sState, equipment } = this.props;
    const sensor = this.getSensorFromSState();

    const sensors = get(this, 'props.data', getEquipSensors(equipment, sState, []).sort(
      sortPublishByTimeOrDate
    ));
    const thresholds = getEquipThresholds(equipment, sState, []).sort(
      sortPublishByTimeOrDate
    );

    const chosenSensor = get(this, 'props.chosenSensor');

    const sensorMap: SensorMap = {};

    const all_ss = sensors
      .filter((s: Publish) => get(s, 'event', '') === 'dz_sensor_statistics')
      // .sort(sortPublishByTimeOrDate);

    const ss = all_ss.filter((s: Publish) =>
      get(sensor, 'filterBy')
        ? !!get(s, sensor.filterBy)
        : get(s, 'data.statistics_type', 0) != 1
    );

    const hourly_ss = all_ss.filter(
      (s: Publish) => get(s, 'data.statistics_type') == 1
    );

    const si = sensors.filter((s: Publish) => s.event === 'dz_sensor_instant');
    const sensorAlerts = sensors.filter(
      (s: Publish) =>
        s.event === 'dz_alert_data' && get(s, 'data.source') === chosenSensor
    );

    ss.map((s: Publish) => {
      const data = s.data || {};
      for (let k in data) {
        let sm = sensorMap[k];
        if (!sm) {
          sm = sensorMap[k] = createSensorMapItem();
        }
        const { min, max, avg, total, has } = getChartValues(data[k]);
        const val = this.cleanVal(avg);
        const minVal = this.cleanVal(min);
        const maxVal = this.cleanVal(max);
        const totalVal = this.cleanVal(total);

        const time = moment(s.published_at);

        const canDisplay = time.isBetween(date.start, date.end);

        if (canDisplay) {
          sm.min.push({ y: minVal, x: time });
          sm.max.push({ y: maxVal, x: time });
          sm.dailyAverage.push({ y: val, x: time, vals: data[k] });
          if (has('total')) {
            //@ts-ignore
            sm.total.push({ y: totalVal, x: time });
          }
        }
      }
    });

    hourly_ss.map((s: Publish) => {
      const data = s.data || {};
      for (let k in data) {
        let sm = sensorMap[k];
        if (!sm) {
          sm = sensorMap[k] = createSensorMapItem();
        }
        const vals = getChartValues(data[k]);
        const val = this.cleanVal(vals.avg);
        const minVal = this.cleanVal(vals.min);
        const maxVal = this.cleanVal(vals.max);

        const time = moment(s.published_at);

        const canDisplay = time.isBetween(date.start, date.end);

        if (canDisplay) {
          sm.hmin.push({ y: minVal, x: time });
          sm.hmax.push({ y: maxVal, x: time });
          sm.hourlyAverage.push({ y: val, x: time, vals: data[k] });
        }
      }
    });

    si.map((s: Publish) => {
      const data = s.data || {};
      for (let k in data) {
        let sm = sensorMap[k];
        if (!sm) {
          sm = sensorMap[k] = createSensorMapItem();
        }
        const vals = data[k];
        const val = this.cleanVal(vals);

        const time = moment(s.published_at);

        sm.instants.push({ y: val, x: time, vals });
      }
    });

    sensorAlerts.map((s: Publish) => {
      const data = s.data || {};
      const start = moment(get(data, 'start_time', 1) * 1000);
      const interval = get(data, 'interval', 15);

      const canDisplay = start.isBetween(date.start, date.end);
      if (canDisplay) {
        (data.values || []).map((val: number, i: number) => {
          let sm = sensorMap[chosenSensor];
          if (!sm) {
            sm = sensorMap[chosenSensor] = createSensorMapItem();
          }

          const x = moment(start).add(interval * i, 'seconds');
          sm.alertData.push({ y: this.cleanVal(val), x });
        });
      }
    });

    thresholds.map((s: Publish) => {
      const data = s.data || {};
      for (let k in data) {
        let sm = sensorMap[k];
        if (!sm) {
          sm = sensorMap[k] = createSensorMapItem();
        }

        const vals = data[k] || [];
        const high = get(vals, '0');
        const low = vals.length > 2 ? get(vals, '2') : get(vals, '1');

        const time = moment(s.published_at);

        high !== undefined &&
          sm.threshHigh.push({ y: this.cleanVal(high), x: time });
        low !== undefined &&
          sm.threshLow.push({ y: this.cleanVal(low), x: time });
      }
    });

    const filterMap = this.state.filterMap;
    if (get(sensor, 'legendsSelected')) {
      get(sensor, 'legendsSelected', []).map((s: PropertyPath) => {
        set(filterMap, s, false);
      });
    }

    this.setState({ sensorMap, filterMap }, this.renderChart);
  };
  render() {
    const { visible, loading, sState } = this.props;
    const sensor = this.getSensorFromSState();
    const ispb = isPB(sState);

    const showLegend = get(sensor, 'showLegend', true);

    return (
      <Modal
        visible={visible}
        onCancel={this.close}
        closable={true}
        footer={null}
        width={ispb ? `90%` : `60%`}
        title={
          <div>
            <div css={css(styles.modalTitle)}>
              {get(this, 'props.title', get(sensor, 'name', 'None'))}
              {loading && (
                <Icon css={css('margin-left: 5px')} type={'loading'} />
              )}
            </div>
            <div css={css(styles.modalSubTitle)}>
              {get(sensor, 'description', '')}
            </div>
          </div>
        }
      >
        {visible && !this.state.hasAll && (
          <div>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
        {visible && !!this.state.hasAll && (
          <div>
            {this.renderHeader()}
            {showLegend && this.renderLegend()}
            <canvas ref={this.chartRef} />
            {this.renderDateDrop()}
          </div>
        )}
      </Modal>
    );
  }
}

export const ModalChart = connect((sState: AppState) => ({ sState }), { addToMap })(
  _ModalChart
);
