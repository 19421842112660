import React from 'react';
import { AccountDetails } from './account-details';
import { ManageUsers } from './manage-users';
import { NotificationSettings } from './notification-settings';
import { Scheduling } from './scheduling';
import { ChangePassword } from './change-password';
import { AddUser } from './add-user';
import { ManageGroups } from './manage-groups';
import { ManageEquipment } from './manage-equipment';
import { ManageLocations } from './manage-locations';
import { ManageModels } from './manage-models';
import { ManageUser } from './manage-user';
import { DashRoute } from '../_shared/interfaces/dashroute';
import { EquipmentDetails } from './equipment-details';
import { EditEquipment } from './edit-equipment';
import { ManageGroup } from './manage-group';
import { EditGroup } from './edit-group';
import { ManageServiceRecord } from './manage-service-record';
import { BillingHistory } from './billing-history';
import { ManageEquipmentList } from './manage-equipment-list';
import { ManageEquipmentSelect } from './manage-equipment-select';
import { ManageModel } from './manage-model';
import { ManageOrgs } from './manage-orgs';
import { ManageOrg } from './manage-org';
import { EditOrg } from './edit-org';
import { EditLocation } from './edit-location';
import { ManageLocation } from './manage-location';
import { AppState } from '../app.state';
import { userHasRole, getMyOrg, userHasCompOrVac, isHandpiece } from '../utils';
import { ManufactureProcess } from './manufacture-process';
import { MFASettings } from './mfa-settings';
import { ManageSerials } from './manage-serials';
import { ManageTandemGroups } from './manage-tandem-groups';
import { EditTandemGroup } from './edit-tandem-group';
import { ManageTandemGroup } from './manage-tandem-group';
import { ManageProfile } from './manage-profile';
import { EditProfile } from './edit-profile';
import { EditPreset } from './edit-preset';
import { ManagePreset } from './manage-preset';
import { SpoofAPublish } from './spoof-a-publish';
import { ProcessData } from './process-data';
import { Equipment } from '../_shared/interfaces/equipment';
import { get } from 'lodash';
import { NotificationEvents, ReportingEvents, AlertEvents } from './reporting';
import { EditModel } from './edit-model';
import { GenerateReports } from './reporting/generatereports.component';

const isUserAssociatedWithLocationWithHandpiece = (
  state: AppState
): boolean => {
  const { auth, dash } = state;
  const user = get(auth, 'user');
  if (!user) return false;

  const eqs = get(dash, 'equipment', []);
  return eqs.some((eq: Equipment) => isHandpiece(eq));
};

export const SideRoutes: DashRoute[] = [
  {
    title: 'My Account',
    icon: { type: 'user' },
    path: '/dashboard/myAccount',
  },
  {
    title: 'Change Password',
    icon: { type: 'unlock' },
    path: '/dashboard/changePassword',
  },
  {
    title: 'Notification Settings',
    icon: { type: 'flag' },
    path: '/dashboard/notificationSettings',
  },
  {
    title: '2FA Settings',
    icon: { type: 'mail' },
    path: '/dashboard/mfaSettings',
    allowedRoles: [0],
  },
];

export const ReportingRoutes: DashRoute[] = [
  {
    title: 'User Events',
    icon: { type: 'user' },
    path: '/dashboard/reporting',
  },
  {
    title: 'Alert Events',
    icon: { type: 'exclamation-circle' },
    path: '/dashboard/reportingalerts',
  },
  {
    title: 'Notification Events',
    icon: { type: 'mail' },
    path: '/dashboard/reportingnotifications',
  },
  {
    title: 'Technical Data',
    icon: { type: 'snippets' },
    path: '/dashboard/generatereports',
  },
];

export const AdminSideRoutes: DashRoute[] = [
  {
    title: 'My Organization',
    icon: { type: 'account', lib: 'SvgIcon' },
    path: '/dashboard/myOrg',
    allowedRoles: [2],
  },
  {
    title: 'Users',
    icon: { type: 'users', lib: 'SvgIcon' },
    path: '/dashboard/manageUsers',
    allowedRoles: [0, 1, 2],
  },
  {
    title: 'Groups',
    icon: { type: 'groups', lib: 'SvgIcon' },
    path: '/dashboard/manageGroups',
    allowedRoles: [0, 1, 2, 3],
  },
  {
    title: 'Equipment',
    icon: { type: 'equipment', lib: 'SvgIcon' },
    path: '/dashboard/manageEquipmentList',
    allowedRoles: [0, 1, 2],
  },
  {
    title: 'Tandem Groups',
    icon: { type: 'tandemGroups', lib: 'SvgIcon' },
    path: '/dashboard/manageTandemGroups',
    allowedRoles: [0, 1, 2, 3],
    test: (sState: AppState) => {
      const pass = userHasCompOrVac(sState) || userHasRole([0, 1], sState);
      if (pass) {
        return true;
      }
      return false;
    },
  },
  {
    title: 'Service Organizations',
    icon: { type: 'serviceOrg', lib: 'SvgIcon' },
    path: '/dashboard/manageServiceOrgs',
    allowedRoles: [0, 1],
  },
  {
    title: 'Dental Organizations',
    icon: {
      type: 'dentalOrg',
      lib: 'SvgIcon',
    },
    path: '/dashboard/manageDentalOrgs',
    allowedRoles: [0, 1, 2],
    test: (sState: AppState) => {
      const isDental = userHasRole([0, 1], sState);
      const myOrg = getMyOrg(sState);

      if (isDental) {
        return true;
      } else {
        if (myOrg && myOrg.orgType === 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  {
    title: 'Locations',
    icon: { type: 'locations', lib: 'SvgIcon' },
    path: '/dashboard/manageLocations',
    allowedRoles: [0, 1, 2],
  },
  {
    title: 'Models',
    icon: { type: 'models', lib: 'SvgIcon' },
    path: '/dashboard/manageModels',
    allowedRoles: [0],
  },
  {
    title: 'Serials',
    icon: { type: 'serials', lib: 'SvgIcon' },
    path: '/dashboard/manageSerials',
    allowedRoles: [0],
  },
  {
    title: 'Spoof a Publish',
    path: '/dashboard/someSneakyStuff',
    allowedRoles: [0],
    icon: { type: 'cloudDownload', lib: 'SvgIcon' },
  },
];

const orgAdminHasOrg = (sState: AppState) => {
  if (userHasRole(2, sState)) {
    const orgId = get(sState, 'auth.user.orgId');
    return !!orgId;
  }
  return true;
}

export const Routes: { [key: string]: DashRoute } = {
  account: {
    title: 'Account',
    icon: { type: 'user' },
    path: '/dashboard/myAccount',
    component: AccountDetails,
    sideRoutes: SideRoutes,
    showPBSearch: false,
  },
  myOrg: {
    title: 'My Organization',
    icon: { type: 'triangle' },
    path: '/dashboard/myOrg',
    highlighted: '/dashboard/myOrg',
    render: props => <ManageOrg {...props as any} />,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [2],
    showSearch: false,
  },
  accountDetails: {
    title: 'Account',
    icon: { type: 'user' },
    path: '/dashboard/accountDetails',
    component: AccountDetails,
    highlighted: '/dashboard/manageUsers',
    sideRoutes: AdminSideRoutes,
    showSearch: false,
  },
  manageEquipmentList: {
    title: 'Manage Equipment',
    icon: { type: 'star' },
    path: '/dashboard/manageEquipmentList',
    allowedRoles: [0, 1, 2],
    component: ManageEquipmentList,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageEquipmentList',
    showSearch: false,
  },
  manageEquipmentSelect: {
    title: 'Manage Equipment',
    icon: { type: 'star' },
    path: '/dashboard/manageEquipmentSelect',
    allowedRoles: [0, 1, 2],
    component: ManageEquipmentSelect,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageEquipmentList',
    showSearch: false,
  },
  manageTandemGroups: {
    title: 'Tandem Groups',
    icon: { type: 'tandemGroups' },
    path: '/dashboard/manageTandemGroups',
    allowedRoles: [0, 1, 2, 3],
    component: ManageTandemGroups,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageTandemGroups',
    showSearch: false,
  },
  editTandemGroup: {
    title: 'Edit Tandem Group',
    icon: { type: 'tandemGroups' },
    path: '/dashboard/editTandemGroup',
    allowedRoles: [0, 1, 2],
    component: EditTandemGroup,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageTandemGroups',
    showSearch: false,
  },
  manageTandemGroup: {
    title: 'Tandem Group',
    icon: { type: 'tandemGroups' },
    path: '/dashboard/manageTandemGroup',
    allowedRoles: [0, 1, 2, 3],
    component: ManageTandemGroup,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageTandemGroups',
    showSearch: false,
  },
  manageUsers: {
    title: 'Manage Users',
    icon: { type: 'usergroup-add' },
    path: '/dashboard/manageUsers',
    allowedRoles: [0, 1, 2],
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageUsers',
    component: ManageUsers,
    showSearch: false,
    test: orgAdminHasOrg,
  },
  manageUser: {
    title: 'Manage User',
    icon: { type: 'usergroup-add' },
    path: '/dashboard/manageUser',
    allowedRoles: [0, 1, 2],
    component: ManageUser,
    highlighted: '/dashboard/manageUsers',
    sideRoutes: AdminSideRoutes,
    showSearch: false,
  },
  editGroup: {
    title: 'Edit Group',
    icon: { type: 'setting' },
    path: '/dashboard/editGroup',
    allowedRoles: [0, 1, 2, 3],
    component: EditGroup,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageGroups',
    showSearch: false,
  },
  manageGroups: {
    title: 'Manage Groups',
    icon: { type: 'setting' },
    path: '/dashboard/manageGroups',
    allowedRoles: [0, 1, 2, 3],
    component: ManageGroups,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageGroups',
    showSearch: false,
    test: orgAdminHasOrg,
  },
  manageGroup: {
    title: 'Manage Group',
    icon: { type: 'setting' },
    path: '/dashboard/manageGroup',
    allowedRoles: [0, 1, 2, 3],
    component: ManageGroup,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageGroups',
    showSearch: false,
  },
  viewPreset: {
    title: 'Manage Preset',
    icon: { type: 'profiles' },
    path: '/dashboard/managePreset',
    highlighted: '/dashboard/manageDentalOrgs',
    sideRoutes: AdminSideRoutes,
    showSearch: false,
    allowedRoles: [0, 1, 2, 3, 4],
    component: ManagePreset,
  },
  editProfile: {
    title: 'Edit Profile',
    icon: { type: 'profiles' },
    path: '/dashboard/editProfile',
    showSearch: false,
    allowedRoles: [2, 3, 4],
    component: EditProfile,
  },
  editProfileAdmin: {
    title: 'Edit Profile',
    icon: { type: 'profiles' },
    path: '/dashboard/editProfileAdmin',
    highlighted: '/dashboard/manageDentalOrg',
    sideRoutes: AdminSideRoutes,
    showSearch: false,
    allowedRoles: [2],
    component: EditProfile,
  },
  editPreset: {
    title: 'Edit Preset',
    icon: { type: 'profiles' },
    path: '/dashboard/editPreset',
    showSearch: false,
    allowedRoles: [2, 3, 4],
    component: EditPreset,
  },
  notificationSettings: {
    title: 'Notification Settings',
    icon: { type: 'flag' },
    path: '/dashboard/notificationSettings',
    highlighted: '/dashboard/notificationSettings',
    component: NotificationSettings,
    sideRoutes: SideRoutes,
    showPBSearch: false,
  },
  mfaSettings: {
    title: 'Multi Factor Settings',
    icon: { type: 'flag' },
    path: '/dashboard/mfaSettings',
    highlighted: '/dashboard/mfaSettings',
    component: MFASettings,
    sideRoutes: SideRoutes,
  },
  landing: {
    title: 'Dashboard',
    path: '/dashboard',
    exact: true,
    component: ManageEquipment,
  },
  profiles: {
    title: 'Profiles & Presets',
    path: '/dashboard/manageprofile',
    component: ManageProfile,
    showSearch: false,
    allowedRoles: [0, 2, 3, 4],
    test: (sState: AppState) =>
      isUserAssociatedWithLocationWithHandpiece(sState),
  },
  viewMypreset: {
    title: 'Manage My Presets',
    path: '/dashboard/viewMyPreset',
    component: ManagePreset,
  },
  editMypreset: {
    title: 'Edit My Presets',
    path: '/dashboard/editMyPreset',
    component: EditPreset,
  },
  scheduling: {
    title: 'Scheduling',
    icon: { type: 'carry-out' },
    path: '/dashboard/scheduling',
    component: Scheduling,
    allowedRoles: [0, 1, 2, 3, 4, 6, 7],
    sideRoutes: [],
    test: (sState: AppState) => {
      const pass = userHasCompOrVac(sState);
      if (pass) {
        return true;
      }
      return false;
    },
  },
  admin: {
    title: 'Admin',
    path: '/dashboard/admin',
    allowedRoles: [0, 1, 2, 3],
    highlighted: '/dashboard/manageUsers',
    component: ManageUsers,
    sideRoutes: AdminSideRoutes,
    showSearch: false,
    test: orgAdminHasOrg,
  },
  reporting: {
    title: 'Reporting',
    path: '/dashboard/reporting',
    allowedRoles: [0, 1],
    highlighted: '/dashboard/reporting',
    component: ReportingEvents,
    sideRoutes: ReportingRoutes,
    showSearch: false,
  },
  alertevents: {
    title: 'Alert Events',
    path: '/dashboard/reportingalerts',
    allowedRoles: [0, 1],
    highlighted: '/dashboard/reportingalerts',
    component: AlertEvents,
    sideRoutes: ReportingRoutes,
    showSearch: false,
  },
  notificationevents: {
    title: 'Notification Events',
    path: '/dashboard/reportingnotifications',
    allowedRoles: [0, 1],
    highlighted: '/dashboard/reportingnotifications',
    component: NotificationEvents,
    sideRoutes: ReportingRoutes,
    showSearch: false,
  },
  generatereports: {
    title: 'Technical Data',
    path: '/dashboard/generatereports',
    allowedRoles: [0, 1],
    highlighted: '/dashboard/generatereports',
    component: GenerateReports,
    sideRoutes: ReportingRoutes,
    showSearch: false,
  },
  changePassword: {
    title: 'Change Password',
    icon: { type: 'unlock' },
    path: '/dashboard/changePassword',
    component: ChangePassword,
    highlighted: '/dashboard/changePassword',
    sideRoutes: SideRoutes,
    showPBSearch: false,
  },
  addUser: {
    title: 'Add User',
    icon: { type: 'user-add' },
    path: '/dashboard/addUser',
    highlighted: '/dashboard/manageUsers',
    component: AddUser,
    sideRoutes: AdminSideRoutes,
    showSearch: false,
  },
  manageEquipment: {
    title: 'Equipment',
    icon: { type: 'star' },
    path: '/dashboard/manageEquipment',
    component: ManageEquipment,
    allowedRoles: [0, 1, 2, 3],
    sideRoutes: [],
  },
  equipmentDetails: {
    title: 'Equipment Details',
    icon: { type: 'star' },
    path: '/dashboard/equipmentDetails',
    component: EquipmentDetails,
  },
  editEquipment: {
    title: 'Edit Equipment',
    icon: { type: 'star' },
    path: '/dashboard/editEquipment',
    component: EditEquipment,
  },
  addTandemGroup: {
    title: 'Edit Tandem Group',
    icon: { type: 'tandemGroups' },
    path: '/dashboard/addTandemGroup',
    component: EditTandemGroup,
  },
  viewTandemGroup: {
    title: 'Manage Tandem Group',
    icon: { type: 'tandemGroups' },
    path: '/dashboard/viewTandemGroup',
    component: ManageTandemGroup,
  },
  manageServiceOrgs: {
    title: 'Service Organizations',
    icon: { type: 'triangle' },
    path: '/dashboard/manageServiceOrgs',
    highlighted: '/dashboard/manageServiceOrgs',
    render: props => <ManageOrgs {...props} orgType={0} />,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageDentalOrgs: {
    title: 'Dental Organizations',
    icon: { type: 'diamond' },
    path: '/dashboard/manageDentalOrgs',
    render: props => <ManageOrgs {...props} orgType={1} />,
    highlighted: '/dashboard/manageDentalOrgs',
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageDentalOrg: {
    title: 'Manage Dental Organizations',
    icon: { type: 'diamond' },
    path: '/dashboard/manageDentalOrg',
    component: ManageOrg,
    highlighted: '/dashboard/manageDentalOrgs',
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageServiceOrg: {
    title: 'Manage Service Organizations',
    icon: { type: 'diamond' },
    path: '/dashboard/manageServiceOrg',
    component: ManageOrg,
    highlighted: '/dashboard/manageServiceOrgs',
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  editServiceOrg: {
    title: 'Edit Organizations',
    icon: { type: 'diamond' },
    path: '/dashboard/editServiceOrg',
    component: EditOrg,
    highlighted: '/dashboard/manageServiceOrgs',
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  editDentalOrg: {
    title: 'Edit Organizations',
    icon: { type: 'diamond' },
    path: '/dashboard/editDentalOrg',
    component: EditOrg,
    highlighted: '/dashboard/manageDentalOrgs',
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageLocations: {
    title: 'Locations',
    icon: { type: 'circle' },
    path: '/dashboard/manageLocations',
    highlighted: '/dashboard/manageLocations',
    component: ManageLocations,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageLocation: {
    title: 'Locations',
    icon: { type: 'circle' },
    path: '/dashboard/manageLocation',
    highlighted: '/dashboard/manageLocations',
    component: ManageLocation,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  editLocation: {
    title: 'Locations',
    icon: { type: 'circle' },
    path: '/dashboard/editLocation',
    highlighted: '/dashboard/manageLocations',
    component: EditLocation,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageModels: {
    title: 'Models',
    icon: { type: 'circle' },
    path: '/dashboard/manageModels',
    highlighted: '/dashboard/manageModels',
    component: ManageModels,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  manageModel: {
    title: 'Models',
    icon: { type: 'circle' },
    path: '/dashboard/manageModel',
    component: ManageModel,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageModels',
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  editModel: {
    title: 'Add/Edit Model',
    icon: { type: 'circle' },
    path: '/dashboard/editModel',
    component: EditModel,
    sideRoutes: AdminSideRoutes,
    highlighted: '/dashboard/manageModels',
    allowedRoles: [0, 1, 2, 3],
    showSearch: false,
  },
  billingHistory: {
    title: 'Billing History',
    icon: { type: 'circle' },
    path: '/dashboard/billingHistory',
    component: BillingHistory,
    sideRoutes: [],
    allowedRoles: [0, 1, 2, 3],
  },
  manageServiceRecord: {
    title: 'Manage Service Record',
    icon: { type: 'circle' },
    path: '/dashboard/manageServiceRecord',
    component: ManageServiceRecord,
    sideRoutes: [],
    allowedRoles: [0, 1, 2, 3],
  },
  manufactureProcess: {
    title: 'Manufacturing Process',
    icon: { type: 'circle' },
    path: '/dashboard/manufactureProcess',
    component: ManufactureProcess,
    sideRoutes: [],
    allowedRoles: [5],
  },
  manageSerials: {
    title: 'Serials',
    icon: { type: 'circle' },
    path: '/dashboard/manageSerials',
    highlighted: '/dashboard/manageSerials',
    component: ManageSerials,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0],
    showSearch: false,
  },
  processData: {
    //NOT A PUBLICLY AVAILABLE SCREEN. MEANT FOR DEVVING
    title: 'Process Data',
    icon: { type: 'circle' },
    path: '/dashboard/processData',
    highlighted: '/dashboard/processData',
    component: ProcessData,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0],
    showSearch: false,
  },
  spoofAPublish: {
    title: 'SPOOF PUBLISH',
    icon: { type: 'circle' },
    path: '/dashboard/someSneakyStuff',
    highlighted: '/dashboard/someSneakyStuff',
    component: SpoofAPublish,
    sideRoutes: AdminSideRoutes,
    allowedRoles: [0],
    showSearch: false,
  },
};
