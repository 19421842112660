import colors from './colors';
import { convertHex } from '../utils';

export const GlobalInserts = `
  html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  #root {
    height: 100%;
  }
  
  .App {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-input {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-label label {
    font-size: 12px;
    font-weight: bold;
    color: ${colors.labelColor};
  }

  #tg .ant-select-selection {
    min-width: 200px;
  }

  .ant-select-selection,
  .ant-input.ant-select-search__field {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
  }

  .ant-checkbox-inner {
    border-radius: 0px;
  }

  .ant-menu-item {
    font-size: 12px;
  }

  .ant-menu-item.ant-menu-item-selected {
    background-color: ${colors.white};
    width: 100%;
  }

  .ant-menu-item.ant-menu-item-selected i.anticon{
    fill: ${colors.highlight};
  }

  .ant-menu-item:hover i.anticon {
    fill: ${colors.highlight};
    transition: fill 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), 
      border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), 
      background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), 
      padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-menu-item.ant-menu-item-selected::after {
    left: 0px;
    right: auto;
    border-width: 5px;
    border-color: ${colors.highlight};
  }

  .ant-table {
    border: none;
  }

  .ant-table .tbl-list-row-0 {
    background-color: ${colors.table0};
  }

  .ant-table .ant-table-thead > tr > th {
    background-color: ${colors.white};
  }

  .ant-table .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .any-table .ant-table-column-title {
    color: ${colors.labelColor};
  }

  .ant-table-row.canClick {
    cursor: pointer;
  }

  .ant-input-group-addon button {
    border-radius: 0px;
  }

  .ant-input[disabled],
  .ant-select-disabled {
    background-color: ${colors.disabledLight};
    color: ${colors.black};
  }

  .ant-modal-content {
    border-radius: 0px;
  }

  button.ant-btn {
    border-radius: 0px;
  }

  .rbc-day-slot .rbc-event,
  .rbc-event {
    background-color: ${colors.highlight};
    border: 1px solid ${colors.highlight};
    border-radius: 0px;
  }

  .rbc-event.rbc-selected {
    background-color: ${colors.highlight};
  }

  .rbc-current-time-indicator {
    background-color: ${colors.highlight};
  }

  .rbc-today {
    background-color: ${convertHex(colors.highlight, 0.1)};
  }

  .ant-card {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
  }

  .ant-card .ant-card-body {
    padding: 10px;
  }

  .ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
  }
  
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: rgba(0, 0, 0, 0.25) !important;
    -webkit-animation-name: none;
    animation-name: none;
  }
//  .ant-checkbox .ant-checkbox-inner {
//     border-color: red;
//   }

    .col-ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .col-ellipsis {
      display: block;
    }
`;

export default {
  logoWrap: `
    margin-bottom: 30px;
    height: 80px;
  `,
  row: `
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  column: `
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  pbRow: `
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  formContainer: `
    max-width: 400px;
    height: 100%;
  `,
  formAlert: `
    margin-bottom: 10px;
  `,
  hr: `
    width: 100%;
    height: 2px;
    background-color: ${colors.hrBorder};
  `,
  ellipsisText: `
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
  `,
  error: `
    font-style: italic;
    color: ${colors.error};
  `,
  pbFilterContainer: `
    flex-direction: column;
    align-items: start;
  `,
  pbFilterStyle: `
    width: 100%;
    margin: 5px 0px;
  `,
  hidden: `
    display: none
  `,
  pbButtonContainer: `
    display: flex;
    flex-direction:
    row; width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  `,
  fullHeightCenterColumn: `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  `,
  header: `
    color: ${colors.highlight};
    font-size: 15px;
    font-weight: bold;
  `
};
