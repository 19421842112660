/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PureComponent, Component } from 'react';
import { Select } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { take, size, get, uniqBy } from 'lodash';
import { AutoComplete } from '.';
import { SelectProps } from 'antd/lib/select';
import { sortByLabel } from '../../utils';

interface IProps extends AutoCompleteProps {
  limit: number;
  type?: string;
}

export class AutoCompleteLimited extends PureComponent<IProps> {
  state = {
    allData: this.props.dataSource || [],
    filtered: take(this.props.dataSource, this.props.limit),
  };
  onSearch = (value: string) => {
    const allData = (this.props.dataSource || []).filter(s => {
      const _s = (s || { text: '' }) as { text: string };
      const str = _s.text.toUpperCase();
      return str.indexOf(value.toUpperCase()) > -1;
    });

    this.setState({
      allData,
      filtered: take(allData, this.props.limit),
    });
  };
  componentDidUpdate = (prevProps: IProps) => {
    if (this.props.dataSource !== prevProps.dataSource) {
      this.setState({
        filtered: take(this.props.dataSource, this.props.limit),
        allData: this.props.dataSource,
      });
    }
  };
  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    if ((size(this.props.dataSource) !== size(nextProps.dataSource)) || nextState !== this.state || nextProps.value !== this.props.value) {
      return true;
    }

    return false;
  }
  render() {
    const gt = this.state.allData.length > this.props.limit;
    const filtered = gt
      ? this.state.filtered.concat([
          <Select.Option disabled key="all" className="show-all">
            Displaying first 25 results. Search by Name or Serial Number to
            filter this list.
          </Select.Option>,
        ])
      : this.state.filtered;

    if (this.props.type === 'dropdown') {
      return (
        <Select
          {...this.props}
          onSearch={this.onSearch}
          filterOption={undefined}
        >
          {filtered.map(f => <Select.Option value={get(f, 'value')}>{get(f, 'text')}</Select.Option>)}
        </Select>
      )
    }

    return (
      <AutoComplete
        {...this.props}
        dataSource={filtered}
        onSearch={this.onSearch}
      />
    );
  }
}

interface Opt {
  value: any;
  label: string;
}

interface IPropsSelect extends SelectProps {
  limit: number;
  data: Opt[];
  value?: string[];
  firstopts?: Opt[];
}

export class SelectLimited extends Component<IPropsSelect> {
  state = {
    allData: this.props.data || [],
    filtered: (() => {
      const hasValue = size(this.props.value);
      if (hasValue) {
        const toinclude = this.props.data.filter(f => get(this, 'props.value', []).includes(f.value));

        const all = uniqBy([...toinclude, ...this.props.data], 'value') as Opt[];

        const limited = take(all, this.props.limit - (this.props.firstopts ? size(this.props.firstopts) : 0));

        return [...(this.props.firstopts || []), ...limited.sort(sortByLabel)];
      }
      
      return take([...(this.props.firstopts || []), ...this.props.data], this.props.limit)
    })() 
  };
  onSearch = (value: string) => {
    const allData = (this.props.data || []).filter(s => {
      const _s = (s || { label: '' }) as { label: string };
      const str = _s.label.toUpperCase();
      return str.indexOf(value.toUpperCase()) > -1;
    });

    this.setState({
      allData,
      filtered: take(allData, this.props.limit),
    });
  };
  componentDidUpdate = (prevProps: IPropsSelect) => {
    if (this.props.data !== prevProps.data) {
      this.setState({
        filtered: take(this.props.data, this.props.limit),
        allData: this.props.data,
      });
    }
  };
  shouldComponentUpdate(nextProps: Readonly<IPropsSelect>, nextState: Readonly<{}>): boolean {
    if ((size(this.props.data) !== size(nextProps.data)) || nextState !== this.state || nextProps.value !== this.props.value) {
      return true;
    }

    return false;
  }
  render() {
    const { limit } = this.props;
    const gt = this.state.allData.length > limit;
    const filtered = this.state.filtered;

    return (
      <Select
        {...this.props}
        onSearch={this.onSearch}
        filterOption={undefined}
      >
        {filtered.map(f => <Select.Option key={`${f.value}`} value={f.value}>{f.label}</Select.Option>)}
        {!!gt && <Select.Option disabled key="all" className="show-all">{`Displaying first ${limit} results. Search to filter this list further.`}</Select.Option>}
      </Select>
    )
  }
}
